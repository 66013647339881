import React from 'react';
import { Icon } from '../globalIcons';

export function MobileViewTableHeaderComponent(): null  {
  return null;
}

export function SortedAscendingIcon(): JSX.Element {
  return (
    <div className="rms__sort__icon__wrap">
      <div className="rms__icon rms__sort__icon-asc"></div>
    </div>
  );
}

export function SortedDescendingIcon(): JSX.Element {
  return (
    <div className="rms__sort__icon__wrap">
      <div className="rms__icon rms__sort__icon-desc"></div>
    </div>
  );
}

export function TableFooterComponent(): JSX.Element {
  return <div className="rms__table__dashboard__footer">Total Records: 10</div>;
}

export function NoDataComponent(): JSX.Element {
  return <div className="rms__table__dashboard__no__data">No Data</div>;
}

export function NoDataComponentAlarms(): JSX.Element {
  return <div className="rms__table__dashboard__no__data">
    <Icon iconClassName='no__alarms__icon' />
   <div className="rms__table__dashboard__no__data-text">No Alarms Available</div>
  </div>;
}

export function NoDataComponentChargers(): JSX.Element {
  return <div className="rms__table__dashboard__no__data"><Icon iconClassName='no__charger__data__icon' />
     <div className="rms__table__dashboard__no__charger__data">No Chargers Available</div></div>;
}

export function NoDataComponentChargingStations(): JSX.Element {
  return <div className="rms__table__dashboard__no__data"><Icon iconClassName='no__charger__data__icon' />
     <div className="rms__table__dashboard__no__charger__data">No Charging Stations Available</div></div>;
}

export function NoDataComponentCustomers(): JSX.Element {
  return <div className="rms__table__dashboard__no__data">No Customers Available</div>;
}

export function NoDataComponentUsers(): JSX.Element {
  return <div className="rms__table__dashboard__no__data"><Icon iconClassName='no__users__icon' />
     <div className="rms__table__dashboard__no__data-text">No Users Available</div></div>;
}

export function NoDataComponentRoles(): JSX.Element {
  return <div className="rms__table__dashboard__no__data"><Icon iconClassName='no__users__icon' />
     <div className="rms__table__dashboard__no__data-text">No Roles Available</div></div>;
}

export function NoDataComponentParameters(): JSX.Element {
  return <div className="rms__table__dashboard__no__data"><Icon iconClassName='no__chargers__icon' />
     <div className="rms__table__dashboard__no__data-text">No Parameters Available</div></div>;
}

export function NoDataComponentUpdateCommands(): JSX.Element {
  return <div className="rms__table__dashboard__no__data"><Icon iconClassName='no__chargers__icon' />
     <div className="rms__table__dashboard__no__data-text">No Commands Available</div></div>;
}

export function NoDataComponentOrgs(): JSX.Element {
  return <div className="rms__table__dashboard__no__data"><Icon iconClassName='no__users__icon' />
     <div className="rms__table__dashboard__no__data-text">No Organisations Available</div></div>;
}

export function NoDataComponentChargingHistory(): JSX.Element {
  return <div className="rms__table__dashboard__no__data"><Icon iconClassName='no__charger__data__icon' />
     <div className="rms__table__dashboard__no__charger__data">No Charging History Available</div></div>;
}

export function NoDataComponentChargerLogs(): JSX.Element {
  return <div className="rms__table__dashboard__no__data"><Icon iconClassName='no__charger__data__icon' />
     <div className="rms__table__dashboard__no__charger__data">No Charger Logs Available</div></div>;
}

export function NoDataComponentExceptionLogs(): JSX.Element {
  return <div className="rms__table__dashboard__no__data"><Icon iconClassName='no__charger__data__icon' />
     <div className="rms__table__dashboard__no__charger__data">No Exception Logs Available</div></div>;
}


export function NoDataComponentUpdateHistory(): JSX.Element {
  return <div className="rms__table__dashboard__no__data"><Icon iconClassName='no__users__icon' />
     <div className="rms__table__dashboard__no__data-text">No Update History Available</div></div>;
}
