import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';

import localStorageInstance from '../../rmsReduxStore/api-service/LocalStorageService';
import authService from './AuthService';
import HWLocalStorage from '../../rmsReduxStore/api-service/HWLocalStorage';

class RefreshTokenService {
  private refreshTask: Promise<AxiosResponse> | null = null;

  public callRefreshToken(
    originalRequest: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    if (this.refreshTask) {
      return new Promise<AxiosResponse>((resolve) => {
        const pendingRequestConfig = originalRequest;
        this.refreshTask?.finally(() => { resolve(this.reCall(pendingRequestConfig)); });
      });
    }

    const refreshToken = localStorageInstance.getRefreshToken();

    const request = {
        refreshToken: refreshToken
        ? refreshToken
        : localStorageInstance.getAccessToken(),
    };
    const recallRequest = originalRequest;
    this.refreshTask = authService.fetchAccessTokenAndRefresh(request)
      .then((response) => {
        if (
          response &&
          response.data &&
          (response.status === 202 || response.status === 200)
        ) {
          localStorageInstance.setAccessToken(response.data.accessToken);
          return this.reCall(recallRequest);
        } else {
          const logoutData = {
            userId: HWLocalStorage?.load('user_id'),
            sessionId: HWLocalStorage?.load('sessionId'),
          };
          authService.handleLogout(logoutData);
          HWLocalStorage.clearLocalStorage();
          window.location.href='/'

          throw new Error('Token refresh failed');
        }
      })
      .catch(() => {
        throw new Error('Token refresh failed');
      })
      .finally(() => {
        this.refreshTask = null;
      });

    return this.refreshTask;
  }

  private reCall(originalRequest: AxiosRequestConfig): Promise<AxiosResponse> {
    originalRequest.headers = {
      ...originalRequest.headers,
      Authorization: localStorageInstance.getAccessToken(),
    };

    return axios(originalRequest);
  }
}

const refreshTokenInstance = new RefreshTokenService();
export default refreshTokenInstance;
