/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import hwaxios from '../api-service/HWAxios';
import { dashboardTypes } from '../reduxExports';

const baseUrl = process.env.REACT_APP_SERVER_BASE_URL;

export const setDashboardHeaderLoader:any =
  (val: boolean) =>
    async (
      dispatch: (action: Partial<DashboardAction>) => void
    ): Promise<void> => {
      const loaderAction: Partial<DashboardAction> = {
        type: dashboardTypes.SET_DASHBOARD_STATE_DATA,
        dashboardHeaderLoader: val,
      };
      dispatch(loaderAction);
    };

export const setDashboardChargerHealthStatusLoader:any =
  (val) =>
    async (dispatch): Promise<void> => {
      const loaderAction: Partial<DashboardAction> = {
        type: dashboardTypes.SET_DASHBOARD_STATE_DATA,
        chargerHealthGraphLoader: val,
      };
      dispatch(loaderAction);
    };

export const setAlarmSummaryCountLoader:any =
  (val) =>
    async (dispatch): Promise<void> => {
      const loaderSuccessAction: Partial<DashboardAction> = {
        type: dashboardTypes.SET_DASHBOARD_STATE_DATA,
        alarmSummaryCountLoader: val,
      };
      dispatch(loaderSuccessAction);
    };


const setCustomerWiseChargerHealthGraphLoader =
  (val) =>
    async (dispatch): Promise<void> => {
      const loaderSuccessAction: Partial<DashboardAction> = {
        type: dashboardTypes.SET_DASHBOARD_STATE_DATA,
        customerWiseChargerHealthGraphLoader: val,
      };
      dispatch(loaderSuccessAction);
    };

export const fetchDashboardHeaderData:any =
  (reqBody) =>
    async (dispatch: (arg0: Partial<DashboardAction>) => void): Promise<void> => {
      const url = baseUrl + '/dashboard';
      try {
        setDashboardHeaderLoader(true);

        const response = await hwaxios.post(url, reqBody);

        const action: Partial<DashboardAction> = {
          type: dashboardTypes.FETCH_DASHBOARD_HEADER_DATA,
          dashboardHeaderData: response?.data,
        };
        dispatch(action);
      } catch (error) {
        setDashboardHeaderLoader(false);
        console.error(error);

        throw error;
      }
    };

export const fetchAlarmSummaryData:any =
  (reqBody) =>
    async (dispatch: (arg0: Partial<DashboardAction>) => void): Promise<void> => {
      const url = baseUrl + '/dashboard/alarm-summary';
      try {
        const response = await hwaxios.get(url);

        const action: Partial<DashboardAction> = {
          type: dashboardTypes.FETCH_ALARM_SUMMARY_DATA,
          alarmSummaryData: response?.data?.data,
          alarmSummaryDataNextToken: response?.data?.nextToken,
        };
        dispatch(action);
      } catch (error) {
        console.error(error);

        throw error;
      }
    };

export const fetchCustomerHealthData:any =
  (reqBody) =>
    async (dispatch: (arg0: Partial<DashboardAction>) => void): Promise<void> => {
      const url = baseUrl + '/dashboard/charger-working-status';
      try {
        setCustomerWiseChargerHealthGraphLoader(true);
        const response = await hwaxios.post(url, reqBody);

        const action: Partial<DashboardAction> = {
          type: dashboardTypes.FETCH_CUSTOMER_HEALTH_DATA,
          customerWiseChargerHealthData: response.data,
          customerWiseChargerHealthDataNextToken: response.data?.nextToken,
        };
        dispatch(action);
        setCustomerWiseChargerHealthGraphLoader(true);
        return response.data;
      } catch (error) {
        console.error(error);
        setCustomerWiseChargerHealthGraphLoader(true);
        throw error;
      }
    };

export const fetchDashboardChargerHealthData:any =
  (reqBody) =>
    async (dispatch: (arg0: Partial<DashboardAction>) => void): Promise<void> => {
      const url =
        process.env.REACT_APP_SERVER_BASE_URL +
        '/dashboard/charger-health-status';
      try {
        setDashboardChargerHealthStatusLoader(true);
        const response = await hwaxios.post(url, reqBody);

        const action: Partial<DashboardAction> = {
          type: dashboardTypes.FETCH_CHARGER_HEALTH_DATA,
          chargerHealthData: response.data?.data,
        };
        dispatch(action);
        setDashboardChargerHealthStatusLoader(false);
        return response.data;
      } catch (error) {
        setDashboardChargerHealthStatusLoader(false);
        console.log(error);
        throw error;
      }
    };

    export const fetchConnectivityMediumData:any =
  (reqBody) =>
    async (dispatch: (arg0: Partial<DashboardAction>) => void): Promise<void> => {
      const url = baseUrl + '/dashboard/charger-by-connectivity';
      try {
        setCustomerWiseChargerHealthGraphLoader(true);
        const response = await hwaxios.post(url, reqBody);
        
        const action: Partial<DashboardAction> = {
          type: dashboardTypes.FETCH_CONNECTIVITY_MEDIUM_DATA,
          connectivityMediumData: response.data?.data,
        };
        dispatch(action);
        setCustomerWiseChargerHealthGraphLoader(true);
        return response.data;
      } catch (error) {
        console.error(error);
        setCustomerWiseChargerHealthGraphLoader(true);
        throw error;
      }
    };
