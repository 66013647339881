export const SET_REPORT_LOADER = 'SET_REPORT_LOADER';
export const FETCH_CHARGERS_HISTORY_DATA = 'FETCH_CHARGERS_HISTORY_DATA'
export const CLEAR_CHARGING_HISTORY_LIST_DATA = 'CLEAR_CHARGING_HISTORY_LIST_DATA'
export const SET_CHARGING_HISTORY_LIST_TABLE_PROPS = 'SET_CHARGING_HISTORY_LIST_TABLE_PROPS'
export const FETCH_CHARGER_LOGS_DATA = 'FETCH_CHARGER_LOGS_DATA'
export const SET_CHARGER_LOGS_LIST_TABLE_PROPS = 'SET_CHARGER_LOGS_LIST_TABLE_PROPS'
export const CLEAR_CHARGER_LOGS_LIST_DATA = 'CLEAR_CHARGER_LOGS_LIST_DATA'
export const FETCH_EXCEPTION_LOGS_DATA = 'FETCH_EXCEPTION_LOGS_DATA'
export const SET_EXCEPTION_LOGS_LIST_TABLE_PROPS = 'SET_EXCEPTION_LOGS_LIST_TABLE_PROPS'
export const CLEAR_EXCEPTION_LOGS_LIST_DATA = 'CLEAR_EXCEPTION_LOGS_LIST_DATA'

export const SET_CHARGER_LOGS_LIST_REQUEST_HASH = 'SET_CHARGER_LOGS_LIST_REQUEST_HASH'