import { Skeleton, Table, TableCell, TableRow } from '@mui/material';
import React, { FC } from 'react'


interface TableSkeletonProps {
  rowsNum: number,
  colsNum: number
}

const TableRowsLoader: FC<TableSkeletonProps> = ({ rowsNum, colsNum = 4 }) => {
  return (
    <tbody>
    {[...Array(rowsNum)].map((row, rowIndex) => (
      <TableRow key={rowIndex}>
        {[...Array(colsNum)].map((col, colIndex) => (
          <TableCell key={'col' + colIndex} component="th" scope="row">
            <Skeleton animation="wave" variant="text" />
          </TableCell>
        ))}
      </TableRow>
    ))}
    </tbody>
  );
};

const TableSkeleton: FC<TableSkeletonProps> = ({ rowsNum = 10, colsNum }) => {

  return (
    <div className='table__skeleton__container'>
      <Table>
        <TableRowsLoader rowsNum={rowsNum ?? 10} colsNum={colsNum ?? 6} />
      </Table>
    </div>
  )
}

export default TableSkeleton