import { dashboardTypes } from '../reduxExports';

const dashboardInitialState: DashboardState = {
  dashboardHeaderData: null,
  alarmSummaryData: null,
  alarmSummaryDataNextToken: null,
  customerWiseChargerHealthData: null,
  customerWiseChargerHealthDataNextToken: null,
  chargerHealthData: null,
  chargerHealthGraphLoader: false,
  dashboardHeaderLoader: false,
  alarmSummaryCountLoader: false,
  alarmSummaryTableLoader: false,
  customerWiseChargerHealthGraphLoader: false,
  connectivityMediumData: null
};

const dashboardReducer = (
  state: DashboardState = dashboardInitialState,
  action: DashboardAction
): DashboardState => {
  switch (action.type) {
    case dashboardTypes.FETCH_DASHBOARD_HEADER_DATA:
      return {
        ...state,
        dashboardHeaderData: action.dashboardHeaderData,
      };
    case dashboardTypes.FETCH_ALARM_SUMMARY_DATA:
      return {
        ...state,
        alarmSummaryData: action.alarmSummaryData,
        alarmSummaryDataNextToken: action.alarmSummaryDataNextToken,
      };
    case dashboardTypes.FETCH_CUSTOMER_HEALTH_DATA:
      return {
        ...state,
        customerWiseChargerHealthData: action.customerWiseChargerHealthData,
        customerWiseChargerHealthDataNextToken: action.customerWiseChargerHealthDataNextToken,
      };
    case dashboardTypes.FETCH_CHARGER_HEALTH_DATA:
      return {
        ...state,
        chargerHealthData: action.chargerHealthData,
      };
    case dashboardTypes.FETCH_CONNECTIVITY_MEDIUM_DATA:
      return {
        ...state,
        connectivityMediumData: action.connectivityMediumData,
      };
      case dashboardTypes.CLEAR_DASHBOARD_STATE_DATA:
        return {
          ...state,
          dashboardHeaderData: null,
          alarmSummaryData: null,
          alarmSummaryDataNextToken: null,
          customerWiseChargerHealthData: null,
          customerWiseChargerHealthDataNextToken: null,
          chargerHealthData: null,
          chargerHealthGraphLoader: false,
          dashboardHeaderLoader: false,
          alarmSummaryCountLoader: false,
          alarmSummaryTableLoader: false,
          customerWiseChargerHealthGraphLoader: false,
          connectivityMediumData: null,
        };
    case dashboardTypes.SET_DASHBOARD_STATE_DATA:
      { return { ...state, ...action } }
    default:
      return state;
  }
};

export default dashboardReducer;
