export const alarmNavList = [
  {
    id: 'active',
    text: 'Active Alarms'
  },
  {
    id: 'history',
    text: 'History'
  },
]

export const alarmPanelNavList = [
  {
    id: 'core',
    text: 'Core'
  },
  // {
  //   id: 'remoteTrigger',
  //   text: 'Remote Trigger'
  // },
  // {
  //   id: 'firmwareManagement',
  //   text: 'Firmware Management'
  // },
  // {
  //   id: 'reservation',
  //   text: 'Reservation'
  // },
  // {
  //   id: 'localAuthListManagement',
  //   text: 'Local Auth List Management'
  // },
  // {
  //   id: 'smartCharging',
  //   text: 'Smart Charging'
  // }
]


export const remoteCommandRadioButtons = [
  {
    radioButtonId: 'connector1',
    radioButtonLabel: 'Connector 1'
  },
  {
    radioButtonId: 'connector2',
    radioButtonLabel: 'Connector 2'
  }
]

export const remoteCommandRadioButtonsAll = [
  {
    radioButtonId: 'connector1',
    radioButtonLabel: 'Connector 1'
  },
  {
    radioButtonId: 'connector2',
    radioButtonLabel: 'Connector 2'
  },
  {
    radioButtonId: 'All Connector',
    radioButtonLabel: 'All Connector'
  }
]

export const alarmCoreNavList = [
  {
    buttonText: 'Change Availability',
    buttonIconClassName: 'alarm__panel__settings__all__icon',
    buttonId: 'change-availability'
  },
  {
    buttonText: 'Manage Configuration',
    buttonIconClassName: 'alarm__panel__settings__all__icon',
    buttonId: 'manage-configuration'
  },
  {
    buttonText: 'Clear Cache',
    buttonIconClassName: 'alarm__panel__settings__all__icon',
    buttonId: 'clear-cache'
  },
  {
    buttonText: 'Reset',
    buttonIconClassName: 'alarm__panel__settings__all__icon',
    buttonId: 'reset'
  },
  {
    buttonText: 'Data Transfer',
    buttonIconClassName: 'alarm__panel__settings__all__icon',
    buttonId: 'data-transfer'
  }
]

export const alarmRemoteTriggerNavList = [
  {
    buttonText: 'Remote Start',
    buttonIconClassName: 'alarm__panel__settings__all__icon',
    buttonId: 'remote-start'
  },
  {
    buttonText: 'Remote Stop',
    buttonIconClassName: 'alarm__panel__settings__all__icon',
    buttonId: 'remote-stop'
  },
  {
    buttonText: 'Unlock Connector',
    buttonIconClassName: 'alarm__panel__settings__all__icon',
    buttonId: 'unlock-connector'
  },
  {
    buttonText: 'Trigger Message',
    buttonIconClassName: 'alarm__panel__settings__all__icon',
    buttonId: 'trigger-message'
  }
]

export const idTagTypeRadioButtons = [
  {
    radioButtonId: 'rfid',
    radioButtonLabel: 'RFID'
  }
]

export const alarmFirmwareManagementNavList = [
  {
    buttonText: 'Update Firmware',
    buttonIconClassName: 'alarm__panel__settings__all__icon',
    buttonId: 'update-firmware'
  },
  {
    buttonText: 'Get Diagnostic',
    buttonIconClassName: 'alarm__panel__settings__all__icon',
    buttonId: 'get-diagnostic'
  }
]

export const alarmReservationNavList = [
  {
    buttonText: 'Reserve Now',
    buttonIconClassName: 'alarm__panel__settings__all__icon',
    buttonId: 'reserve-now'
  },
  {
    buttonText: 'Cancel Reservation',
    buttonIconClassName: 'alarm__panel__settings__all__icon',
    buttonId: 'cancel-reservation'
  }
]

export const alarmLocalAuthListManagementNavList = [
  {
    buttonText: 'Send Local List',
    buttonIconClassName: 'alarm__panel__settings__all__icon',
    buttonId: 'send-local-list'
  },
  {
    buttonText: 'Get Local List Version',
    buttonIconClassName: 'alarm__panel__settings__all__icon',
    buttonId: 'get-send-local-list'
  }
]

export const alarmSmartChargingNavList = [
  {
    buttonText: 'Get Composite Schedule',
    buttonIconClassName: 'alarm__panel__settings__all__icon',
    buttonId: 'get-composite-schedule'
  },
  {
    buttonText: 'Clear Charging Profile',
    buttonIconClassName: 'alarm__panel__settings__all__icon',
    buttonId: 'clear-charging-profile'
  },
  {
    buttonText: 'Set Charging Profile',
    buttonIconClassName: 'alarm__panel__settings__all__icon',
    buttonId: 'set-charging-profile'
  }
]