import rmsStore, { persistor } from './rmsReduxStore/rmsStore'
import React, { type FC, Suspense } from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import AppLayout from './layouts/AppLayout'
import { PersistGate } from 'redux-persist/integration/react'
import './App.css'
import './rmsAssets/rmsIcons.css'
import './rmsResponsiveStyles/rmsResponsiveStyles.css'
import './globalUtils/globalUtils.css'
import AppVersionCheck from './layouts/AppVersionCheck'

const App: FC = () => {

  return (
    <BrowserRouter>
      <Suspense fallback='Loading...'>
        <Provider store={rmsStore}>
        <AppVersionCheck key={'app-refresh'}></AppVersionCheck>
        <PersistGate loading={null} persistor={persistor}>
            <AppLayout></AppLayout>
            </PersistGate>
        </Provider>
      </Suspense>
    </BrowserRouter>
  )
}

export default App
