import React, { FC } from 'react';
import Button from '@mui/material/Button';
import {Dialog,DialogActions,DialogContent,DialogContentText,DialogTitle, ThemeProvider, styled} from '@mui/material';
import { FilledBtnTheme, OutlinedBtnTheme } from '../globalExports';



interface CustomDialogProps {
    dialogConfig: CustomDialogConfig ,
    show: boolean,
    // eslint-disable-next-line @typescript-eslint/ban-types
    handleClose?: Function,
    // eslint-disable-next-line @typescript-eslint/ban-types
    handleSubmit?: Function
    deleteLoader?:boolean,

}

interface CustomDialogConfig {
    dialogClassName?: string,
    dialogTitle?: string
    dialogDescription: string,
    dialogCancelTitle?: string,
    dialogOkTitle?: string,
}

const CustomizedDialog = styled(Dialog)(({ theme }) => (
    {
    '& .MuiDialog-root': {
      padding: theme.spacing(1),
    },
  }));


const CustomDialog: FC<CustomDialogProps> = (props) => {
    const { dialogConfig, show, handleClose, handleSubmit,deleteLoader } = props

    return (
        <CustomizedDialog
            className={'custom__dialog ' + dialogConfig?.dialogClassName}
            open={show}
            onClose={(e) => handleClose?.(e)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {dialogConfig?.dialogTitle}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {dialogConfig?.dialogDescription}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
            <ThemeProvider theme={OutlinedBtnTheme}>
                {dialogConfig?.dialogCancelTitle &&<Button onClick={(e) => handleClose?.(e)}>{dialogConfig?.dialogCancelTitle}</Button>}
            </ThemeProvider>
            <ThemeProvider theme={FilledBtnTheme}>
               {dialogConfig?.dialogOkTitle && <Button 
               onClick={(e) => handleSubmit?.(e)} 
               disabled={deleteLoader?? false}
               autoFocus>{dialogConfig?.dialogOkTitle}
                </Button>}
            </ThemeProvider>
            </DialogActions>
        </CustomizedDialog>
    );
}

export default CustomDialog
