import React, { useCallback, useState, useEffect, type FC } from 'react'
import Navbar from './navbar/Navbar'
import Sidebar from './sidebar/Sidebar'
import './layout.css'
import { Routes, Route } from 'react-router-dom'
import { authenticatedRoutes, unAuthenticatedRoutes } from './layoutRoutes'
import PropTypes from 'prop-types'
import { useScreenSize } from '../globalUtils/globalHooks'
import appUser from '../auth/service/AppUser'
import { Toasters } from '../globalUtils/globalExports'

export const SidebarProps = {
  expandSideBar: PropTypes.bool.isRequired,
  handleSidebarToggle: PropTypes.func
}

export type SidebarPropTypes = PropTypes.InferProps<typeof SidebarProps>

const AppLayout: FC = () => {
  const [expandSideBar, setExpandSideBar] = useState(true)
  const screenSize = useScreenSize?.()
  const handleSidebarToggle = useCallback((val) => { setExpandSideBar(val) }, [])
  const isLoggedIn = appUser.isLoggedIn();
  const routes     = isLoggedIn ? authenticatedRoutes() : unAuthenticatedRoutes();

  useEffect(() => {
    if (screenSize?.width <= 800) {
      handleSidebarToggle(false)
    } else {
      handleSidebarToggle(true)
    }
  }, [screenSize?.width])
  
  return (
      <div className="app__layout">
      {!isLoggedIn && (
         <Routes>
             { routes.map((rmsRoute, index) => <Route key={rmsRoute.path + index} path={rmsRoute.path} element={rmsRoute?.component} />)  }
         </Routes>
        )}
      {isLoggedIn && (
        (
          <>
            <Navbar handleSidebarToggle={handleSidebarToggle} expandSideBar={expandSideBar}/>
            <div className="app__layout__container">
              {expandSideBar && (
                <Sidebar handleSidebarToggle={handleSidebarToggle} expandSideBar={expandSideBar}/>
              )}
              <div className={ expandSideBar ? 'app__route__container ' : 'app__route__container without__side__bar'} >
                <Routes>
                { routes?.filter((route) => route?.isAccessible).map((rmsRoute, index) => <Route key={rmsRoute.path + index}  path={rmsRoute.path} element={rmsRoute?.component} />)  }
                </Routes>
              </div>
              <Toasters />
            </div>
          </>
            )
      )}
      </div>
    )
}
export default AppLayout
