import PropTypes from 'prop-types'
import './CustomStatus.css'
import { type FC } from 'react'
import React from 'react';
import { CustomTooltipWithLabel } from '../globalExports';
import { Icon } from '../globalIcons';

export const CustomStatusCardType = {
  containerClassName: PropTypes.string,
  statusIconClassName: PropTypes.string.isRequired,
  statusText: PropTypes.string.isRequired,
  backgroundColor: PropTypes.string,
}

type CustomStatusCardPropType = PropTypes.InferProps<
  typeof CustomStatusCardType
>

export const CustomStatus: FC<CustomStatusCardPropType> = (props) => {
  const { statusIconClassName, backgroundColor, statusText, containerClassName } = props

  return (
    <>
      <div className={'rms__table__status__wrap ' + containerClassName} style={{ backgroundColor: backgroundColor ? `${backgroundColor}` : 'transparent', color: backgroundColor ? 'var(--white-color)' : 'var(--black-color)', padding: backgroundColor ? '3px 10px' : '3px 0px' }}>
        {statusIconClassName && <Icon iconClassName={statusIconClassName} />}
        {statusText && <div className='rms__table__status-text'><CustomTooltipWithLabel label={statusText} labelId={statusText} /></div>}
      </div>
    </>
  )
}

export default CustomStatus
