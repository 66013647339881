import React, { FC } from 'react';
import Button from '@mui/material/Button';
import {Dialog,DialogActions,DialogContent,DialogTitle, ThemeProvider, styled} from '@mui/material';
import { FilledBtnTheme, OutlinedBtnTheme } from '../globalExports';



interface ImageDialogProps {
    dialogConfig: ImageDialogConfig ,
    show: boolean,
    // eslint-disable-next-line @typescript-eslint/ban-types
    handleClose?: Function,
    // eslint-disable-next-line @typescript-eslint/ban-types
    handleSubmit?: Function

}

interface ImageDialogConfig {
    dialogClassName?: string,
    dialogTitle?: string
    imageUrl?: string|null
    dialogCancelTitle?: string,
    dialogOkTitle?: string,
}

const CustomizedDialog = styled(Dialog)(({ theme }) => (
    {
    '& .MuiDialog-root': {
      padding: theme.spacing(1),
    },
  }));


const ImageDialog: FC<ImageDialogProps> = (props) => {
    const { dialogConfig, show, handleClose, handleSubmit } = props

    return (
        <CustomizedDialog
            className={'custom__dialog ' + dialogConfig?.dialogClassName}
            open={show}
            onClose={(e) => handleClose?.(e)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {dialogConfig?.dialogTitle}
            </DialogTitle>
            <DialogContent>
                {dialogConfig?.imageUrl && <img src={dialogConfig?.imageUrl} alt="Dialog Image" style={{ width: '100%', height: 'auto' }}/>}
            </DialogContent>
            <DialogActions>
            <ThemeProvider theme={OutlinedBtnTheme}>
                {dialogConfig?.dialogCancelTitle &&<Button onClick={(e) => handleClose?.(e)}>{dialogConfig?.dialogCancelTitle}</Button>}
            </ThemeProvider>
            <ThemeProvider theme={FilledBtnTheme}>
               {dialogConfig?.dialogOkTitle && <Button onClick={(e) => handleSubmit?.(e)} autoFocus>{dialogConfig?.dialogOkTitle}
                </Button>}
            </ThemeProvider>
            </DialogActions>
        </CustomizedDialog>
    );
}

export default ImageDialog
