export const FETCH_CUSTOMERS_DATA = 'FETCH_CUSTOMERS_DATA'
export const SET_CUSTOMER_LOADER = 'SET_CUSTOMER_LOADER'
export const SET_CUSTOMER_REDUCER_STATE = 'SET_CUSTOMER_REDUCER_STATE'
export const FETCH_HISTORY_DATA = 'FETCH_HISTORY_DATA'
export const SET_CUSTOMER_LIST_TABLE_PROPS = 'SET_CUSTOMER_LIST_TABLE_PROPS'
export const CLEAR_HISTORY_LIST_DATA = 'CLEAR_HISTORY_LIST_DATA'
export const CLEAR_CUSTOMER_LIST_DATA = 'CLEAR_CUSTOMER_LIST_DATA'
export const SET_CUSTOMER_DETAIL_LOADER = 'SET_CUSTOMER_DETAIL_LOADER'
export const FETCH_CUSTOMER_DETAIL_DATA = 'FETCH_CUSTOMER_DETAIL_DATA'
export const CLEAR_CUSTOMER_DETAIL_DATA = 'CLEAR_CUSTOMER_DETAIL_DATA'
export const SET_CUSTOMER_CHARGER_LIST_TABLE_PROPS = 'SET_CUSTOMER_CHARGER_LIST_TABLE_PROPS'
export const SET_CUSTOMER_UPDATE_HISTORY_LIST_TABLE_PROPS = 'SET_CUSTOMER_UPDATE_HISTORY_LIST_TABLE_PROPS'
export const FETCH_CUSTOMER_CHARGERS_DATA = 'FETCH_CUSTOMER_CHARGERS_DATA'
export const SET_SELECTED_CUSTOMER_CHARGER_DATA = 'SET_SELECTED_CUSTOMER_CHARGER_DATA'
export const CLEAR_CUSTOMER_CHARGER_LIST_DATA = 'CLEAR_CUSTOMER_CHARGER_LIST_DATA'
