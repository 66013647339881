import React, { memo,  useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../rmsReduxStore/rmsReduxHooks';
import { fetchChargerMasterInfo, fetchChargerSystemInfo, getChargerReducer, } from '../../../rmsReduxStore/reduxExports';
import { cloneDeep } from 'lodash';
import { updateMasterInfo, useEffectOnce, } from '../../../globalUtils/globalHooks';

import { useLocation } from 'react-router-dom';
import {ParametersTable} from '../../../globalUtils/globalExports';

interface ChargerPanelSystemsInfo {
  contentHeight: number
}

const ChargerPanelSystemsInfoDashboard = memo(function ChargerPanelSystemsInfoDashboard({ contentHeight }: ChargerPanelSystemsInfo) {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { chargerMasterInfo, chargerSystemInfo } = useAppSelector(getChargerReducer);
  const [systemInfoData, setSystemInfoData] = useState<ChargerMasterInfo[] | null>(null);


  useEffect(() => {
    if (chargerMasterInfo !== null && chargerSystemInfo !== null) {
      const chargerMasterData = cloneDeep(chargerMasterInfo);
      const chargerSystemData = cloneDeep(chargerSystemInfo);
      const data = updateMasterInfo(chargerMasterData, chargerSystemData);
      setSystemInfoData(data)
    }
  }, [chargerMasterInfo, chargerSystemInfo])

  useEffectOnce(() => {
    dispatch(fetchChargerMasterInfo({ chargerId: location?.state?.chargerId }))
    dispatch(fetchChargerSystemInfo({ chargerId: location?.state?.chargerId }))
  })

  return (
    <div className="charger__panel__systems__info__dashboard" style={{ height: `${contentHeight}px` }}>
<ParametersTable data={systemInfoData} containerClassName={'charger__panel__systems__info-paramters-table'} />
    </div>
  )
}
)

export default ChargerPanelSystemsInfoDashboard
