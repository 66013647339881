import React, { FC, useCallback, useState } from 'react';
import PageVisibility from 'react-page-visibility/dist/umd/PageVisibility'
import axios from 'axios';
import { useEffectOnce } from '../globalUtils/globalHooks';
import rmsStore from '../rmsReduxStore/rmsStore';

/* 
 Forece refresh the webpage, whenever a new version of the app is available.
*/
const AppVersionCheck: FC = () => {
  const [checkingAppVersion, setCheckingAppVersion] = useState(false);
  const [appVersionStorageKey] = useState('spin-rms-app-version');
  const forceClearCache = true

  const checkAndRefresh = useCallback(() => {
    if (checkingAppVersion == true) { return; }
    setCheckingAppVersion(true);
    const url = '/app-config.json?t=' + new Date().getTime();
    axios.get(url).then(response => {
      try {
        const newAppVersion = response.data && response.data.appVersion && response.data.appVersion.trim();
        if (!newAppVersion) { return }

        const localAppVersion = localStorage.getItem(appVersionStorageKey);
        if (!localAppVersion || (localAppVersion.trim().toLowerCase() !== newAppVersion.trim().toLowerCase())) {
          if (forceClearCache) {
            rmsStore.dispatch({ type: 'RESET_LOGOUT' })
          }
          localStorage.setItem(appVersionStorageKey, newAppVersion);
          window.location.reload();
        }
      } catch (error) { console.log('App Version: Error while checking the app version.'); }
    }).catch(() => { console.log('App Version: Error while checking the app version.'); })
      .finally(() => { setCheckingAppVersion(false); });
  }, [checkingAppVersion, setCheckingAppVersion, appVersionStorageKey]);

  useEffectOnce(() => {
    checkAndRefresh();
  });

  // User comes back to the app.
  const onVisibiltyChange = useCallback((isVisible) => {
    if (!isVisible) { return; }
    checkAndRefresh();
  }, [checkAndRefresh]);

  return (<PageVisibility onChange={onVisibiltyChange} />);
}

export default AppVersionCheck;
