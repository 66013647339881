import PropTypes from 'prop-types'
import './DashboardHeader.css'
import { type FC } from 'react'
import React  from 'react';

export const DashboardHeaderProps = {
  header: PropTypes.string.isRequired,
  headerClassName: PropTypes.string
}

export type DashboardHeaderPropTypes = PropTypes.InferProps<
  typeof DashboardHeaderProps
>

const DashboardHeader: FC<DashboardHeaderPropTypes> = (props) => {
  const { header, headerClassName } = props
  return (
    <h1 className={'app__dashboard__header ' + headerClassName}>{header}</h1>
  )
}

export default DashboardHeader
