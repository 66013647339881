import React from 'react';
import './ToggleSwitch.css';
import PropTypes from 'prop-types';
import Switch from 'react-switch';
import { Icon } from '../globalIcons';


export const ToggleSwitchType = {
  toggleId: PropTypes.string.isRequired,
  toggleClassName: PropTypes.string,
  toggleLabelName: PropTypes.string,
  disableToggleLabelName: PropTypes.string,
  handleClick: PropTypes.func,
  isDisabled: PropTypes.bool,
  checked: PropTypes.bool
};

type ToggleSwitchTypePropTypes = PropTypes.InferProps<
  typeof ToggleSwitchType
>;


const ToggleSwitch: React.FC<ToggleSwitchTypePropTypes> = (props) => {
  const { toggleId, toggleClassName, handleClick, isDisabled, toggleLabelName, disableToggleLabelName, checked } = props;
  return (
    <div className={'rms__toggle__switch__container ' + toggleClassName}>
      <label htmlFor={toggleId} className={checked ? 'rms__toggle__switch__container-enabled-text' : 'rms__toggle__switch__container-disabled-text'}>
        {checked ? toggleLabelName : disableToggleLabelName}
        <Switch
          checked={checked || false}
          disabled={isDisabled || false}
          onChange={(checked, e, id) => {
            handleClick?.(checked, e, id)
          }}
          onColor='#E4F1E0'
          offColor='#F9E0E0'
          handleDiameter={22}
          uncheckedIcon={false}
          checkedIcon={false}
          // boxShadow='0px 1px 5px rgba(0, 0, 0, 0.6)'
          // activeBoxShadow='0px 0px 1px 10px rgba(0, 0, 0, 0.2)'
          height={12}
          width={55}
          className='rms__toggle__switch'
          id={toggleId}
          uncheckedHandleIcon={
            <Icon iconClassName='disable_togge-icon' />
          }
          checkedHandleIcon={
            <Icon iconClassName='enable_togge-icon' />
          }
        />
      </label>
    </div>
  )
}

export default ToggleSwitch