/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
import { Typography } from '@mui/material'
import { type FC, useCallback } from 'react'

import { type GridRowParams } from '@mui/x-data-grid-pro'
import { CustomStatus } from '../globalExports'
import React from 'react';
import { ActionIcons, Icon } from '../globalIcons';

interface HeaderDataConfig {
  headerLeftDataConfig: []
  headerRightDataConfig: {
    actionIconsComponent: boolean
    statusIconComponent: boolean
  }
}
interface ContentData {
  key: string
  iconClassName: string
}

type ContentDataRow = ContentData[]

type ContentDataConfig = ContentDataRow[]

interface CustomParams extends GridRowParams {
  headerDataConfig: HeaderDataConfig
  contentDataConfig: ContentDataConfig
  actionIconsList: any
  statusIconKey: string
  statusIconMapper:any
  renderedColumns?: any[],
  statusIconBackgroundColorMapper?:any
}

const MobileViewCards: FC<CustomParams> = (params) => {
  const headerData = params?.headerDataConfig?.headerLeftDataConfig
  const headerRightDataConfig = params?.headerDataConfig?.headerRightDataConfig

  const contentData = params?.contentDataConfig
  const rowData = params?.row
  const rowId = params?.row?.id
  const renderedColumns = params?.renderedColumns

  const convertCamelToTitleCase= (str) => {
    return str
      .replace(/([a-z])([A-Z])/g, '$1 $2')
      .replace(/\b\w/g, (char) => char.toUpperCase());
  };

  const checkLength=(str) =>{
    return (str && str.length>20)?`${str.slice(0, 17)}...`:str;
  }

  const fetchHeaderName = useCallback(
    (columnId) => {
      const column = renderedColumns?.find((col) => col.field === columnId)
      if (column) {
        return column.headerName
      }
      return checkLength(convertCamelToTitleCase(columnId));
    },
    [renderedColumns]
  )

  return (

    <div
      className="rms__table__row__cards"

      key={rowData?.id}
    >
      <div className="rms__table__row__card-header-wrap">
        <div className="rms__table__row__card-header-left-wrap">
          {headerData?.map((header, index) => {
            return (
              <div
                key={'row' + index + rowId}
                className="rms__table__row__card-header-row"
              >
                <Typography
                  variant="h4"
                  className=""
                  component="div"
                  key={header + index + 'title' + rowId}
                >
                  {fetchHeaderName(header)}
                </Typography>
                <Typography
                  variant="h4"
                  className=""
                  component="div"
                  key={header + index + 'colon' + rowId}
                >
                  :
                </Typography>
                <Typography
                  variant="h5"
                  className=""
                  component="div"
                  key={header + index + 'value' + rowId}
                >
                  {checkLength(rowData[header])}
                </Typography>
                {(rowData[header] && rowData[header].length>20) && <Icon iconClassName='rms__black_copy_clipboard_icon' />}
              </div>
            )
          }, [])}
        </div>

        <div className="rms__table__row__card-header-right-wrap">
          {headerRightDataConfig?.actionIconsComponent && <ActionIcons actionIconsList={params?.actionIconsList} />}
          {headerRightDataConfig?.statusIconComponent && <CustomStatus 
          statusText={rowData[params?.statusIconKey]} 
          statusIconClassName= {params?.statusIconMapper?.[rowData[params?.statusIconKey]]} 
          backgroundColor={params?.statusIconBackgroundColorMapper?.[rowData[params?.statusIconKey]]}
          />}              
        </div>
      </div>

      <div className="rms__table__row__card-content-wrap">
        {contentData?.map((row, rowIndex) => {
          return (
            <div
              key={rowIndex + 'card-row'}
              className="rms__table__row__card-content-row"
            >
              {row?.map((column, colIndex) => {
                return (
                  <div
                    key={colIndex + 'card-column' + rowId}
                    className="rms__table__row__card-content-column"
                  >
                    <Icon iconClassName={column?.iconClassName} />
                    <div className="rms__table__row__card-content-column-text">
                      {checkLength(rowData[column?.key])}  
                    </div>
                    {(rowData[column?.key]?.length>20) && <Icon iconClassName='rms__grey_copy_clipboard_icon' />}
                  </div>
                )
              })}
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default MobileViewCards

