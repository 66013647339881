import { InputAdornment, TextField, ThemeProvider } from '@mui/material'
import PropTypes from 'prop-types'
import React, { FC, useCallback, useEffect, useState } from 'react'
import { SearchBoxTheme } from '../globalExports'
import { Icon } from '../globalIcons'

export const SearchBoxType = {
  searchFieldId: PropTypes.string.isRequired,
  searchFieldName: PropTypes.string,
  searchBoxClassName: PropTypes.string,
  handleSearch: PropTypes.func,
  isDisabled:PropTypes.bool
}

export type SearchBoxPropTypes = PropTypes.InferProps<
  typeof SearchBoxType
>

const SearchBox: FC<SearchBoxPropTypes> = (props) => {
  const {
    searchFieldId,
    searchFieldName,
    searchBoxClassName,
    handleSearch,
    isDisabled
  } = props

  const [searchString, setSearchString] = useState('')
  const [isInitialMount, setIsInitialMount] = useState(true)
  useEffect(() => {
    if (isInitialMount) {
      setIsInitialMount(false)
      return
    }
    
    const delayDebounceFn = setTimeout(() => {
      handleSearch?.(searchString)
    }, 20)
    return () => { clearTimeout(delayDebounceFn) }
  }, [searchString])

  const handleChange = useCallback((event) => {
    const inputValue = event.target.value
    setSearchString(inputValue)
  }, [])

  useEffect(() => {
    return () => {
      setSearchString('')
    }
  }, [])

  return (
    <ThemeProvider theme={SearchBoxTheme}>
      <TextField
        variant="standard"
        className={searchBoxClassName ?? undefined}
        InputLabelProps={{
          shrink: true
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <div className="rms__icon rms__custom__search__box__icon"></div>
            </InputAdornment>
          ),
          endAdornment: (searchString?.length > 0 &&
            <InputAdornment position="end" className='custom__search__box__cross__icon'>
             <Icon iconClassName='react__select__dropdown__cross__icon' onIconClick={() => {setSearchString('');handleSearch?.('')}} />
          </InputAdornment>
          ),
          disableUnderline: true
        }}
        id={searchFieldId}
        name={searchFieldName ?? undefined}
        value={searchString ?? undefined}
        placeholder={'Search'}
        onChange={handleChange}
        disabled={isDisabled ?? false}

      />
    </ThemeProvider>
  )
}

export default SearchBox
