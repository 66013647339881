import type React from 'react'
import PropTypes from 'prop-types'

export const BubbledTextType = {
  bubbleContainerClassName: PropTypes.string,
  bubbleId: PropTypes.string.isRequired,
  bubbleText: PropTypes.string,
  bubbleColor: PropTypes.string
}

export type BubbledTextPropTypes = PropTypes.InferProps<typeof BubbledTextType>

const BubbledText: React.FC<BubbledTextPropTypes> = (props) => {
  const { bubbleId, bubbleContainerClassName, bubbleText, bubbleColor } = props
  return (
    <div
      id={bubbleId}
      style={{ backgroundColor: bubbleColor ?? '#ffffff' }}
      className={'bubbled__text__container ' + bubbleContainerClassName}
    >
      {bubbleText}
    </div>
  )
}

export default BubbledText
