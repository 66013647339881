/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  type ChangeEvent,
  type MouseEvent,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import {
  CustomButtonGroup,
  DashboardLoader,
  // CustomInput,
  RadioButton,
  CustomInput,
  useAppSelector
} from '../../../globalUtils/globalExports'
import { FormLabel } from '@mui/material'
import { toast } from 'react-toastify'

// import { FormLabel } from '@mui/material'
import ChargerPanelSubNavListHeader from './ChargerPanelSubNavListHeader'
import React from 'react';
import hwAxios from '../../../rmsReduxStore/api-service/HWAxios'
import appUser from '../../../auth/service/AppUser'
import { getChargerReducer } from '../../../rmsReduxStore/reduxExports'
import { useLocation } from 'react-router-dom'

interface ChargerPanelRemoteCommands      { buttonText: string, buttonIconClassName: string, buttonId: string }
interface ChargerPanelRemoteCommandsProps { contentHeight: number, commands: ChargerPanelRemoteCommands[]}
interface IConnectorOptions { radioButtonId: string, radioButtonLabel: string }

function ChargerPanelRemoteCommandsComponent({ contentHeight, commands }: ChargerPanelRemoteCommandsProps) {

  const dashboardContentRef = useRef<HTMLDivElement>(null)
  const dashboardNavRef = useRef<HTMLDivElement>(null)
  const [selectedNav, setSelectedNav] = useState<string|null>(commands.length > 0 ? commands[0].buttonId : null);
  const [urlValue, setUrlValue] = useState<string | null>(null)
  const [dashboardHeight, setDashboardHeight] = useState(0)
  const location = useLocation();
  const { chargerConnectorStatus } = useAppSelector(getChargerReducer);
  const [connectorButtons, setConnectorButtons] = useState<IConnectorOptions[]>();

  useEffect(() => {
    if (chargerConnectorStatus) {
      const connectorOptions: IConnectorOptions[] = chargerConnectorStatus.map((connector) => { return { radioButtonId: connector.connectorId, radioButtonLabel: '' + connector.connectorName + ' (' + connector.connectorType + ')' } as IConnectorOptions; });
      setConnectorButtons(connectorOptions);
    }
  }, [chargerConnectorStatus])

  const [selectedConnectorValue, setSelectedConnectorValue] = useState<string | null>(null);

  const handleConnectorChange = useCallback((event: ChangeEvent<HTMLInputElement>, value: string): void => {
    setSelectedConnectorValue(value)
  }, []);

  const handleNavBtnClick = useCallback((event: MouseEvent<HTMLButtonElement>, val: string) => {
    setSelectedNav(val);
  }, [])

  const handleUrlChange = useCallback((event) => {
    setUrlValue(event?.target?.value)
  }, [])

  const calculateContentHeight = useCallback(() => {
    if (
      dashboardNavRef.current != null &&
      dashboardContentRef.current != null
    ) {
      const dashboardNavRect =
        dashboardNavRef.current.getBoundingClientRect()

      const navbarHeight = dashboardNavRect.height

      const remainingHeight = contentHeight - navbarHeight - 10
      setDashboardHeight(remainingHeight)
    }
  }, [contentHeight])

  useEffect(() => {
    calculateContentHeight()
    window.addEventListener('resize', calculateContentHeight)
    return (): void => {
      window.removeEventListener('resize', calculateContentHeight)
    }
  }, [calculateContentHeight])

  const isFtpUrlRequired = useMemo(() => {
     return selectedNav && 
     (['ftp_software_upgrade','ftp_log_upload','ftp_certificate_upgrade', 'ftp_screen_saver_images_upgrade',
     'ftp_customer_logo_update'].indexOf(selectedNav) != -1);
   },[selectedNav])

   const isConnectorRequired = useMemo(() => {
    return selectedNav && (selectedNav === 'disable_connector' || selectedNav === 'enable_connector' || selectedNav === 'calibrate_voltage')
  },[selectedNav])

  const onPerformBtnClick = useCallback(() => {

    // Validate:
    if (isConnectorRequired && !selectedConnectorValue) {
        toast.error('Please select the connector.');
        return;
    }
    if (isFtpUrlRequired && (!urlValue || !urlValue.trim())) {
       toast.error('Please enter the URL.');
       return;
    }

    setShowLoader(true);
    const url = process.env.REACT_APP_SERVER_BASE_URL;
    const body = {
      remoteCommand: selectedNav, userId: appUser.user?.id,
      chargerId: location.state.chargerId,
      connectorId: selectedConnectorValue,
    }
    if (urlValue) { body['ftpUrl'] = urlValue; }
    if (selectedConnectorValue) { body['connectorId'] = selectedConnectorValue }

    hwAxios.post(url + '/charger/remote-command', body).then((response) => {
      setShowLoader(false);
      const message = response.data.message;
      if (response.data.statusCode == 200) {
        toast.success(message);
      } else {
        toast.error(message);
      }
    }).catch((error) => {
      console.log('Error Message', JSON.stringify(error));
      setShowLoader(false);
      toast.error('Something went wrong. Please try again.');
    })
  }, [selectedNav, appUser, location, urlValue, selectedConnectorValue])

  const btnsList = useMemo(() => {
    return [
      {
        buttonText: 'Perform',
        buttonId: 'perform',
        btnClassName: 'primary__btn',
        handleClick: onPerformBtnClick,
        isDisabled: false,
        buttonVariant: 'filled'
      }
    ]
  }, [onPerformBtnClick])

  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    if (selectedNav && (selectedNav.indexOf('ftp') != -1)) {
      setUrlValue('');
    }
    if (selectedNav && (selectedNav.indexOf('connector') != -1) && connectorButtons) {
      setSelectedConnectorValue(connectorButtons!.length > 0 ? connectorButtons[0].radioButtonId : null);
    }
  }, [selectedNav, connectorButtons])

 

  return (
    <div className='charger__remote__commands_dashboard' style={{ height: `${contentHeight - 10}px` }} >
      <DashboardLoader handleClose={setShowLoader} showLoader={showLoader} />
      {selectedNav && <ChargerPanelSubNavListHeader handleNavBtnClick={handleNavBtnClick} selectedNav={selectedNav!}
        dashboardNavRef={dashboardNavRef} navList={commands}
      />}

      <div ref={dashboardContentRef} className='charger__remote__commands_dashboard-content-wrap'
        style={{ height: `${dashboardHeight - 10}px` }}
      >
        { isConnectorRequired && (<div>
          <div className="charger__remote__commands_dashboard-radio-buttons-wrap">
            <RadioButton
              key={selectedConnectorValue || 'radio-button-value-remote'}
              buttonsList={connectorButtons}
              handleSelectedRadioBtnChange={handleConnectorChange}
              selectedRadioBtnValue={selectedConnectorValue}
              radionBtnGrpName={'charger-remote-commands-dashboard-radio-buttons'}
            />
          </div>
        </div>)}

        {isFtpUrlRequired && (<div>
          <div className="charger__remote__commands_dashboard-content">
            <FormLabel className={'rms-form-label'} required>
              URL
            </FormLabel>
            <CustomInput
              inputPlaceHolder="Type here"
              handleInputChange={handleUrlChange}
              inputValue={urlValue || ''}
              inputFieldId="adding_station"
              isRequired={true}
            />
          </div>
        </div>)}

        <div className='charger__remote__commands_dashboard-footer-wrap'>
          <CustomButtonGroup buttonsList={btnsList} buttonGroupClassName={'button__group__footer'} />
        </div>
      </div>
    </div>
  )
}

export default ChargerPanelRemoteCommandsComponent
