import {
  DataGridPro,
  GridColumnHeaders,
  GridFooter,
  GridPaginationModel,
  GridRow,
  useGridApiRef,
  type GridRowProps,
  type GridSortModel,
  type RowPropsOverrides
} from '@mui/x-data-grid-pro'
import {
  LoginLoader,
  MobileViewCards,
  NoDataComponent,
  SortedAscendingIcon,
  SortedDescendingIcon,
  TablePagination,
  TableSkeleton,
  TableTheme,
} from '../globalExports'
import { TableType, } from './TableDashboard'
import React, { type FC, useCallback, useState, JSXElementConstructor, useEffect } from 'react'
import { TablePaginationOwnProps, ThemeProvider } from '@mui/material'
import { checkForScreenResolution, useEffectOnce } from '../globalHooks'
import { LicenseInfo } from '@mui/x-license-pro';

const TableLayout: FC<TableType> = (props) => {
  const {
    tableId,
    tableRows,
    gridColumns,
    mobileViewConfiguration,
    tableClassName,
    layoutView,
    refreshId,
    showSkeleton,
    totalCount,
    listLayoutCallBack,
    renderCustomRowComponent,
    customRowComponent,
    disableHeader,
    handleCallBackFromParent,
    totalCountText,
    showLoader,
    customNoDataComponent,
    pageNumber,
    tablePageSize,
    sortField,
    sortOrder,
    leftPinnedColumns,
    rightPinnedColumns,
    columnPrefrences,
    handleColumnPrefrenceChange
  } = props
  const smallScreenResolution = checkForScreenResolution?.('sm');
  const globalPageSize = 15;
  const apiRef = useGridApiRef();
  LicenseInfo.setLicenseKey('592863905b14abe78770cb5851e88222Tz04Nzg4MixFPTE3NDM5MjM4MzUwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=');

  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: pageNumber || 1,
    pageSize: tablePageSize || globalPageSize
  })
  const handleSortModelChange = useCallback((model: GridSortModel) => {
    const pageSize = paginationModel?.pageSize || globalPageSize;
    if (model?.length > 0) {
      const sortOrder = model?.[0]?.sort === 'asc' ? 'ascending' : model?.[0]?.sort === 'desc' ? 'descending' : null
      const sortField = (model?.[0]?.field?.length ?? 0) > 0 ? model?.[0]?.field : null;
      listLayoutCallBack?.(paginationModel?.page, pageSize, layoutView, sortField, sortOrder)
    }
  }, [layoutView, listLayoutCallBack, paginationModel?.page])

  const onPageChange = useCallback((event, pagenumber) => {
    const sortModel = apiRef && apiRef.current?.getSortModel();
    let sortOrder: string | null = '';
    let sortField: string | null = '';
    if (sortModel?.length > 0) {
      sortOrder = sortModel?.[0]?.sort === 'asc' ? 'ascending' : sortModel?.[0]?.sort === 'desc' ? 'descending' : null
      sortField = (sortModel?.[0]?.field?.length ?? 0) > 0 ? sortModel?.[0]?.field : null;
    }
    listLayoutCallBack?.(pagenumber, paginationModel?.pageSize, layoutView, sortField, sortOrder);
    setPaginationModel({
      page: pagenumber,
      pageSize: paginationModel?.pageSize,
    })
  }, [layoutView, apiRef, paginationModel?.pageSize, listLayoutCallBack])

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleSetPaginationModel = useCallback((event, action) => {
    const sortModel = apiRef && apiRef.current?.getSortModel();
    let sortOrder: string | null = '';
    let sortField: string | null = '';
    if (sortModel?.length > 0) {
      sortOrder = sortModel?.[0]?.sort === 'asc' ? 'ascending' : sortModel?.[0]?.sort === 'desc' ? 'descending' : null
      sortField = (sortModel?.[0]?.field?.length ?? 0) > 0 ? sortModel?.[0]?.field : null;
    }
    const { pageSize, page } = event;
    const { reason } = action;
    if (reason === 'setPaginationModel') {
      listLayoutCallBack?.(page + 1, pageSize, layoutView, sortField, sortOrder);
      setPaginationModel({
        page: page + 1,
        pageSize: pageSize,
      })
    }

  }, [listLayoutCallBack])

  const getPaginationComponent = useCallback(() => {
    return (
      <TablePagination totalCountText={totalCountText} totalCount={totalCount} pageNumber={paginationModel?.page || 1}
        pageSize={paginationModel?.pageSize} onPageChange={(event, pagenumber) => {
          onPageChange(event, pagenumber)
        }} />
    )
  }, [totalCount, globalPageSize, paginationModel?.page, paginationModel?.pageSize, totalCountText, apiRef])

  useEffectOnce(() => {
    if (layoutView === 'list' && !handleCallBackFromParent) {
      const pageSize = paginationModel?.pageSize;
      listLayoutCallBack?.(paginationModel?.page, pageSize, layoutView,);
    }
  });


  /* To Update the pagination Model whenever the some filter is applied or something is searched 
      as we are setting the page to default 1 on the mentioned actions */
  useEffect(() => {
    setPaginationModel({
      page: pageNumber || 1,
      pageSize: tablePageSize || globalPageSize,
    })
  }, [pageNumber, tablePageSize])

  return (
    <ThemeProvider theme={TableTheme}>
      {showSkeleton ? <TableSkeleton rowsNum={globalPageSize} colsNum={5} /> :
        <DataGridPro
          apiRef={apiRef}
          rows={tableRows ?? []}
          columns={gridColumns ?? []}
          disableColumnFilter
          disableColumnPinning={leftPinnedColumns || rightPinnedColumns ? false : true}
          // disableColumnMenu
          key={tableId ?? 'table' + refreshId}
          className={'rms__grid__table ' + tableClassName}
          // rowHeight={30}
          density='compact'
          pagination
          // pinnedColumns={{left: leftPinnedColumns,right:rightPinnedColumns }}
          loading={(showLoader && !showSkeleton) || false}
          slotProps={{
            row: smallScreenResolution?.result || renderCustomRowComponent
              ? (mobileViewConfiguration as Partial<
                GridRowProps & RowPropsOverrides
              >)
              : undefined,
            pagination: totalCount as Partial<TablePaginationOwnProps>
          }}
          initialState={{
            pagination: {
              paginationModel:
              {
                pageSize: tablePageSize || globalPageSize,
                page: pageNumber || 1
              },
            },
            sorting: {
              sortModel: [{ field: sortField ?? '', sort: sortOrder ? (sortOrder === 'ascending' ? 'asc' : 'desc') : null }],
            },
            pinnedColumns: { left: leftPinnedColumns, right: rightPinnedColumns },
            columns: {
              orderedFields: columnPrefrences?.columnOrders,
              dimensions: columnPrefrences?.columnDimensions,
              columnVisibilityModel: columnPrefrences?.columnVisibilityModel,
            }
          }}
          onColumnOrderChange={() => handleColumnPrefrenceChange?.('columnOrder', apiRef?.current?.exportState()?.columns?.orderedFields)}
          onColumnWidthChange={() => handleColumnPrefrenceChange?.('columnResize', apiRef?.current?.exportState()?.columns?.dimensions)}
          onColumnVisibilityModelChange={() => handleColumnPrefrenceChange?.('columnVisibility', apiRef?.current?.exportState()?.columns?.columnVisibilityModel)}
          sortingMode="server"
          onSortModelChange={handleSortModelChange}
          pageSizeOptions={[10, 15, 25, 50, 100]}
          // autoPageSize
          onPaginationModelChange={handleSetPaginationModel}
          slots={{
            footer: layoutView === 'grid' ? (): null => null : GridFooter,
            columnSortedAscendingIcon: SortedAscendingIcon,
            columnSortedDescendingIcon: SortedDescendingIcon,
            row: renderCustomRowComponent && customRowComponent !== undefined ? customRowComponent as JSXElementConstructor<unknown> : smallScreenResolution?.result ? MobileViewCards as JSXElementConstructor<unknown> : GridRow,
            columnHeaders: smallScreenResolution?.result || disableHeader
              ? (): null => null
              : GridColumnHeaders,
            noRowsOverlay: customNoDataComponent as JSXElementConstructor<unknown> || NoDataComponent,
            pagination: getPaginationComponent,
            loadingOverlay: LoginLoader
          }}
        />}
    </ThemeProvider>
  )
}

export default TableLayout
