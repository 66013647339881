

import {
  GoogleMap,
  InfoWindowF, MarkerF, useLoadScript
} from '@react-google-maps/api';
import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ClickAwayListener } from '@mui/base';
import FaultyMarker from '../../rmsAssets/faulty-map.svg';
import OfflineMarker from '../../rmsAssets/offline-map.svg';
import OnlineMarker from '../../rmsAssets/online-map.svg';
import { MapLoader, useEffectOnce } from '../globalExports';
import { Icon } from '../globalIcons';
import './MapLayout.css';

interface MapLayoutType {
  mapFocusedDataPoint?: {
    latitude: number;
    longitude: number;
    id: string;
  };
  usedefaultMapCenter?: boolean;
  mapDataPoints?: Array<{
    latitude: number;
    longitude: number;
    status: string;
    id: string;
    tooltipData?: {
      id: string;
      headerText: string;
      // eslint-disable-next-line @typescript-eslint/ban-types
      headerOnClick?: Function;
      data?: Array<{
        icon: string;
        text: string;
      }>;
    };
  }>;
}

interface MapTooltipProps {
  mapId: string;
}

interface IInfoWindow {
  value: boolean,
  lat: number,
  lng: number,
  id: string,
}

// export type MapLayoutPropTypes = PropTypes.InferProps<typeof MapLayoutType>;

const MapLayout = memo(
  function MapLayout(props: MapLayoutType) {
    const { mapDataPoints, mapFocusedDataPoint, usedefaultMapCenter } = props;
    const [markers, setMarkers] = useState<
      { lat: number; lng: number; status: string; id: string }[]
    >([]);
    const [showInfoWindow, setShowInfoWindow] = useState<IInfoWindow | null>(null);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [map, setMap] = useState<google.maps.Map | null>(null);
    const mapCenterRef = useRef<{ lat: number; lng: number; } | null>(null)
    const { isLoaded } = useLoadScript({
      id: 'google-map-script',
      googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY || 'AIzaSyBBv71oYzENSIjMDyaIll1eZh2OewUGqmw',
    });

    const center = useMemo(() => {
      if (usedefaultMapCenter && markers && markers?.length > 0) {
        const totalLat = markers.reduce((sum, marker) => sum + marker.lat, 0);
        const totalLon = markers.reduce((sum, marker) => sum + marker.lng, 0);

        const averageLat = totalLat / markers.length;
        const averageLon = totalLon / markers.length;
        return {
          lat: averageLat,
          lng: averageLon
        }
      } else {
        return {
          lat: mapFocusedDataPoint?.latitude !== 0 ? Number(mapFocusedDataPoint?.latitude) : 0,
          lng: mapFocusedDataPoint?.longitude !== 0 ? Number(mapFocusedDataPoint?.longitude) : 0,
        }
      }
    }, [mapFocusedDataPoint?.latitude, mapFocusedDataPoint?.longitude, markers, usedefaultMapCenter]);

    useEffect(() => {
      mapCenterRef.current = center
      if (map && mapCenterRef && mapCenterRef.current.lat !== 0 && mapCenterRef.current.lng !== 0 && mapFocusedDataPoint?.latitude === 0 && mapFocusedDataPoint?.longitude === 0) {
        const bounds = new google.maps.LatLngBounds(mapCenterRef.current);
        markers?.forEach(({ lat, lng }) => bounds.extend({ lat, lng }));
        map.fitBounds(bounds);
      }
    }, [center])

    const OPTIONS = {
      minZoom: 4,
      maxZoom: 25,
    }

    const onLoad = useCallback((map): void => {
      const bounds = new google.maps.LatLngBounds(mapCenterRef.current);
      markers?.forEach(({ lat, lng }) => bounds.extend({ lat, lng }));
      map.fitBounds(bounds);
      setMap(map);
    }, [markers, mapCenterRef.current])

    const onUnmount = React.useCallback(() => {
      setMap(null);
    }, []);

    const handleOnMouseOver = useCallback(
      (event, lat: number, lng: number, id: string) => {
        setShowInfoWindow({ value: true, lat, lng, id });
      }, [showInfoWindow]);

    const MapTooltip = memo(function MapTooltip(props: MapTooltipProps) {
      const { mapId } = props;
      const data = mapDataPoints?.find(item => item?.id === mapId);
      return (
        <ClickAwayListener onClickAway={() => setShowInfoWindow(null)}>
          <div className="map__tooltip__container">
            <button className="map__tooltip__container-header-btn" onClick={(e) => {
              e?.stopPropagation();
              data?.tooltipData?.headerOnClick && data?.tooltipData?.headerOnClick();
            }}>{data?.tooltipData?.headerText}</button>
            <div className="map__tooltip__container-content">
              {data?.tooltipData?.data && data?.tooltipData?.data?.length > 0 && data?.tooltipData?.data?.map((item, index) => {
                return (
                  <div key={index} className="map__tooltip__container-content-row">
                    <div className='map__tooltip__container-content-icon-wrap'>
                      <Icon containerClassName={'map__tooltip__container-content-icon'} iconClassName={item?.icon ?? ''} />
                    </div>
                    <div className="map__tooltip__container-content-text">{item?.text}</div>
                  </div>
                )
              })}
            </div>
          </div>
        </ClickAwayListener>
      );
    });

    const fetchMapDataPoints = useCallback(() => {
      if (mapDataPoints && mapDataPoints.length > 0) {
        const updatedMarkers = mapDataPoints.map(
          ({ latitude, longitude, status, id }) => ({
            lat: Number(latitude),
            lng: Number(longitude),
            status: status,
            id,
          })
        );
        setMarkers(updatedMarkers);
      } else if (usedefaultMapCenter) {
        // when the marker count is 0 on TOGGLING to the mapview (and not from a selected charger) so there should be no markers visible
        setMarkers([]);
      }
    }, [mapDataPoints])

    const fetchFocusedTooltip = useCallback(() => {
      if (mapFocusedDataPoint && mapFocusedDataPoint?.id && mapFocusedDataPoint?.latitude !== 0 && mapFocusedDataPoint?.longitude !== 0) {
        setShowInfoWindow({
          value: true,
          lat: Number(mapFocusedDataPoint?.longitude),
          lng: Number(mapFocusedDataPoint?.longitude),
          id: mapFocusedDataPoint?.id,
        })
      } else {
        setShowInfoWindow(null)
      }
    }, [mapFocusedDataPoint])

    useEffectOnce(() => {
      fetchMapDataPoints();
      fetchFocusedTooltip()
    })

    return (
      <div className="map__layout__container">
        {!isLoaded ? (
          <MapLoader />
        ) : (
          <GoogleMap
            mapContainerClassName="map__container"
            center={center}
            options={OPTIONS}
            zoom={10}
            onLoad={onLoad}
            onUnmount={onUnmount}
          >

            {markers.map(({ lat, lng, status, id }, index) => (
              <MarkerF
                onMouseOver={(event) => handleOnMouseOver(event, lat, lng, id)}
                key={`${lat}-${lng}${index}`}
                position={{ lat, lng }}
                icon={status === 'Offline' ? OfflineMarker : status === 'Online' ? OnlineMarker : status === 'Faulty' ? FaultyMarker : 'http://maps.google.com/mapfiles/ms/icons/green-dot.png'}
              >
                {showInfoWindow !== null && showInfoWindow?.value && showInfoWindow?.id === id && (
                  <InfoWindowF position={showInfoWindow}>
                    <MapTooltip mapId={id ?? ''} />
                  </InfoWindowF>
                )}
              </MarkerF>
            ))}
          </GoogleMap>
        )}
      </div>
    );
  })

export default MapLayout;
