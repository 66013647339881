import { type FC } from 'react'
import PropTypes from 'prop-types'
import './HorizontalNavbar.css'
import React  from 'react';

export const HorizontalNavbarProps = {
  navList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string ?? '',
      text: PropTypes.string
    }).isRequired
  ),
  selectedNav: PropTypes.oneOf(['status' , 'settings', 'remote_commands', 'system_info',  'alarm_history', 'admin_actions']),
  onNavClick: PropTypes.func
}

export type HorizontalNavbarPropTypes = PropTypes.InferProps<
  typeof HorizontalNavbarProps
>

const HorizontalNavbar: FC<HorizontalNavbarPropTypes> = (props) => {
  const { navList, selectedNav, onNavClick } = props

  return (
    <div className="horizontal__navbar__container">
      {(navList != null) &&
        navList?.length > 0 &&
        navList?.map((navItem, index) => {
          return (
            <div
              onClick={(e) => onNavClick?.(e, navItem?.id)}
              key={'nav' + index}
              id={'nav-' + navItem?.id}
              className={selectedNav === navItem?.id ? 'horizontal__nav selected ' : 'horizontal__nav'}
            >
              {navItem?.text}
            </div>
          )
        })}
    </div>
  )
}

export default HorizontalNavbar
