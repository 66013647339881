import * as React from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import LinkButton, { LinkButtonPropTypes } from '../LinkButton/LinkButton';
import './CustomBreadCrumbs.css'

export interface BreadCrumbObject {
  objecType: 'link' | 'text'
  linkBtnProps?: LinkButtonPropTypes
  textProps?: string
}
export interface CustomBreadCrumbsProps {
  containerId?: string
  containerClassName?: string
  breadCrumbs: BreadCrumbObject[]
}

const CustomBreadCrumbs: React.FC<CustomBreadCrumbsProps> = (props) => {
  const { containerId, containerClassName, breadCrumbs } = props

  return (
    <Stack key={containerId} className={'custom__breadCrumb__wrap ' + containerClassName} spacing={1}>
      <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
        {breadCrumbs && breadCrumbs?.length > 0 && breadCrumbs?.map((item, index) => {
          return (
            <div key={index} className='custom__breadCrumb__wrap-content-column'>
              {item?.objecType === 'link' && item?.linkBtnProps &&
                <LinkButton {...item?.linkBtnProps} />
              }
              {item?.objecType === 'text' && item?.textProps &&
                <Typography variant="h6" style={{ fontSize: '14px', fontFamily: 'var(--font-family)', lineHeight: 0 }}>
                  {item?.textProps}
                </Typography>
              }
            </div>
          )
        })}
      </Breadcrumbs>
    </Stack>
  );
}

export default CustomBreadCrumbs