import { type FC, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Collapse, useMediaQuery } from '@mui/material'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Typography from '@mui/material/Typography'
import List from '@mui/material/List'

import useTheme from '@mui/system/useTheme'
import React  from 'react';
import { Arrow } from '../../globalUtils/globalIcons'

export const SideBarMenuItem = {
  text: PropTypes.string.isRequired,
  path: PropTypes.any,
  nestedMenus: PropTypes.array,
  className: PropTypes.string,
  styles: PropTypes.object,
  selected: PropTypes.string,
  handleSelect: PropTypes.func,
  toggleOpen: PropTypes.any,
  handleOpenedMenu: PropTypes.func,
  parentLabel: PropTypes.string,
  handleDrawerClose: PropTypes.func
}

type SideBarMenuItemPropTypes = PropTypes.InferProps<typeof SideBarMenuItem>
type SideBarMenuItemPropsWithoutItems = Omit<
SideBarMenuItemPropTypes,
'nestedMenus'
>

export type SideBarMenuItemProps = SideBarMenuItemPropsWithoutItems & {
  nestedMenus?: SideBarMenuItemProps[]
}

const SidebarItem: FC<SideBarMenuItemProps> = (props) => {
  const navigate = useNavigate()
  const {
    text,
    path,
    nestedMenus = [],
    className,
    styles,
    selected,
    parentLabel,
    handleSelect,
    toggleOpen,
    handleOpenedMenu,
    handleDrawerClose
  } = props

  const isExpandable = nestedMenus && nestedMenus.length > 0
  const theme = useTheme()
  const isSmScreen = useMediaQuery(theme.breakpoints.down('sm'))

  const handleItemClick = useCallback(
    (label) => {
      if (path) {
        navigate(path)
        handleSelect?.(path)
        if (label !== toggleOpen) {
          handleOpenedMenu?.(label)
        }
        if (isSmScreen) {
          handleDrawerClose?.(false)
        }
      } else if (isExpandable) {
        if (label !== toggleOpen) {
          handleOpenedMenu?.(label)
          if (nestedMenus[0]?.path) {
          navigate(nestedMenus[0].path)
          // if (isSmScreen) {
          //   handleDrawerClose?.(false)
          // }
          handleSelect?.(nestedMenus[0].path)
        } 
        } else{
          handleOpenedMenu?.(false)
          navigate('/dashboard')
          handleSelect?.('/dashboard')
        }
        
      }
    },
    [handleDrawerClose, handleOpenedMenu, handleSelect, isExpandable, isSmScreen, navigate, nestedMenus, path, toggleOpen]
  )

  const nestedMenuStyles = {
    color: 'var(--primary-text-color)',
    fontSize: '13px',
    fontWeight: 400,
    fontFamily: 'Inter',
    letterSpacing: '0.2px'
  }

  const MenuItemRoot = (
    <ListItem
      className={
        toggleOpen === parentLabel && isExpandable
          ? 'rms__sidebar__item selected expandable'
          : selected === path && !isExpandable
            ? 'rms__sidebar__item selected'
            : isExpandable
              ? 'rms__sidebar__item expandable'
              : 'rms__sidebar__item'
      }
      disablePadding
      onClick={() => {
        handleItemClick(parentLabel)
      }}
    >
      <ListItemButton style={{ paddingTop: 8, paddingBottom: 8 }}>
        <ListItemIcon style={{ minWidth: '38px' }}>
          <div className={'rms__sidebar__icon rms__icon ' + className}></div>
        </ListItemIcon>
        <ListItemText
          disableTypography
          primary={
            <Typography
              className="rms__sidebar__text"
              variant="body2"
              style={{
                color: 'black',
                fontSize: '14px',
                fontWeight: 400,
                fontFamily: 'Inter',
                letterSpacing: '0.2px',
                ...styles
              }}
            >
              {text}
            </Typography>
          }
        />
        {isExpandable && !(toggleOpen === parentLabel) && (
          <Arrow color={'black'} direction={'right'} />
        )}
        {isExpandable && toggleOpen === parentLabel && (
          <Arrow color={'black'} direction={'down'} />
        )}
      </ListItemButton>
    </ListItem>
  )

  const MenuItemChildren = isExpandable
    ? (
    <Collapse in={toggleOpen === parentLabel} timeout="auto" unmountOnExit className="side__bar__collapse__wrap">
      <List
        className="rms__nested__menu__wrapper"
        component="nav"
        disablePadding
      >
        {nestedMenus.map((item, index) => (
          <SidebarItem
            toggleOpen={toggleOpen}
            handleOpenedMenu={handleOpenedMenu}
            selected={selected}
            handleSelect={handleSelect}
            styles={nestedMenuStyles}
            {...item}
            key={index}
          />
        ))}
      </List>
    </Collapse>
      )
    : null

  return (
    <>
      {MenuItemRoot}
      {MenuItemChildren}
    </>
  )
}

SidebarItem.propTypes = SideBarMenuItem

export default SidebarItem
