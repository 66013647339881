import access from '../../auth/service/AccessControl';

export const chargerLogsNavList = [
    {
      id: 'status',
      text: 'Status',
      isAccessible: access?.reports?.chargerLog?.status?.view()
    },
    {
      id: 'settings',
      text: 'Settings',
      isAccessible: access?.reports?.chargerLog?.setting?.view()
    },
    {
        id: 'systemInfo',
        text: 'System Info',
        isAccessible: access?.reports?.chargerLog?.systemInfo?.view()
    },
]