import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
    DashboardHeader,
    FilledViewParametersCards,
    useAppDispatch,
    useAppSelector,
    TableLayout,
    SearchBox,
    useEffectOnce,
    CustomStatus,
    CustomBreadCrumbs,
    CustomTooltipWithLabel
} from '../../../globalUtils/globalExports';
import {
    chargerTypes,
    fetchParametersDevicesData,
    getChargerReducer,
    setChargerParametersDevicesListTableProps,
    fetchParameterDevicesStatusData,
} from '../../../rmsReduxStore/reduxExports';
import Typography from '@mui/material/Typography';



import './ViewParameter.css';
import { AlarmCardTableSkeleton } from '../../../globalUtils/SkeletonDesign/SkeletonDesign';
import { cloneDeep } from 'lodash';
import { GridRenderCellParams, GridValidRowModel } from '@mui/x-data-grid-pro';
import { createBreadCrumbProps, formatDateInDateTimeFormatNotSeconds } from '../../../globalUtils/globalHooks';

interface HeaderArray {
    title: string,
    value: string
}

const ViewParameters = memo(function ViewParameters() {
    const dispatch = useAppDispatch();
    const location = useLocation();
    const parameterData = location?.state && location?.state?.parameterData;
    const { chargerParametersDevices, chargerParametersDevicesCount, chargerParametersDevicesTableLoader, chargerParametersDevicesListTableProps, parameterDevicesStatusCount } = useAppSelector(getChargerReducer);
    const [setParameterDetail, setSetParameterDetail] = useState<HeaderArray[] | null>(null);
    const [tableWrapHeight, setTableWrapHeight] = useState(0)
    const [forceRefreshId, setForceRefreshId] = useState(new Date().getTime())
    const totalWrapRef = useRef<HTMLDivElement>(null)
    const headerParamWrapRef = useRef<HTMLDivElement>(null)
    const summaryWrapRef = useRef<HTMLDivElement>(null)
    const parameterDevicesHeaderCardsRef = useRef<HTMLDivElement>(null);
    const searchParamWrapRef = useRef<HTMLDivElement>(null)
    const tableRef = useRef<HTMLDivElement>(null)
    const chargerParametersDevicesListTablePropsRef = useRef(chargerParametersDevicesListTableProps);
    const parametersDevicesRef = useRef(chargerParametersDevices);

    useEffect(() => {
        chargerParametersDevicesListTablePropsRef.current = chargerParametersDevicesListTableProps;
    }, [chargerParametersDevicesListTableProps]);

    useEffect(() => {
        parametersDevicesRef.current = chargerParametersDevices;
    }, [chargerParametersDevices]);

    const updateSize = useCallback(() => {
        setForceRefreshId(new Date().getTime())
    }, [])

    useEffect(() => {
        const ro = new ResizeObserver(() => {
            updateSize()
        })
        if (tableRef.current != null) {
            ro.observe(tableRef.current)
        }
        window.addEventListener('resize', updateSize)
        return () => {
            window.removeEventListener('resize', updateSize)
        }
    }, [tableRef])

    useEffect(() => {
        const calculateTableWrapHeight = (): void => {
            if (((headerParamWrapRef?.current) != null) && ((searchParamWrapRef?.current) != null) && ((totalWrapRef?.current) != null)) {
                const totalHeight = totalWrapRef?.current?.getBoundingClientRect?.().height || 0
                const headerHeight = headerParamWrapRef?.current?.getBoundingClientRect?.().height || 0
                const summaryCardHeight = summaryWrapRef?.current?.getBoundingClientRect?.().height || 0
                const searchHeight = searchParamWrapRef?.current?.getBoundingClientRect?.().height || 0
                const statusCardsHeight = parameterDevicesHeaderCardsRef?.current?.getBoundingClientRect?.().height || 0
                const tableWrapHeight = totalHeight - (headerHeight + searchHeight + summaryCardHeight + statusCardsHeight);
                setTableWrapHeight(tableWrapHeight - 15)
            }
        }
        calculateTableWrapHeight()
        window.addEventListener('resize', calculateTableWrapHeight)
        return () => {
            window.removeEventListener('resize', calculateTableWrapHeight)
        }
    })


    // TODO: Status Icon display
    const getStatusIconClass = {
        'InProgress': 'status__in-progress__parameter__icon',
        'Scheduled': 'status__scheduled__parameter__icon',
        'Completed': 'status__completed__parameter__icon',
        'Success': 'status__device_parameter__success__icon',
        'Failed': 'status__device_parameter_failed__icon'
    }

    useEffectOnce(() => {
        const headerArray: HeaderArray[] = [
            {
                title: 'Parameter',
                value: parameterData?.parameterName
            },
            {
                title: 'Set Value',
                value: parameterData?.parameterType ==='Date' ?formatDateInDateTimeFormatNotSeconds(parameterData?.value):(parameterData?.unit && parameterData?.value ? parameterData?.value + parameterData?.unit : parameterData?.value)
            },
            {
                title: 'Set By',
                value: parameterData?.createdBy
            },
            {
                title: 'Requested On',
                value: parameterData?.createdOn ? formatDateInDateTimeFormatNotSeconds(parameterData?.createdOn) : '',
            },
            {
                title: 'Set On',
                value: parameterData?.triggerTime ? formatDateInDateTimeFormatNotSeconds(parameterData?.triggerTime) : '',
            },

            {
                title: 'Execution Status',
                value: parameterData?.status ? <CustomStatus statusText={parameterData?.status} statusIconClassName={getStatusIconClass[parameterData?.status]} /> : <></>

            }
        ]

        setSetParameterDetail(headerArray);
        dispatch(fetchParameterDevicesStatusData({
            paramId: parameterData?.id,
        }));
    });
 
    const parameterDevicesStatusCard = useMemo(() => {
        return [
            {
                cardId: 'paramsCount',
                headerText: 'totalList',
                footerText: (parameterDevicesStatusCount?.total) ? parameterDevicesStatusCount?.total?.toString() : '0',
                bgColor: 'grey',
            },
            {
                cardId: 'failed',
                headerText: 'failed',
                footerText: parameterDevicesStatusCount?.failedCount.toString(),
                bgColor: 'var(--critical-alarm-color)'
            },
            {
                cardId: 'InProgress',
                headerText: 'inProgress',
                footerText: parameterDevicesStatusCount?.inProgressCount?.toString(),
                bgColor: 'var(--card-yellow-color)'
            },

            {
                cardId: 'Success',
                headerText: 'success',
                footerText: parameterDevicesStatusCount?.successCount?.toString(),
                bgColor: 'var(--card-green-color)'
            }
          
        ]
    }, [parameterDevicesStatusCount]);

    const getTableRows = useCallback(() => {
        return chargerParametersDevices !== null && chargerParametersDevices?.length > 0
            ? chargerParametersDevices?.map((chargerParametersDevices) => {
                const { chargerId, ...rest } = chargerParametersDevices;
                return { id: chargerId, ...rest };
            })
            : [];
    }, [chargerParametersDevices]);

    const devicesSubTableColumns = useMemo(() => {
        return [
            {
                field: 'chargerVisibleId',
                headerName: 'Charger',
                minWidth: 90,
                editable: false,
                flex: .5,
            },
            {
                field: 'customerName',
                headerName: 'Customer',
                minWidth: 90,
                editable: false,
                flex: .5,
            },
            {
                field: 'chargingStationName',
                headerName: 'Station Name',
                minWidth: 90,
                editable: false,
                flex: .5,
            },
            {
                field: 'oldValue',
                headerName: 'Previous Value',
                minWidth: 90,
                editable: false,
                flex: .5,
                renderCell: (params: GridRenderCellParams<GridValidRowModel, Date>): JSX.Element => {
                    const rowData = params?.row;
                    let value = rowData?.oldValue
                    const labelId = rowData?.id
                    if (rowData?.valueType == 'Date') {
                      value = rowData?.oldValue ? formatDateInDateTimeFormatNotSeconds(rowData?.oldValue) : '-'
                    }
                    else{
                        value= parameterData?.unit && value ? value + parameterData?.unit : value
                    }
                    return (
                        <>
                           <CustomTooltipWithLabel label={value} labelId={labelId} />
                        </>
                      )
                  },
            },
            {
                field: 'newValue',
                headerName: 'Updated Value',
                minWidth: 90,
                editable: false,
                flex: .5,
                renderCell: (params: GridRenderCellParams<GridValidRowModel, Date>): JSX.Element => {
                    const rowData = params?.row;
                    let value = rowData?.newValue
                    const labelId = rowData?.id
                    if (rowData?.valueType == 'Date') {
                      value = rowData?.newValue ? formatDateInDateTimeFormatNotSeconds(rowData?.newValue) : ''
                    }
                    else{
                        value= parameterData?.unit && value ? value + parameterData?.unit : value
                    }
                    return (
                      <>
                         <CustomTooltipWithLabel label={value} labelId={labelId} />
                      </>
                    )
                  },
            },

            {
                field: 'status',
                headerName: 'Status',
                minWidth: 90,
                flex: .5,
                editable: false,
                renderCell: (params: GridRenderCellParams<GridValidRowModel, Date>): JSX.Element => {
                    return <CustomStatus statusText={params?.row?.status}
                        statusIconClassName={getStatusIconClass[params?.row?.status]} />;
                },
            },
        ];
    }, []);


    const onChangeOfSearchText = useCallback(async (searchTerm) => {
        if (chargerParametersDevicesListTablePropsRef?.current) {
            await layoutCallback(
                1,
                chargerParametersDevicesListTablePropsRef?.current?.pageSize,
                'list',
                chargerParametersDevicesListTablePropsRef?.current?.sortBy,
                chargerParametersDevicesListTablePropsRef?.current?.order,
                searchTerm
            );
        }
    }, [chargerParametersDevices, chargerParametersDevicesListTablePropsRef?.current,]);

    const layoutCallback = useCallback(async (pageNumber: number, pageSize: number, view: string, sortField: string, sortOrder: string, searchTerm) => {
        const tableProps: IChargerListTableProps = cloneDeep(chargerParametersDevicesListTablePropsRef?.current)
        await dispatch(fetchParametersDevicesData({
            paramId: parameterData?.id,
            sortBy: sortField || '',
            order: sortOrder || '',
            pageSize,
            pageNumber,
            searchTerm: searchTerm != null && searchTerm != undefined ? searchTerm : chargerParametersDevicesListTablePropsRef?.current?.searchTerm
        }));

        if (tableProps) {
            tableProps.sortBy = sortField || ''
            tableProps.order = sortOrder || 'ascending';
            tableProps.pageNumber = pageNumber;
            tableProps.pageSize = pageSize;
            if (searchTerm != null && searchTerm != undefined) {
                tableProps.searchTerm = searchTerm;
            }
        }
        await dispatch(setChargerParametersDevicesListTableProps(tableProps))
    }, [chargerParametersDevicesListTablePropsRef?.current, dispatch]);


    const parametersDevicesListCleanUpState = useCallback(() => {
        const action = {
            type: chargerTypes.CLEAR_CHARGER_PARAMETER_DEVICES_LIST_DATA,
        }
        dispatch(action);
    }, [])

    useEffect(() => {
        window.addEventListener('beforeunload', parametersDevicesListCleanUpState);
        return (): void => {
            parametersDevicesListCleanUpState()
            window.removeEventListener('beforeunload', parametersDevicesListCleanUpState);
        }
    }, [])

    const parameterDevicesStatusCleanUpState = useCallback(() => {
        const action = {
            type: chargerTypes.CLEAR_PARAMETER_DEVICES_STATUS_COUNT,
        }
        dispatch(action);
    }, [])

    useEffect(() => {

        window.addEventListener('beforeunload', parameterDevicesStatusCleanUpState);
        return (): void => {
            parameterDevicesStatusCleanUpState()
            window.removeEventListener('beforeunload', parameterDevicesStatusCleanUpState);
        }
    }, [])


    // TODO: BreadCrumb navigation Props
    const breadCrumbs = createBreadCrumbProps({
        breadCrumbProps:
            [
                {
                    objectType: 'link',
                    id: 'setParameters',
                },
                {
                    objectType: 'text',
                    id: 'text',
                    text: location?.state?.parameterData?.parameterName
                }
            ]
    })

    return (
        <div className='charger__broadcast__message__panel' ref={totalWrapRef}>
            <div className='charger__panel-header-wrap' ref={headerParamWrapRef}>
                <div className='back__btn-container'>
                    <CustomBreadCrumbs breadCrumbs={breadCrumbs} />
                </div>
                {
                    <div className='view-parameter-header'>
                        <div className='charger__panel-header-content-wrap'>
                            <DashboardHeader header={location?.state?.parameterData?.parameterName} />
                        </div>
                    </div>
                }
            </div>
            <div className='parameters-cards-summary-wrapper' ref={summaryWrapRef}>
                <div
                    key={'view-parameter-sub-header'}
                    className='parameter_sub__header__grey__card'>
                    <div className='parameter_sub__header__grey__card__content__wrap'>
                        {setParameterDetail?.map((arrayData, index) => {
                            return (
                                <div key={index} className={'parameter_sub__header__grey__card__content ' + index}>
                                    <div className='parameter_sub__header__grey__card__content-title'>
                                        {arrayData?.title}
                                    </div>
                                    <div className='parameter_sub__header__grey__card__content-value'>
                                        {
                                               typeof arrayData?.value === 'string' ? (
                                                <CustomTooltipWithLabel label={arrayData?.value} labelId={parameterData?.id} />
                                            ) :
                                            (arrayData?.value)
                                        }
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>


            </div>
            <div ref={parameterDevicesHeaderCardsRef} className='charger__panel-cards-wrap'  >
                {parameterDevicesStatusCard ?
                    <div className='charger__panel-alarm-cards'>
                        {parameterDevicesStatusCard && parameterDevicesStatusCard?.length > 0 && parameterDevicesStatusCard?.map((parameterDevicesStatusHeaderCard, index) => <FilledViewParametersCards key={'parameter-status-card-' + index} cardId={parameterDevicesStatusHeaderCard?.cardId} headerText={parameterDevicesStatusHeaderCard?.headerText} footerText={parameterDevicesStatusHeaderCard?.footerText} bgColor={parameterDevicesStatusHeaderCard?.bgColor} />)}
                    </div> :
                    <AlarmCardTableSkeleton />
                }
            </div>
            <div>
                <div ref={searchParamWrapRef}>
                    <Typography variant="h6" style={{ display: 'flex', alignItems: 'center', fontSize: '18px', color: '#06BFBF', paddingRight: '2px', paddingLeft: '5px' }}>
                        Chargers
                    </Typography>
                    <div className='set-parameter-view-search' >
                        <SearchBox searchFieldId='manage-charger-parameters-devices-search-box'
                            handleSearch={onChangeOfSearchText} />
                    </div>
                </div>

                <div style={{ height: tableWrapHeight }}  >
                    <TableLayout
                        key={'table'}
                        gridColumns={devicesSubTableColumns}
                        tableRows={getTableRows()}
                        tableId={'set-parameters-view'}
                        tableClassName={'set__parameters__view'}
                        refreshId={forceRefreshId}
                        pageNumber={chargerParametersDevicesListTableProps?.pageNumber}
                        listLayoutCallBack={layoutCallback}
                        renderCustomRowComponent={true}
                        disableHeader={false}
                        layoutView={'list'}
                        totalCount={chargerParametersDevicesCount}
                        totalCountText={'chargers'}
                        showSkeleton={false}
                        showLoader={chargerParametersDevicesTableLoader}
                        tablePageSize={chargerParametersDevicesListTableProps?.pageSize}
                    />
                </div>

            </div>
        </div>
    )
});

export default ViewParameters
