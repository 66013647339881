import { Checkbox, ThemeProvider } from '@mui/material'
import React, { FC } from 'react'
import { checkBoxTheme } from '../globalExports';

interface ICheckBox {
  checked: boolean
  isDisabled?: boolean
}

const CheckBox: FC<ICheckBox> = (props) => {
  const { checked, isDisabled } = props;
  return (
    <ThemeProvider theme={checkBoxTheme}>
      <Checkbox
        checked={checked}
        sx={{
          color: 'var(--grey-color)',
          '&.Mui-checked': {
            color: 'var(--secondary-blue-color)',
          },
          '&.Mui-disabled': {
            color: 'var(--card-grey-color)'
          },
          '& .MuiSvgIcon-root': { fontSize: 20 }
        }}
        disabled={isDisabled ?? false}
        disableRipple={true} />
    </ThemeProvider>
  )
}

export default CheckBox