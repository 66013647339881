/* eslint-disable react/display-name */
import React, { useCallback, forwardRef } from 'react';
import ChargerStatusConnectorDetails from './ChargerStatusConnectorDetails';
import ChargerStatusHistory from './ChargerStatusHistory';
import './chargerPanelStatus.css';
import { useEffectOnce } from '../../../../globalUtils/globalHooks';
import { useLocation } from 'react-router-dom';
import { useAppDispatch } from '../../../../rmsReduxStore/rmsReduxHooks';
import { fetchChargerConnectorStatus } from '../../../../rmsReduxStore/reduxExports';

interface IChargerPanelStatusDashboard {
  contentHeight: number;
  // eslint-disable-next-line @typescript-eslint/ban-types
  apiCallForChargerStatusHistoryGraph: Function;
  // eslint-disable-next-line @typescript-eslint/ban-types
  apiCallbackForHistoryLogs: Function;
}

export type ChargerParameterRef = HTMLDivElement;

const ChargerPanelStatusSecondOption = forwardRef<ChargerParameterRef, IChargerPanelStatusDashboard>(
  function (props) {
    const { contentHeight, apiCallForChargerStatusHistoryGraph, apiCallbackForHistoryLogs } = props;
    const dispatch = useAppDispatch();
    const location = useLocation();

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const asynchronousCallbacks = useCallback( () => {
      apiCallForChargerStatusHistoryGraph?.();
      dispatch(fetchChargerConnectorStatus({ chargerId: location?.state?.chargerId }));
      apiCallbackForHistoryLogs();
    }, [dispatch]);

    useEffectOnce(() => {
      // asynchronousCallbacks();
    });

    return (
      <div className='charger__panel__status__dashboard' style={{ height: `${contentHeight - 10}px` }}>
        <div className='charger__panel__status__dashboard-top'>
          <ChargerStatusConnectorDetails />
          <ChargerStatusHistory />
        </div>
        {/* <ChargerStatusParameters ref={ref} /> */}
      </div>
    );
  }
);

export default ChargerPanelStatusSecondOption;
