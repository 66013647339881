import type React from 'react'
import { CustomButton, GreyBtnWithIconTheme } from '../../../globalUtils/globalExports'
import { type FC } from 'react'

interface ChargerPanelSubNav {
  buttonText: string
  buttonIconClassName: string
  buttonId: string

}
interface ChargerPanelSubNavList {
  dashboardNavRef: React.Ref<HTMLDivElement>
  navList: ChargerPanelSubNav[]
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleNavBtnClick: any
  selectedNav: string
}

const ChargerPanelSubNavListHeader: FC<ChargerPanelSubNavList> = ({ dashboardNavRef, selectedNav, navList, handleNavBtnClick }) => {
  return (
    <div ref={dashboardNavRef} className="charger__panel__settings_dashboard-header">
      {navList?.length > 0 &&
        navList?.map((navItem, index) => {
          return (
            <div
              key={'navbtn' + index}
              className={
                selectedNav === navItem?.buttonId
                  ? 'charger__panel__settings_dashboard__nav__btn-wrapper selected'
                  : 'charger__panel__settings_dashboard__nav__btn-wrapper  '
              }
            >
              <CustomButton
                customTheme={GreyBtnWithIconTheme}
                btnClassName="charger__panel__settings_dashboard__nav__btn"
                buttonText={navItem?.buttonText}
                buttonIconClassName={navItem?.buttonIconClassName}
                buttonId={navItem?.buttonId}
                handleClick={(e) => handleNavBtnClick?.(e, navItem.buttonId)}
                handleKeyPress={() => { }}
              />
            </div>
          )
        })}
    </div>
  )
}

export default ChargerPanelSubNavListHeader
