/* eslint-disable @typescript-eslint/no-explicit-any */
import { isAxiosError } from 'axios';
import hwaxios from '../api-service/HWAxios';
import { fetchOrgReferenceData, setFilterData, userTypes } from '../reduxExports'
import rmsStore from '../rmsStore';

const url = process.env.REACT_APP_SERVER_BASE_URL

// TODO: Users List API
export const fetchUsersData: any = (reqBody: unknown) => async (dispatch: (arg0: Partial<UserAction>) => void): Promise<void> => {
  try {
    const loaderStartAction: Partial<UserAction> = {
      type: userTypes.SET_LOADER,
      loader: true
    }
    dispatch(loaderStartAction);
    const response = await hwaxios.post(url + '/user-dashboard', reqBody)
    const action: Partial<UserAction> = {
      type: userTypes.FETCH_USERS_DATA,
      users: response.data?.data?.users,
      userCount: response.data?.data?.userCount,
    }
    dispatch(action)
    if (response?.status === 200) {
      const loaderEndAction: Partial<UserAction> = {
        type: userTypes.SET_LOADER,
        loader: false
      }
      dispatch(loaderEndAction);
    }
  } catch (error) {
    const loaderEndAction: Partial<UserAction> = {
      type: userTypes.SET_LOADER,
      loader: false
    }
    dispatch(loaderEndAction);
    console.error(error)
    throw error
  }
}

// TODO: Add user
export const createUser: any = (reqBody: unknown) => async () => {
  try {
    const { data, status } = await hwaxios.post(url + '/user', reqBody)
    return { data, status };
  } catch (error) {
    if (isAxiosError(error)) {
      if (error.response) {
        const data = error.response.data;
        const status = error.response.status;
        return { data, status };
      }
    } else {
      return {
        data: 'Something went wrong', status: 400
      }
    }
  }
}

// TODO: Update user
export const updateUser: any = (reqBody: unknown) => async () => {
  try {
    const { data, status } = await hwaxios.put(url + '/user', reqBody)
    return { data, status };
  } catch (error) {
    if (isAxiosError(error)) {
      if (error.response) {
        const data = error.response.data;
        const status = error.response.status;
        return { data, status };
      }
    } else {
      return {
        data: 'Something went wrong', status: 400
      }
    }
  }
}

// TODO: Delete individual user 
export const deleteUser: any = (reqParams: unknown) => async () => {
  try {
    const { data, status } = await hwaxios.delete(url + '/user', { params: reqParams })
    return { data, status };
  } catch (error) {
    if (isAxiosError(error)) {
      if (error.response) {
        const data = error.response.data;
        const status = error.response.status;
        return { data, status };
      }
    } else {
      return {
        data: 'Something went wrong', status: 400
      }
    }
  }
}

// TODO: Individual User Details API
export const fetchIndividualUserDetail: any = (reqParams: unknown) => async (dispatch: (arg0: Partial<UserAction>) => any): Promise<any> => {
  try {
    const loaderStartAction: Partial<UserAction> = {
      type: userTypes.SET_LOADER,
      loader: true
    }
    dispatch(loaderStartAction);

    const response = await hwaxios.get(url + '/user', { params: reqParams })
    const action: Partial<UserAction> = {
      type: userTypes.FETCH_USER_DETAIL_DATA,
      individualUserDetail: response?.data?.data,
    }
    dispatch(action)
    if (response?.status === 200) {
      const loaderEndAction: Partial<UserAction> = {
        type: userTypes.SET_LOADER,
        loader: false
      }
      dispatch(loaderEndAction);
    }
    return response;
  } catch (error) {
    const loaderEndAction: Partial<UserAction> = {
      type: userTypes.SET_LOADER,
      loader: false
    }
    dispatch(loaderEndAction);
    console.error(error)
    throw error
  }
}

//TODO: USER FORM CHARGER LIST BT CUSTOMER BY ORG
export const fetchChargersByCustomersByOrg: any = (reqBody) => async (dispatch: (arg0: Partial<UserAction>) => void): Promise<void> => {
  try {
    const response = await hwaxios.post(url + '/ref/org-charger', reqBody)
    const action: Partial<UserAction> = {
      type: userTypes.FETCH_CHARGERS_BY_CUSTOMER_BY_ORG,
      chargerListByCustomerByOrg: response?.data?.data,
    }
    dispatch(action)
  } catch (error) {
    console.error(error)
    throw error
  }
}

// TODO: Export USERS
export const exportToCsvForUserList: any = (reqParams: unknown) => async () => {
  try {
    const { data, status } = await hwaxios.post(url + '/users/export', reqParams)
    return { data, status };
  } catch (error) {
    if (isAxiosError(error)) {
      if (error.response) {
        const data = error.response.data;
        const status = error.response.status;
        return { data, status };
      }
    } else {
      return {
        data: 'Something went wrong', status: 400
      }
    }
  }
}

//TODO: Role List API
export const fetchRoles: any = (reqBody: unknown) => async (dispatch: (arg0: Partial<UserAction>) => void): Promise<void> => {
  try {
    const loaderStartAction: Partial<UserAction> = {
      type: userTypes.SET_LOADER,
      loader: true
    }
    dispatch(loaderStartAction);
    const response = await hwaxios.post(url + '/role-dashboard', reqBody)
    const action: Partial<UserAction> = {
      type: userTypes.FETCH_ROLES_DATA,
      roles: response.data?.data?.roles,
      rolesCount: response.data?.data?.roleCount,
    }
    dispatch(action)
    if (response?.status === 200) {
      const loaderEndAction: Partial<UserAction> = {
        type: userTypes.SET_LOADER,
        loader: false
      }
      dispatch(loaderEndAction);
    }
  } catch (error) {
    const loaderEndAction: Partial<UserAction> = {
      type: userTypes.SET_LOADER,
      loader: false
    }
    dispatch(loaderEndAction);
    console.error(error)
    throw error
  }
}

// TODO: Delete individual role 
export const deleteRole: any = (reqParams: unknown) => async () => {
  try {
    const { data, status } = await hwaxios.delete(url + '/role', { params: reqParams })
    return { data, status };
  } catch (error) {
    if (isAxiosError(error)) {
      if (error.response) {
        const data = error.response.data;
        const status = error.response.status;
        return { data, status };
      }
    } else {
      return {
        data: 'Something went wrong', status: 400
      }
    }
  }
}

// TODO: ADD ROLE
export const createRole: any = (reqBody: unknown) => async () => {
  try {
    const { data, status } = await hwaxios.post(url + '/role', reqBody)
    return { data, status };
  } catch (error) {
    if (isAxiosError(error)) {
      if (error.response) {
        const data = error.response.data;
        const status = error.response.status;
        return { data, status };
      }
    } else {
      return {
        data: 'Something went wrong', status: 400
      }
    }
  }
}

// TODO: UPDATE ROLE
export const updateRole: any = (reqBody: unknown) => async () => {
  try {
    const { data, status } = await hwaxios.put(url + '/role', reqBody)
    return { data, status };
  } catch (error) {
    if (isAxiosError(error)) {
      if (error.response) {
        const data = error.response.data;
        const status = error.response.status;
        return { data, status };
      }
    } else {
      return {
        data: 'Something went wrong', status: 400
      }
    }
  }
}

// TODO: EXPORT ROLES
export const exportToCsvForRoleList: any = (reqParams: unknown) => async () => {
  try {
    const { data, status } = await hwaxios.post(url + '/role/export', reqParams)
    return { data, status };
  } catch (error) {
    if (isAxiosError(error)) {
      if (error.response) {
        const data = error.response.data;
        const status = error.response.status;
        return { data, status };
      }
    } else {
      return {
        data: 'Something went wrong', status: 400
      }
    }
  }
}

//TODO: USER,ROLE AND ORG LIST TABLE PROPS
export const setTableProps: any = (data, screen) => (dispatch: (arg0: Partial<UserAction>) => void) => {
  if (screen === 'usersList') {
    const action = {
      type: userTypes.SET_USER_LIST_TABLE_PROPS,
      userListTableProps: data,
    };
    dispatch(action);
  } else if (screen === 'rolesList') {
    const action = {
      type: userTypes.SET_ROLE_LIST_TABLE_PROPS,
      roleListTableProps: data,
    }
    dispatch(action);
  } else if (screen === 'orgsList') {
    const action = {
      type: userTypes.SET_ORGS_LIST_TABLE_PROPS,
      orgListTableProps: data,
    }
    dispatch(action);
  }
}

//TODO: ORG FORM CHARGER LIST BT CUSTOMER
export const fetchChargersByCustomers: any = (reqBody) => async (dispatch: (arg0: Partial<UserAction>) => void): Promise<void> => {
  try {
    const response = await hwaxios.post(url + '/ref/charger-by-customer', reqBody)
    const action: Partial<UserAction> = {
      type: userTypes.FETCH_CHARGERS_BY_CUSTOMER,
      chargerListByCustomer: response?.data?.data,
    }
    dispatch(action)
  } catch (error) {
    console.error(error)
    throw error
  }
}

// TODO: ORGS LIST API
export const fetchOrgsData: any = (reqBody: unknown) => async (dispatch: (arg0: Partial<UserAction>) => void): Promise<void> => {
  try {
    const loaderStartAction: Partial<UserAction> = {
      type: userTypes.SET_LOADER,
      loader: true
    }
    dispatch(loaderStartAction);
    const response = await hwaxios.post(url + '/org-dashboard', reqBody)
    const action: Partial<UserAction> = {
      type: userTypes.FETCH_ORGS_DATA,
      orgs: response.data?.data?.orgs,
      orgsCount: response.data?.data?.orgCount,
    }
    dispatch(action)
    if (response?.status === 200) {
      const loaderEndAction: Partial<UserAction> = {
        type: userTypes.SET_LOADER,
        loader: false
      }
      dispatch(loaderEndAction);
    }
  } catch (error) {
    const loaderEndAction: Partial<UserAction> = {
      type: userTypes.SET_LOADER,
      loader: false
    }
    dispatch(loaderEndAction);
    console.error(error)
    throw error
  }
}

// TODO: ADD ORG
export const createOrg: any = (reqBody: unknown) => async () => {
  try {
    const { data, status } = await hwaxios.post(url + '/organization', reqBody)
    return { data, status };
  } catch (error) {
    if (isAxiosError(error)) {
      if (error.response) {
        const data = error.response.data;
        const status = error.response.status;
        return { data, status };
      }
    } else {
      return {
        data: 'Something went wrong', status: 400
      }
    }
  }
}

// TODO: UPDATE ORG
export const updateOrg: any = (reqBody: unknown) => async () => {
  try {
    const { data, status } = await hwaxios.put(url + '/organization', reqBody)
    return { data, status };
  } catch (error) {
    if (isAxiosError(error)) {
      if (error.response) {
        const data = error.response.data;
        const status = error.response.status;
        return { data, status };
      }
    } else {
      return {
        data: 'Something went wrong', status: 400
      }
    }
  }
}

// TODO: DELETE ORG 
export const deleteOrg: any = (reqParams: unknown) => async () => {
  try {
    const { data, status } = await hwaxios.delete(url + '/organization', { params: reqParams })
    return { data, status };
  } catch (error) {
    if (isAxiosError(error)) {
      if (error.response) {
        const data = error.response.data;
        const status = error.response.status;
        return { data, status };
      }
    } else {
      return {
        data: 'Something went wrong', status: 400
      }
    }
  }
}

// TODO: INDIVIDUAL ORG DETAILS API
export const fetchIndividualOrgDetail: any = (reqParams: unknown) => async (dispatch: (arg0: Partial<UserAction>) => any): Promise<any> => {
  try {
    const loaderStartAction: Partial<UserAction> = {
      type: userTypes.SET_LOADER,
      loader: true
    }
    dispatch(loaderStartAction);
    const response = await hwaxios.get(url + '/organization', { params: reqParams })
    const action: Partial<UserAction> = {
      type: userTypes.FETCH_ORG_DETAIL_DATA,
      individualOrgDetail: response?.data?.data,
    }
    dispatch(action)
    if (response?.status === 200) {
      const loaderEndAction: Partial<UserAction> = {
        type: userTypes.SET_LOADER,
        loader: false
      }
      dispatch(loaderEndAction);
    }
    return response;
  } catch (error) {
    const loaderEndAction: Partial<UserAction> = {
      type: userTypes.SET_LOADER,
      loader: false
    }
    dispatch(loaderEndAction);
    console.error(error)
    throw error
  }
}

// TODO: EXPORT ORGS
export const exportToCsvForOrgList: any = (reqParams: unknown) => async () => {
  try {
    const { data, status } = await hwaxios.post(url + '/organization/export', reqParams)
    return { data, status };
  } catch (error) {
    if (isAxiosError(error)) {
      if (error.response) {
        const data = error.response.data;
        const status = error.response.status;
        return { data, status };
      }
    } else {
      return {
        data: 'Something went wrong', status: 400
      }
    }
  }
}

// TODO: Individual Role Details API
export const fetchIndividualRoleDetail: any = (reqParams: unknown) => async (dispatch: (arg0: Partial<UserAction>) => any): Promise<any> => {
  try {
    const loaderStartAction: Partial<UserAction> = {
      type: userTypes.SET_LOADER,
      loader: true
    }
    dispatch(loaderStartAction);

    const response = await hwaxios.get(url + '/role', { params: reqParams })
    const action: Partial<UserAction> = {
      type: userTypes.FETCH_ROLE_DETAIL_DATA,
      individualRoleDetail: response?.data?.data,
    }
    dispatch(action)
    if (response?.status === 200) {
      const loaderEndAction: Partial<UserAction> = {
        type: userTypes.SET_LOADER,
        loader: false
      }
      dispatch(loaderEndAction);
    }
    return response;
  } catch (error) {
    const loaderEndAction: Partial<UserAction> = {
      type: userTypes.SET_LOADER,
      loader: false
    }
    dispatch(loaderEndAction);
    console.error(error)
    throw error
  }
}

export const setRedirectedStateForUserFilters: any = (data, orgId?: string) => async (dispatch) => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    try {
      const action = {
        type: userTypes.SET_USERS_FILTERS_APPLIED_FROM_REDIRECTION,
        userFiltersAppliedFromRedirection: data,
      };
      if (orgId) {
        if (!rmsStore.getState().referenceDataReducer?.orgReferenceData) {
          await dispatch(fetchOrgReferenceData())
        }
        const org = rmsStore.getState().referenceDataReducer?.orgReferenceData?.find(org => org?.id === orgId)
        if (org) {
          await setFilterData(org, 'orgName', 'users', 'SET', false)(dispatch);
        }
      }
      await dispatch(action);
      // Resolve the promise
      resolve('Action dispatched successfully');
    } catch (error) {
      // Reject the promise with the error
      reject(error);
    }
  });
};

