import { type FC } from 'react'
import React  from 'react';

const EditBroadcastMessage: FC = () => {
  return (
    <>
    <div>EditBroadcastMessage</div>
    </>
  )
}

export default EditBroadcastMessage
