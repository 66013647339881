/* eslint-disable @typescript-eslint/no-explicit-any */
import { isAxiosError } from 'axios';
import { alarmTypes, fetchAlarmSeverityReferenceData, fetchAlarmTypeReferenceData, fetchChargerStatusReferenceData, fetchCustomerReferenceData, getFilterSelectedValue, setFilterData } from '../reduxExports';
import hwaxios from '../api-service/HWAxios';
import rmsStore from '../rmsStore';
import { isEqual } from 'lodash';

const url = process.env.REACT_APP_SERVER_BASE_URL;

export const fetchAlarmListData: any =
  (reqParams) =>
    async (dispatch): Promise<void> => {
      try {
        const searchHash = reqParams?.searchTerm
        const filterHash = reqParams?.filters
        const hashAction: Partial<AlarmsAction> = {
          type: alarmTypes.SET_ALARM_LIST_REQUEST_HASH,
          alarmListRequestSearchHash: searchHash,
          alarmListRequestFilterHash: filterHash
        }
        await dispatch(hashAction)
        const response = await hwaxios.post(url + '/alarm/alarm-list', reqParams);
        if (searchHash === rmsStore.getState().alarmsReducer.alarmListRequestSearchHash && isEqual(filterHash, rmsStore.getState().alarmsReducer.alarmListRequestFilterHash)) {
          const action = {
            type: alarmTypes.FETCH_ALARM_DATA,
            alarms: response.data?.data?.alarms,
            alarmsCount: response.data?.data?.alarmsCount,
          };
          dispatch(action);
        }
      } catch (error) {
        console.error(error);
        throw error;
      }
    };

export const fetchAlarmCardCountData: any = (reqParams) => async (dispatch): Promise<void> => {
  try {
    const loaderStartAction: Partial<AlarmsAction> = {
      type: alarmTypes.SET_PULSE_ALARM_LOADER,
      pulseAlarmLoader: true
    }
    dispatch(loaderStartAction);
    const searchHash = reqParams?.searchTerm
    const filterHash = reqParams?.filters
    const hashAction: Partial<AlarmsAction> = {
      type: alarmTypes.SET_ALARM_LIST_CARDS_REQUEST_HASH,
      alarmListCardsRequestSearchHash: searchHash,
      alarmListCardsRequestFilterHash: filterHash
    }
    await dispatch(hashAction)
    const response = await hwaxios.post(url + '/alarm/alarm-list/count', reqParams);
    if (searchHash === rmsStore.getState().alarmsReducer.alarmListCardsRequestSearchHash && isEqual(filterHash, rmsStore.getState().alarmsReducer.alarmListCardsRequestFilterHash)) {
      const action: Partial<AlarmsAction> = {
        type: alarmTypes.FETCH_ALARM_COUNT_DATA,
        alarmDashboardCount: response.data?.data,
      };
      dispatch(action);
    }
    if (response?.status === 200) {
      const loaderEndAction: Partial<AlarmsAction> = {
        type: alarmTypes.SET_PULSE_ALARM_LOADER,
        pulseAlarmLoader: false
      }
      dispatch(loaderEndAction);
    }

  } catch (error) {
    const loaderEndAction: Partial<AlarmsAction> = {
      type: alarmTypes.SET_PULSE_ALARM_LOADER,
      pulseAlarmLoader: false
    }
    dispatch(loaderEndAction);
    console.error(error);
    throw error;
  }
};


export const setAlarmTableProps: any = (data, fetchType) => async (dispatch: (arg0: Partial<AlarmsAction>) => void): Promise<void> => {
  if (fetchType === 'history') {
    // Partial<AlarmsAction>
    const action = {
      type: alarmTypes.SET_ALARM_HISTORY_TABLE_PROPS,
      alarmHistoryTableProps: data,
    };
    dispatch(action);
  } else if (fetchType === 'active') {
    // : Partial<AlarmsAction>
    const action = {
      type: alarmTypes.SET_ACTIVE_ALARM_TABLE_PROPS,
      activeAlarmTableProps: data,
    };
    dispatch(action);
  }

};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const exportAlarmHistoryData: any = (reqParams) => async (): Promise<unknown> => {
  try {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { data, status } = await hwaxios.post(url + '/alarm/export-csv', reqParams);
    return { data, status };
    // const action: Partial<AlarmsAction> = {
    //   type: alarmTypes.EXPORT_ALARM_DATA,
    // };
    // dispatch(action);
  } catch (error) {
    if (isAxiosError(error)) {
      if (error.response) {
        const data = error.response.data;
        const status = error.response.status;
        return { data, status };
      }
    } else {
      return {
        data: 'Something went wrong', status: 400
      }
    }
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const setRedirectedStateForAlarmFilters = (data, severityId?: string | null, alarmType?: string, customerId?: string | null, chargerStatus?: string) => async (dispatch) => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    try {
      const action = {
        type: alarmTypes.SET_FILTERS_APPLIED_FROM_REDIRECTION,
        alarmFiltersAppliedFromRedirection: data,
      };
      const dashboardSelectedChargerType = getFilterSelectedValue('chargerType', 'dashboard', false)
      if (dashboardSelectedChargerType && data) {
        await setFilterData(dashboardSelectedChargerType, 'chargerType', 'alarms', 'SET', false)(dispatch)
      }
      if (customerId) {
        if (!rmsStore.getState().referenceDataReducer?.customerReferenceData) {
          await dispatch(fetchCustomerReferenceData())
        }
        const customer = rmsStore.getState().referenceDataReducer?.customerReferenceData?.find(customer => customer?.id === customerId)
        if (customer) {
          await setFilterData(customer, 'customer', 'alarms', 'SET', true)(dispatch);
        }
      }
      if (alarmType) {
        if (!rmsStore.getState().referenceDataReducer?.alarmTypeReferenceData) {
          await dispatch(fetchAlarmTypeReferenceData())
        }
        const alarmTypeToSelect = rmsStore.getState().referenceDataReducer?.alarmTypeReferenceData?.find(item => item?.label === alarmType)
        if (alarmTypeToSelect) {
          await setFilterData(alarmTypeToSelect, 'alarmType', 'alarms', 'SET', false)(dispatch);
        }
      }
      if (severityId) {
        if (!rmsStore.getState().referenceDataReducer?.alarmSeverityReferenceData) {
          await dispatch(fetchAlarmSeverityReferenceData())
        }
        const severity = rmsStore.getState().referenceDataReducer?.alarmSeverityReferenceData?.find(severity => severity?.id === severityId)
        if (severity) {
          await setFilterData(severity, 'severity', 'alarms', 'SET', false)(dispatch);
        }
      }
      if (chargerStatus) {
        const chargerStatusReferenceDataVal = (rmsStore.getState().referenceDataReducer.chargerStatusReferenceData) ? rmsStore.getState().referenceDataReducer?.chargerStatusReferenceData : JSON.parse(await dispatch(fetchChargerStatusReferenceData()));
        const status = chargerStatusReferenceDataVal?.find(chargerStatusval => chargerStatusval?.id === chargerStatus);
        if (status) {
          await setFilterData(status, 'chargerStatus', 'alarms', 'SET', false)(dispatch);
        }
      }
      await dispatch(action);
      // Resolve the promise
      resolve('Action dispatched successfully');
    } catch (error) {
      // Reject the promise with the error
      reject(error);
    }
  });
};

export const saveNotes: any = (reqParams: unknown) => async () => {
  try {
    const { data, status } = await hwaxios.put(url + '/alarm', reqParams)
    return { data, status };
  } catch (error) {
    if (isAxiosError(error)) {
      if (error.response) {
        const data = error.response.data;
        const status = error.response.status;
        return { data, status };
      }
    } else {
      return {
        data: 'Something went wrong', status: 400
      }
    }
  }
}
