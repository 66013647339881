/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import { Collapse } from '@mui/material'
import { cloneDeep } from 'lodash'
import { type FC, useCallback, useState } from 'react'
import {
  TableLayout,
  useEffectOnce
} from '../globalExports'
import GridExpandableToggle from './GridExpandableToggle'
import './GridLayout.css'
import React from 'react';
import { TableType } from '../TableDashboard/TableDashboard'

const GridLayout: FC<Partial<TableType>> = (props) => {
  const {
    tableId,
    refreshId,
    gridLayoutCallBack,
    gridRows,
    gridLayoutConfiguration,
    mobileViewConfiguration,
    gridColumns
  } = props
  const [expandedIds, setExpandedIds] = useState<string[]>([])

  const handleToggle = useCallback(
    (event, val) => {
      let array = cloneDeep(expandedIds)
      if (array && array?.length > 0 && array?.includes(val)) {
        array = []
      } else {
        array = [val]
      }

      setExpandedIds(array)
    },[expandedIds])

  const fetchGridTableColumns = useCallback(() => {
    const filteredColumns =
      gridColumns &&
      gridColumns?.length > 0 &&
      gridColumns?.filter(
        (column) =>
          !gridLayoutConfiguration?.tableExcludeFields?.includes(column.field)
      )
    return filteredColumns
  }, [gridColumns, gridLayoutConfiguration?.tableExcludeFields])

  const handleSort = useCallback(
    (layoutView, sortOrder, sortField) => {
      gridLayoutCallBack?.(layoutView, sortOrder, sortField, expandedIds)
    },
    [expandedIds, gridLayoutCallBack]
  )

  useEffectOnce(() => {
    gridLayoutCallBack?.()
  })

  return (
    <div className="grid__layout__wrap">
      {gridRows != null && gridLayoutConfiguration &&
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        Object.keys(gridRows).map((userName: any, gridIndex: number) => {
          const userData = gridRows[userName]
          const headerData = userData?.headerData
          const tableData = userData?.tableData
          const groupId = gridLayoutConfiguration?.groupField

          return (
            <div
              key={gridIndex}
              id={tableId ?? '' + gridIndex}
              className="grid__expandable__layout"
            >
              {gridLayoutConfiguration &&
                <GridExpandableToggle
                  gridName={userName}
                  headerData={headerData!}
                  handleToggle={handleToggle}
                  expandedIds={expandedIds}
                  gridLayoutConfiguration={gridLayoutConfiguration ?? {}}
                  groupId={groupId ?? ''}
                />
              }

              <Collapse
                timeout="auto"
                unmountOnExit
                in={
                  !!(
                    expandedIds &&
                    expandedIds.length > 0 &&
                    groupId &&
                    expandedIds.includes(headerData?.[groupId])
                  )
                }
              >
                <TableLayout
                  tableRows={tableData}
                  // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
                  gridColumns={fetchGridTableColumns() || []}
                  refreshId={refreshId}
                  tableId={tableId + 'table'}
                  tableClassName={'grid__layout__table'}
                  layoutView={'grid'}
                  totalCount={100}
                  mobileViewConfiguration={mobileViewConfiguration}
                  listLayoutCallBack={handleSort}
                />
              </Collapse>
            </div>
          )
        })}
    </div>
  )
}

export default GridLayout
