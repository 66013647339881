import React, { FC } from 'react';
import './Loader.css';

const MapLoader: FC = () => {
  return (
    <div className='map__loader__container'>
      <div className="pin"></div>
      <div className="pulse"></div>
    </div>
  )
}

export default MapLoader