export const FETCH_USERS_DATA = 'FETCH_USERS_DATA'
export const SET_LOADER = 'SET_LOADER'
export const FETCH_ROLES_DATA = 'FETCH_ROLES_DATA'
export const SET_USER_LIST_TABLE_PROPS = 'SET_USER_LIST_TABLE_PROPS'
export const SET_ROLE_LIST_TABLE_PROPS = 'SET_ROLE_LIST_TABLE_PROPS'
export const CLEAR_ROLE_LIST_DATA = 'CLEAR_ROLE_LIST_DATA'
export const FETCH_ROLE_DETAIL_DATA = 'FETCH_ROLE_DETAIL_DATA'
export const CLEAR_ROLE_DETAIL_DATA = 'CLEAR_ROLE_DETAIL_DATA'
export const CLEAR_USER_LIST_DATA = 'CLEAR_USER_LIST_DATA'
export const FETCH_USER_DETAIL_DATA = 'FETCH_USER_DETAIL_DATA'
export const FETCH_CHARGERS_BY_CUSTOMER = 'FETCH_CHARGERS_BY_CUSTOMER'
export const CLEAR_USER_DETAIL_DATA = 'CLEAR_USER_DETAIL_DATA'
export const FETCH_ORGS_DATA = 'FETCH_ORGS_DATA'
export const CLEAR_ORG_LIST_DATA = 'CLEAR_ORG_LIST_DATA'
export const SET_ORGS_LIST_TABLE_PROPS = 'SET_ORGS_LIST_TABLE_PROPS'
export const FETCH_CHARGERS_BY_CUSTOMER_BY_ORG = 'FETCH_CHARGERS_BY_CUSTOMER_BY_ORG'
export const FETCH_ORG_DETAIL_DATA='FETCH_ORG_DETAIL_DATA'
export const CLEAR_ORG_DETAIL_DATA = 'CLEAR_ORG_DETAIL_DATA'
export const SET_USERS_FILTERS_APPLIED_FROM_REDIRECTION = 'SET_USERS_FILTERS_APPLIED_FROM_REDIRECTION'
