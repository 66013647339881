/* eslint-disable @typescript-eslint/explicit-function-return-type */
import HWLocalStorage from '../../rmsReduxStore/api-service/HWLocalStorage';
import axios, {isAxiosError} from 'axios';

class AuthService {
  url = process.env.REACT_APP_SERVER_BASE_URL;
  accessTokenKey   = 'accessToken';
  refreshTokensKey = 'refreshToken';

  hasTokens = ():boolean => { // See if tokens are available.
    return (HWLocalStorage.load(this.accessTokenKey) != null) && (HWLocalStorage.load(this.refreshTokensKey) != null);
  }

  generateOtp = async (formData: { mobileNumber: number,countryCode:string }) => {
    try {
      const { data, status } = await axios.post(this.url + '/auth/send-otp', formData,
        { headers: { 'Content-Type': 'application/json', Accept: 'application/json', }, });
      return { data, status };
    } catch (error) {
      if (isAxiosError(error)) {
          if (error.response) {
            const data = error.response.data;
            const status = error.response.status;
            return { data, status };
          }
      }
    }
  }

  handleSignIn = async (formData) => {
    try {
      const { data, status } = await axios.post(this.url + '/auth/sign-in', formData,
        { headers: { 'Content-Type': 'application/json', }, }
      );
      return { data, status };
    } catch (error) {
      if (isAxiosError(error)) {
        if (error.response) {
          const data = error.response.data;
          const status = error.response.status;
          return { data, status };
        }
      }
      return { error: 'An unexpected error occurred during sign-in' };
    }
  }

  handleLogout = async (formData) => {
    try {
      const { data, status, } = await axios.post(this.url + '/auth/sign-out', formData,
        { headers: { 'Content-Type': 'application/json', }, }
      );
      return { data, status };
    } catch (error) {
      if (isAxiosError(error)) {
        if (error.response) {
          const data = error.response.data;
          const status = error.response.status;
          return { data, status };
        }
      }
      return { error: 'An unexpected error occurred during sign-in' };
    }
  }

  fetchAccessTokenAndRefresh = async (req) => {
    try {
      const response = await axios.post(this.url + '/auth/token/refresh', req);
      return response;
    } catch (error) { console.log('error'); }
  }

}

const authService = new AuthService();
export default authService;
