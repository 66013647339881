import { type GridRenderCellParams, GridValidRowModel } from '@mui/x-data-grid-pro';
import { cloneDeep } from 'lodash';
import React, { ReactNode, memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  CustomDialog,
  // CustomTooltipWithLabel,
  downloadFile,
  formatRelativeTimeForTable,
  renderHeaderActionButtons,
  TableDashboard,
  useAppDispatch,
  useAppSelector,
} from '../../globalUtils/globalExports';
import {
  getUserReducer,
  fetchRoles,
  setTableProps,
  exportToCsvForRoleList,
  deleteRole,
  setTableColumnPrefrences,
  getGlobalReducer,
  userTypes
} from '../../rmsReduxStore/reduxExports';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import './users.css';
import { Id, toast } from 'react-toastify';
import { NoDataComponentRoles } from '../../globalUtils/TableDashboard/TableComponents';
import { useNavigate } from 'react-router-dom';
import access from '../../auth/service/AccessControl';

const RoleManagementDashboard = memo(function RoleManagementDashboard() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { roles, rolesCount, roleListTableProps, loader } = useAppSelector(getUserReducer);
  const {screenTableColumnPrefrences} = useAppSelector(getGlobalReducer)
  const [exportAnchorEl, setExportAnchorEl] = useState(null);
  const [exportingFile, setExportingFile] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [selectedRoleId, setSelectedRoleId] = useState<string | null>(null);
  const roleListTablePropsRef = useRef(roleListTableProps);
  const [deleteLoader,setDeleteLoader] = useState<boolean>(false);

  useEffect(() => {
    roleListTablePropsRef.current = roleListTableProps;
  }, [roleListTableProps]);


  // TODO: Search Box Configuartions
  const onChangeOfSearchText = useCallback(async (searchTerm) => {
    if (roleListTablePropsRef?.current) {
      await layoutCallback(1, roleListTablePropsRef?.current?.pageSize, roleListTablePropsRef?.current?.view, roleListTablePropsRef?.current?.sortBy, roleListTablePropsRef?.current?.order, searchTerm)
    }
  }, [roleListTablePropsRef?.current]);

  const searchBoxConfiguration = useMemo(() => {
    return {
      searchFieldId: 'manage-roles-search-box',
      searchFieldName: 'manage-roles-search-box',
      handleSearch: onChangeOfSearchText,
    };
  }, [onChangeOfSearchText, roleListTablePropsRef?.current]);

  // TODO: Delete Handlers
  const roleDeletionSuccessToast = useCallback((): Id => {
    return toast.success('Role deleted.');
  }, []);

  const roleDeletionFailureToast = useCallback((): Id => {
    return toast.warn('Something went wrong. Please try again.');
  }, []);

  const onDeleteIconClick = useCallback(async (id: string): Promise<void> => {
    setSelectedRoleId(id);
    setIsDeleteDialogOpen(true);
  }, []);

  const handleDeleteDialogClose = useCallback(() => {
    setIsDeleteDialogOpen(false);
    setSelectedRoleId(null);
  }, []);

  const handleDeleteRole = useCallback(async () => {
    setDeleteLoader(true);
    if (selectedRoleId) {
      const { data, status } = await dispatch(deleteRole({
        roleId: selectedRoleId
      }));
      if (status === 200 && data.message === 'Success') {
        roleDeletionSuccessToast()
      }
      setDeleteLoader(false);
      handleDeleteDialogClose();
    } else {
      setDeleteLoader(false);
      roleDeletionFailureToast()
    }
  }, [dispatch, selectedRoleId, handleDeleteDialogClose]);


  // TODO: Adds action btns in header and their callbacks
  const headerActionBtns = renderHeaderActionButtons({
    actionBtns:
      [
        // {
        //   id: 'add_new_btn',
        //   isAccessible: access?.roles?.add(),
        //   headerActionBtnClick: (): void => {
        //     navigate('/role', { state: { mode: 'Add' }});
        //   }
        // },
        {

          id: 'export_csv_btn',
          isAccessible: access?.roles?.export(),
          headerActionBtnClick: (event): void => {
            setExportAnchorEl(event.currentTarget);

          }
        }
      ]
  })


  const onEditIconClick = useCallback(async () => {

  }, []);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const actionIconsList = useMemo(() => {
    return [{ icon: 'pencilIcon', action: onEditIconClick }, { icon: 'deleteIcon', action: (_, rowData) => onDeleteIconClick(rowData?.id) }];
  }, [onDeleteIconClick]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleSelectedRole = useCallback((event, data) => {
    alert(data?.name)
  }, []);

  const handleNameClick = async (event, rowData) => {    
    navigate('/role', { state: { roleId: rowData.id, mode: 'View', rowData: rowData } });
  };


  // TODO: Table Layout Callbacks
  const mobileViewConfiguration = useMemo(() => {
    return {
      headerDataConfig: {
        headerLeftDataConfig: ['name', 'description'],
        headerRightDataConfig: {
          actionIconsComponent: false,
          statusIconComponent: true,
        },
      },
      statusIconKey: 'status'
    };
  }, []);

  const getTableRows = useCallback((): IRole[] => {
    return roles !== null && roles?.length > 0 ? roles : [];
  }, [roles]);

  const roleColumns = useMemo(() => {
    return [
      {
        field: 'orgName',
        headerName: 'Organisation',
        flex: 1,
        sortable: true,
        minWidth: 100,
      },
      {
        field: 'orgType',
        headerName: 'Organisation Type',
        flex: 1,
        sortable: true,
        minWidth: 100,
      },
      {
        field: 'name',
        // flex: 1,
        maxWidth: 500,
        headerName: 'Name',
        minWidth: 100,
        hideable:false,
        renderCell: (params: GridRenderCellParams<GridValidRowModel>): JSX.Element => {
          const rowData = params?.row;
          return (
            <div
              className="individual__role__text"
              onClick={(e) => {
                e?.stopPropagation()
                handleNameClick(e, params.row);
              }
              }
            >
              {rowData?.name}
            </div>
          );
        },
      },
      {
        field: 'description',
        headerName: 'Description',
        minWidth: 250,
        editable: false,
        flex: 3,
        // renderCell: (
        //   params: GridRenderCellParams<GridValidRowModel>): JSX.Element => {
        //   const rowData = params?.row;
        //   return <CustomTooltipWithLabel labelId='role__dashboard-description' label={rowData?.description} /> 
        // },
      },
      {
        field: 'createdAt',
        headerName: 'Created On',
        flex: 1,
        sortable: true,
        minWidth: 100,
        renderCell: (
          params: GridRenderCellParams<GridValidRowModel>
        ): ReactNode => {
          const rowData = params?.row;
          const date = new Date(rowData?.createdAt);
          return formatRelativeTimeForTable(date);
        },
      },
      // {
      //   field: 'Action',
      //   headerName: 'Actions',
      //   flex: 1,
      //   sortable: false,
      //   minWidth: 50,
      //   maxWidth: 100,
      //   headerAlign: 'center',
      //   renderCell: (params: GridRenderCellParams<GridValidRowModel, Date>): JSX.Element => {
      //     const rowData = params?.row;
      //     return (
      //       <div className="role__action__icons__wrap">
      //         <ActionIcons actionIconsList={actionIconsList} data={rowData} />
      //       </div>
      //     );
      //   },
      // },
    ];
  }, []);


  const layoutCallback = useCallback(async (pageNumber: number, pageSize: number, view: string, sortField: string, sortOrder: string, searchTerm) => {
    const tableProps: IListTableProps = cloneDeep(roleListTablePropsRef.current)
    await dispatch(fetchRoles({
      sortBy: sortField || roleListTablePropsRef?.current?.sortBy,
      order: sortOrder || roleListTablePropsRef?.current?.order,
      pageSize,
      pageNumber,
      searchTerm: searchTerm != null && searchTerm != undefined ? searchTerm : roleListTablePropsRef?.current?.searchTerm
    }));
    if (tableProps) {
      tableProps.view = view || 'list'
      tableProps.sortBy = sortField ||roleListTablePropsRef?.current?.sortBy;
      tableProps.order = sortOrder || roleListTablePropsRef?.current?.order;
      tableProps.pageNumber = pageNumber;
      tableProps.pageSize = pageSize;
      if (searchTerm != null && searchTerm != undefined) {
        tableProps.searchTerm = searchTerm;
      }
    }
    await dispatch(setTableProps(tableProps, 'rolesList'))
  }, [roleListTablePropsRef?.current, dispatch]);

  // Handler For Column Prefrence Chnages
  const handleColumnPrefrenceChange = useCallback(async (operation,data) => {
    await dispatch(setTableColumnPrefrences('roles',operation,data))
  },[])

  // TODO: Redux state Clean up
  const roleCleanUpStates = useCallback(() => {
    // : Partial<UserAction>
    const action = {
      type: userTypes.CLEAR_ROLE_LIST_DATA,
    }
    dispatch(action);
  }, [])

  useEffect(() => {
    window.addEventListener('beforeunload', roleCleanUpStates);
    return (): void => {
      roleCleanUpStates()
      window.removeEventListener('beforeunload', roleCleanUpStates);
    }
  }, [])

  // ***********************************************************
  // TODO: Export Handlers
  const handleClose = (): void => {
    setExportAnchorEl(null);
  };

  const fileDownloadSuccessToast = useCallback((): Id => {
    setExportingFile(false);
    return toast.success('File downloaded.');
  }, []);

  const fileDownloadFailureToast = useCallback((): Id => {
    setExportingFile(false);
    return toast.warn('Something went wrong. Please export again.');
  }, []);


  const onExportBtnClick = useCallback(async (fileType: 'csv' | 'xlsx'): Promise<void> => {
    if (exportingFile) {
      // If already exporting, prevent another export
      return;
    }
    try {
      const response = await dispatch(exportToCsvForRoleList({
        fileType: fileType,
      }));
      if (response?.status === 202 || response?.status === 200) {
        downloadFile({ url: response?.data?.url });
        fileDownloadSuccessToast()
      } else {
        fileDownloadFailureToast();
        setExportAnchorEl(null);
      }
    } finally {
      // Ensure that exporting status is updated even if there's an error
      setExportingFile(false);
      setExportAnchorEl(null);

    }
  }, [exportingFile, fileDownloadSuccessToast, fileDownloadFailureToast])

  return (
    <>
      <TableDashboard
        tableId="manage-roles-table"
        tableClassName="manage__roles__table"
        header={'Roles'}
        searchBoxIncluded={true}
        searchBoxConfiguration={searchBoxConfiguration}
        gridColumns={roleColumns}
        tableRows={getTableRows()}
        mobileViewConfiguration={mobileViewConfiguration}
        layoutView={'list'}
        headerActionBtns={headerActionBtns}
        listLayoutCallBack={layoutCallback}
        totalCount={rolesCount}
        showSkeleton={!roles}
        totalCountText={'roles'}
        showLoader={loader}
        customNoDataComponent={NoDataComponentRoles}
        pageNumber={roleListTableProps?.pageNumber}
        tablePageSize={roleListTableProps?.pageSize}
        sortField={roleListTableProps?.sortBy}
        sortOrder={roleListTableProps?.order}
        columnPrefrences={screenTableColumnPrefrences?.find((item) => item.screen === 'roles')}
        handleColumnPrefrenceChange={handleColumnPrefrenceChange}
      />
      <Menu
        id="export-option-dropdown"
        anchorEl={exportAnchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        className='export__option__dropdown'
        sx={{ top: '30px' }}
        open={Boolean(exportAnchorEl)}
        onClose={handleClose}

      >
        <MenuItem>
          <div className='export__btn-option' onClick={(e) => {
            e?.stopPropagation();
            onExportBtnClick('csv')
          }}>
            To CSV
          </div>
        </MenuItem>
        <MenuItem>

          <div className='export__btn-option' onClick={(e) => {
            e?.stopPropagation();
            onExportBtnClick('xlsx')
          }}>
            To Excel
          </div>
        </MenuItem>
      </Menu>

      <CustomDialog
        dialogConfig={{
          dialogDescription: 'Are you sure you want to delete this role?',
          dialogCancelTitle: 'Cancel',
          dialogOkTitle: 'Delete',
        }}
        show={isDeleteDialogOpen}
        handleClose={handleDeleteDialogClose}
        handleSubmit={handleDeleteRole}
        deleteLoader={deleteLoader}
      />
    </>
  );
})

export default RoleManagementDashboard;
