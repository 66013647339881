/* eslint-disable @typescript-eslint/prefer-optional-chain */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import type React from 'react'
import { BubbledText,  checkForScreenResolution } from '../globalExports'
import PropTypes from 'prop-types'
import { Icon } from '../globalIcons'

export const GridExpandableToggleProp = {

  gridLayoutConfiguration: PropTypes.shape({
    headerFields: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        icon: PropTypes.string.isRequired
      }).isRequired
    ).isRequired,
    tableExcludeFields: PropTypes.array.isRequired,
    colors: PropTypes.array.isRequired,
    groupField: PropTypes.string.isRequired,
    sortFields: PropTypes.array.isRequired
  }).isRequired,
  headerData: PropTypes.object.isRequired,
  handleToggle: PropTypes.func,
  groupId: PropTypes.any,
  gridName: PropTypes.string,
  expandedIds: PropTypes.array.isRequired
}
export type GridExpandableTogglePropTypes = PropTypes.InferProps<typeof GridExpandableToggleProp>

const GridExpandableToggle: React.FC<GridExpandableTogglePropTypes> = ({
  expandedIds,
  gridName,
  headerData,
  gridLayoutConfiguration,
  handleToggle,
  groupId
}) => {

  const smallScreenResolution = checkForScreenResolution?.('sm') 
  return (
    <div
      className={
        expandedIds?.length > 0 && expandedIds?.includes(headerData[groupId])
          ? 'grid__layout__expandable__container is__expanded '
          : 'grid__layout__expandable__container'
      }
    >
      <div className="grid__layout__expandable__container__non__toggle__wrap">
        <div className="grid__layout__expandable__container-wrap">
          <div
            className="grid__layout__expandable__container-header-wrap"
            style={{ width: '21%' }}
          >
            <div className="grid__layout__expandable__container-header-text bold">
              {gridName}
            </div>
          </div>

          {!smallScreenResolution?.result &&
            headerData &&
            // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
            gridLayoutConfiguration?.colors &&
            Object.keys(headerData).map((header, index) => {
              const config = gridLayoutConfiguration.headerFields.find(
                (field) => field.title === header
              )

              return (
                config && (
                  <div
                    className="grid__layout__expandable__container-header-wrap"
                    style={{ width: '38%' }}
                  >
                    <div className="grid__layout__expandable__container-header-text">
                      {config?.title}
                    </div>

                    <BubbledText
                      bubbleContainerClassName={
                        'grid__layout__expandable__container-header-number'
                      }
                      bubbleId={index + headerData[header]}
                      bubbleColor={
                        // eslint-disable-next-line no-unsafe-optional-chaining
                        ((gridLayoutConfiguration?.colors?.[index]).length > 0)
                          ? gridLayoutConfiguration?.colors?.[index]
                          : '#ffffff'
                      }
                      bubbleText={headerData[header]}
                    />
                  </div>
                )
              )
            })}
        </div>
        {smallScreenResolution?.result && (
          <div className="grid__layout__expandable__mobile__container-wrap">
            {headerData &&
              Object.keys(headerData).map((header) => {
                const config = gridLayoutConfiguration?.headerFields?.find(
                  (item) => item?.title === header
                )

                return (
                  config && (
                    <div className="grid__layout__mobile__expandable__container__header__wrap">
                      <div className="grid__layout__mobile__expandable__container__header-icon">
                        <Icon iconClassName={`${config?.icon}`} />
                      </div>
                      <div className="grid__layout__mobile__expandable__container__header-count">
                        {headerData[header]}
                      </div>
                    </div>
                  )
                )
              })}
          </div>
        )}
      </div>
      <div className="grid__layout__expandable__container__toggle__wrap">
        <div
          className="grid__layout__expandable__container-header-wrap"
          style={{ width: '3%', justifyContent: 'center' }}
          onClick={(e) => { handleToggle?.(e, headerData?.[groupId]) }}
        >
          <Icon
            iconClassName={
              expandedIds?.length > 0 && expandedIds?.includes(headerData[groupId])
                ? 'grid__layout__expandable__up-icon'
                : 'grid__layout__expandable__down-icon'
            }
          />
        </div>
      </div>
    </div>
  )
}

export default GridExpandableToggle
