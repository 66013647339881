import { Link } from 'react-router-dom'
import type React from 'react'
import PropTypes from 'prop-types'
import './LinkButton.css'

export const LinkButtonType = {
  btnText: PropTypes.string,
  link: PropTypes.string,
  btnClassName: PropTypes.string,
  linkBtnId: PropTypes.string.isRequired,
  linkBtnDirection: PropTypes.string,
  showArrow: PropTypes.bool ?? true,
  linkBtnState: PropTypes.object,
  handleOnClick: PropTypes.func

}
export type LinkButtonPropTypes = PropTypes.InferProps<typeof LinkButtonType>

const LinkButton: React.FC<LinkButtonPropTypes> = (props) => {
  return (
    <Link
      to={props?.link ?? ''}
      key={props?.linkBtnId}
      className={`rms-back-btn ${props?.btnClassName}`}
      state={props?.linkBtnState}
      onClick={(e) => { e?.stopPropagation(); props?.handleOnClick?.()}}
    >
    <div className={props?.linkBtnDirection === 'right' ? 'add__station__back-btn_wrap reverse' : 'add__station__back-btn_wrap '} >
      {((props?.showArrow) ?? false) &&
        <div
          id={props?.linkBtnId}
          className={props?.linkBtnDirection === 'right' ? 'link__btn-icon right' : 'link__btn-icon'}
        ></div>
      }
      <div className="link__btn-text">{props?.btnText}</div>
    </div>
    </Link>
  )
}

export default LinkButton
