import { IUser } from '../../globalUtils/globalInterface';
import HWLocalStorage from './HWLocalStorage';

class LocalStorageService {
   getRefreshToken = (): string | null => {
		const refreshToken = HWLocalStorage.load('refreshToken');
		if (!refreshToken) { return null; }
		return refreshToken;
  }
  
   getAccessToken = (): string | null => {
		const accessToken = HWLocalStorage.load('accessToken');
		if (!accessToken) { return null; }
		return accessToken;
  }

   setAccessToken = (data): void => {
		HWLocalStorage.save('accessToken', data);
	}

	fetchUser = (): IUser | null => {
		const user = HWLocalStorage.load('rms-user');
		if (!user) { return null; }
		return user as unknown as IUser;
	};
	
}

const localStorageInstance = new LocalStorageService();
export default localStorageInstance;