import React, { forwardRef, useEffect, useRef, useState } from 'react';
import { Fragment } from 'react';
import { ThemeProvider } from '@emotion/react';
import { Tooltip } from '@mui/material';
import './CustomTooltipWithLabel.css';
import { TooltipTheme } from '../globalExports';


interface CustomTooltipWithLabelProps {
  label: string;
  containerClassName?: string;
  labelId: string;
}

const CustomTooltipWithLabel = forwardRef<HTMLDivElement, CustomTooltipWithLabelProps>(
  ({ label, containerClassName, labelId }, ref) => {
    const [isTooltipVisible, setTooltipVisible] = useState(false);
    const textRef = useRef<HTMLParagraphElement>(null);

    useEffect(() => {
      const textElement = textRef.current;

      if (textElement) {
        setTooltipVisible(textElement.scrollWidth > textElement.clientWidth);
      }
    }, [label]);

    return (
      <ThemeProvider theme={TooltipTheme}>
        {label && label.trim().length > 0 && isTooltipVisible? (
          <Tooltip
            componentsProps={{
              tooltip: {
                sx: {
                  width: '200px',
                },
              },
              arrow: {
                sx: {
                  bgcolor:'var(--primary-white-color)'
                }
              }
            }}
            className='text__label__tooltip'
            arrow
            title={
              <Fragment>
                <div className={'custom__tooltip__label__inside__wrap'}>
                  <p
                    ref={textRef}
                    id={'label' + labelId}
                    className={`text__label ${isTooltipVisible ? 'show-tooltip' : ''}`}
                  >
                    {label}
                  </p>
                </div>
              </Fragment>
            }
            sx={{ cursor: 'pointer' }}
          >
            <div
              ref={ref}
              id={'label-div' + labelId}
              className={`custom__tooltip__label__wrap ${containerClassName}`}
            >
              <p
                ref={textRef}
                id={'label' + labelId}
                className={`text__label ${isTooltipVisible ? 'show-tooltip' : ''}`}
              >
                {label}
              </p>
            </div>
          </Tooltip>
        ) : (
            !isTooltipVisible && label && label.trim().length > 0 ?
              (   <div
                ref={ref}
                id={'label-div' + labelId}
                className={`custom__tooltip__label__wrap ${containerClassName}`}
              >
                <p
                  ref={textRef}
                  id={'label' + labelId}
                  className={`text__label ${isTooltipVisible ? 'show-tooltip' : ''}`}
                >
                  {label}
                </p>
              </div>)
              :
          <div className='label__no__text'>-</div>
        )}
      </ThemeProvider>
    );
  }
);

CustomTooltipWithLabel.displayName = 'CustomTooltipWithLabel';
export default CustomTooltipWithLabel;
