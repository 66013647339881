/* eslint-disable @typescript-eslint/no-unused-vars */
import { GridColDef } from '@mui/x-data-grid-pro';
import React, { FC } from 'react';
import { Arrow, Icon } from '../../globalUtils/globalIcons';
import {
  alarmCardMapper,
  calculateAging,
  formatRelativeTimeForTable,
} from '../../globalUtils/globalExports';
import { useNavigate } from 'react-router-dom';

export interface IAlarmRowCard {
  row: IAlarm;
  renderedColumns: Array<GridColDef>;
}

const AlarmRowCard: FC<IAlarmRowCard> = (props) => {
  const navigate = useNavigate()


  const rowData = props?.row;
  const columnData = props?.renderedColumns;
  const columns = (columnData as unknown as GridColDef[]).map(col => ({ field: col.field, headerName: col.headerName }));


  const topCard = ['alarmVisibleName', 'alarmSetTime', 'alarm_clear_time'];

  const bottomCard = [
    'chargerVisibleId',
    'connectorType',
    'connectorNumber',
    'customerName',
    'location',
  ];

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const arrowClickHandler = (row: IAlarm) =>{


    navigate('/alarm?alarmId=' + row?.alarmId, {
      state: {
        row: row
      }
    })
  }

  return (
    <div className="alarm__row__card__container">
      <div className="alarm__row__card__container-top">
        <div
          className="alarm__row__card__container-top-border"
          style={{ backgroundColor: alarmCardMapper[rowData?.severity]?.color }}
        ></div>

        {topCard?.map((columnKey, index) => {
          const headerName =
          (columnData as unknown as GridColDef[])?.find(
              (column) => column?.field === columnKey
            )?.headerName || '';
          const value = rowData[columnKey];
          return (
            <div key={index} className="alarm__row__card__container-top-card">
              <div className="alarm__row__card__container-top-card-header">
                <span>{headerName}</span>

                {headerName === 'Alarm' && (
                  <span
                    className={'alarm__row__card__container-top-card-severity-status-text ' }
                    style={{
                      backgroundColor:
                        alarmCardMapper[rowData?.severity]?.color,
                    }}
                  >
                    {rowData?.severity}
                  </span>
                )}
              </div>

              <div className="alarm__row__card__container-top-card-content">
              {headerName === 'Alarm' && (
                  <Icon iconClassName={alarmCardMapper[rowData?.severity]?.alarmSummaryIcon} />
              )}
              {headerName === 'Time (Set at)' && <div className={'text ' + headerName} >{formatRelativeTimeForTable(value)}</div>}
              {headerName !== 'Time (Set at)' && <div className={'text ' + headerName} >{value}</div>}
              
            
              </div>
            </div>
          );
        })}

        <div className="alarm__row__card__container-top-card">
          <div className="alarm__row__card__container-top-card-header">
            <span>Aging</span>
          </div>
          <div className="alarm__row__card__container-top-card-content">
            {calculateAging(rowData?.alarmAge)}
          </div>
        </div>

        <div className="alarm__row__card__container-top-card arrow-wrap" onClick= {()=>arrowClickHandler(rowData)}>
          <Arrow direction="right" color="black" />
        </div>
      </div>
      <div className="alarm__row__card__container-bottom">
      {bottomCard?.map((columnKey, index) => {
        const headerName = columnKey === 'connectorNumber' ? 'Connector No' :
          columnKey === 'connectorType' ? 'Connector Type' :
          columnKey === 'location' ?'Location' :
            (columnData as unknown as GridColDef[])?.find(
              (column) => column?.field === columnKey
            )?.headerName || '';
          const value = rowData[columnKey];          
          return (
            <div key={index} className="alarm__row__card__container-bottom-card">
              <div className="alarm__row__card__container-bottom-card-header">
               {headerName}

              </div>

              <div className="alarm__row__card__container-bottom-card-content">
                {value}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default AlarmRowCard;
