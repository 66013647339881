import { LinearProgress } from '@mui/material'
import { Box } from '@mui/system'
import React, { FC } from 'react'

const LoginLoader: FC = () => {
  return (
    <Box>
      <LinearProgress className='login__linear__loader' />
    </Box>
  )
}

export default LoginLoader