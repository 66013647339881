/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { Dispatch, FC, SetStateAction } from 'react'
import PulseLoader from 'react-spinners/PulseLoader';

interface PulseLoaderProps {
  showLoader: boolean
  handleClose?: Dispatch<SetStateAction<boolean>>
  loaderText?: string
}

const AlarmPulseLoader: FC<PulseLoaderProps> = (props) => {

  const { showLoader } = props;

  return (
    <PulseLoader
      margin={1} // gap between circles 
      speedMultiplier={1}
      size={12}
      color={'var(--secondary-blue-color)'}
      loading={showLoader}
    />
  )
}

export default AlarmPulseLoader