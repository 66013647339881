import { useState, type FC, useMemo, useCallback, useEffect } from 'react'
import {
  useAppDispatch, CustomButtonGroup, CustomBreadCrumbs, DashboardLoader, useAppSelector, TableSubHeader
} from '../../globalUtils/globalExports'
import DashboardHeader from '../../globalUtils/DashboardHeader/DashboardHeader'
import { chargingStationTypes, createChargingStation, getChargingStationReducer, getReferenceDataReducer } from '../../rmsReduxStore/reduxExports'
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom'
import ImageDialog from '../../globalUtils/ImageDialog/ImageDialog'
import CustomForm, { ICustomFormProps, IFormContentProp } from '../../globalUtils/CustomForm/CustomForm'
import { checkCharactersPaste, checkPINCodePaste, createBreadCrumbProps, keyPressValidationForCharacters, keyPressValidationForPINCode, removeAllFromReferenceData, renderHeaderActionButtons, useEffectOnce } from '../../globalUtils/globalHooks'
import axios, { AxiosResponse } from 'axios'
import { Id, toast } from 'react-toastify'
import { cloneDeep, isEqual } from 'lodash'
import { fetchPreSignedURLForUpload, setRedirectedStateForChargerFilters } from '../../rmsReduxStore/chargersRedux/chargerCreators'
import { fetchIndividualStationDetail, updateChargingStation } from '../../rmsReduxStore/chargingStationRedux/chargingStationCreators'
import access from '../../auth/service/AccessControl'
import { Icon, IconWithTooltip } from '../../globalUtils/globalIcons'
import './chargingStation.css'

//import { fetchPreSignedURLForUpload } from '../../rmsReduxStore/chargersRedux/chargerCreators'

interface ChargingStationFormData {
  customer: IGeneralReferenceData | null;
  stationName: string | null;
  stationImage: string | null;
  description: string | '';
  addressLine1: string | '';
  addressLine2: string | '';
  state: string | '';
  country: Record<string, unknown>;
  pinCode: string | '';
  latitude: string | null;
  longitude: string | null;
  locationType: Record<string, unknown>;
  city: string | '';
  customerOptions: unknown[];
  locationTypeOptions: unknown[];
  imageUrl: string | null;
  fileName: string | null;
  notes: string | '';
}
interface IPreSignedUrlInfo {
  blobName: string;
  message: string;
  url: string;
}

const ChargingStationForm: FC = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate();
  const location = useLocation();
  const [showDialog, setShowDialog] = useState(false);
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const { singleStationDetail } = useAppSelector(getChargingStationReducer);
  const [preSignedUrl, setPreSignedUrl] = useState<IPreSignedUrlInfo | null>(null);
  const [showLoader, setShowLoader] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [mode, setMode] = useState(location?.state?.mode ?? 'View')
  const { customerReferenceData } = useAppSelector(getReferenceDataReducer)
  const somethingWentWrongToast = (): Id => toast.warn('Something went wrong.');
  const StationAddedToast = (): Id => toast.success('Charging station added.');
  const StationEditedToast = (): Id => toast.success('Charging station updated.');
  const initialFormData: ChargingStationFormData = {
    customer: location?.state?.name ? { id: location?.state?.id, name: location?.state?.name, label: location?.state?.name, value: location?.state?.name } : null,
    stationName: null,
    stationImage: null,
    description: '',
    addressLine1: '',
    addressLine2: '',
    state: '',
    country: {},
    pinCode: '',
    latitude: null,
    longitude: null,
    locationType: {},
    city: '',
    customerOptions: [],
    locationTypeOptions: [],
    imageUrl: null,
    fileName: null,
    notes: ''
  }
  const [stationFormData, setStationFormData] = useState(initialFormData);
  const [initialStationFormData, setInitialStationFormData] = useState(initialFormData)


  useEffectOnce(() => {
    if (location?.state?.rowData?.id )
      dispatch(fetchIndividualStationDetail({ stationId: location?.state?.rowData?.id }));
  });

  useEffect(() => {
    if (mode != 'Add') {
      loadInitialData()
    }
  }, [singleStationDetail])

  
  useEffect(() => {
    return (): void => {
      const action = {
        type: chargingStationTypes.CLEAR_UPDATED_STATION_DETAIL_DATA,
      }
      dispatch(action);
    }
  }, [])

  const loadInitialData = () => {
    if (singleStationDetail) {
      const val = singleStationDetail;
      const userDetail = {
        customer: { id: val?.customerId, name: val?.customerName, label: val?.customerName, value: val?.customerId },
        stationName: val?.chargingStationName,
        stationImage: val?.userFileName,
        addressLine1: val?.addressLine1|| '',
        addressLine2: val?.addressLine2 || '',
        description: val?.notes || '',
        state: val?.state || '',
        country: val?.country,
        pinCode: val?.pinCode || '',
        latitude: val?.lat?.toString(),
        longitude: val?.lon?.toString(),
        locationType: {},
        city: val?.city || '',
        customerOptions: [],
        locationTypeOptions: [],
        imageUrl: val?.imageUrl,
        fileName: val?.fileName,
        notes: val?.notes || '',
      }

      setStationFormData({
        ...stationFormData,
        ...userDetail
      });
      setInitialStationFormData({
        ...stationFormData,
        ...userDetail
      })
    }
  }

  const disableAddButton = useCallback(() => {
    if (mode === 'Edit' && isEqual(stationFormData, initialStationFormData)) {
      return true
    }
    return (!(
      (stationFormData?.customer?.id != null && stationFormData?.customer?.id?.length > 0) &&
      (stationFormData?.stationName != null && stationFormData?.stationName?.length > 0) &&
      (stationFormData?.latitude != null && stationFormData?.latitude != undefined && stationFormData?.latitude.length > 0) &&
      (stationFormData?.longitude != null && stationFormData?.longitude != undefined && stationFormData?.longitude.length > 0)
    ))
  }, [stationFormData, mode])

  const btnList = useMemo(() => {
    return [
      {
        buttonText: mode === 'Add' ? 'Add' : 'Update',
        buttonId: 'add',
        btnClassName: disableAddButton() ? 'primary__btn disabled' : 'primary__btn',
        buttonVariant: 'filled',
        handleClick: async (): Promise<void> => {
          // Validate latitude here
          const latitudeValue = parseFloat(stationFormData?.latitude ?? '');
          if (latitudeValue && (isNaN(latitudeValue) || latitudeValue < -90 || latitudeValue > 90)) {
            toast.error('Latitude should be in range of [-90,90]');
            return;
          }
          // Validate longitude here
          const longitudeValue = parseFloat(stationFormData?.longitude ?? '');
          if (longitudeValue && (isNaN(longitudeValue) || longitudeValue < -180 || longitudeValue >= 180)) {
            toast.error('Longitude should be in range of [-180,180)');
            return;
          }
          setShowLoader(true)
          const addCharingStationRequest = {
            name: stationFormData.stationName,
            customerId: stationFormData.customer?.id,
            description: stationFormData.description,
            addressLine1: stationFormData.addressLine1,
            addressLine2: stationFormData.addressLine2,
            city: stationFormData?.city,
            state: stationFormData?.state,
            pinCode: stationFormData?.pinCode,
            latitude: parseFloat(stationFormData?.latitude ?? ''),
            longitude: parseFloat(stationFormData?.longitude ?? ''),
            imageUrl: stationFormData?.imageUrl,
            fileName: stationFormData?.fileName
          }
          const editCharingStationRequest = {
            id: singleStationDetail?.id,
            name: stationFormData.stationName,
            customerId: stationFormData.customer?.id,
            description: stationFormData.description,
            addressLine1: stationFormData.addressLine1,
            addressLine2: stationFormData.addressLine2,
            city: stationFormData?.city,
            state: stationFormData?.state,
            pinCode: stationFormData?.pinCode,
            latitude: parseFloat(stationFormData?.latitude ?? ''),
            longitude: parseFloat(stationFormData?.longitude ?? ''),
            imageUrl: stationFormData?.imageUrl,
            fileName: stationFormData?.fileName

          }
          if (preSignedUrl && selectedFile) {
            handleFileUpload(preSignedUrl?.url, selectedFile);
          }
          const response: AxiosResponse = mode === 'Add' ? await dispatch(createChargingStation(addCharingStationRequest)) : await dispatch(updateChargingStation(editCharingStationRequest));
          if (response?.status === 200 || response?.status === 202) {
            setShowLoader(false)

            mode === 'Add' ? StationAddedToast() : StationEditedToast();
            setTimeout(() => {
            navigate('/charging-stations');
            }, 2000)
          }
          else {
            setShowLoader(false)
            somethingWentWrongToast();
          }
        },
        isDisabled: disableAddButton(),
      },
      {
        buttonText: 'Cancel',
        buttonId: 'cancel',
        btnClassName: 'secondary__btn',
        handleClick: (): void => {
          if (location?.state?.from) navigate(location?.state?.from, { state: { ...location?.state, mode: location?.state?.chargingStationMode } })
          else navigate('/charging-stations');
        },
        isDisabled: false,
        buttonVariant: 'outlined',
      },
    ];
  }, [disableAddButton, stationFormData, mode, initialStationFormData]);


  const setStationDetailData = (updatedState) => {
    setStationFormData(prevState => (
      {
        ...prevState,
        ...updatedState
      }
    ))
  }


  const keyPressValidation = (event): void => {
    const { key, target } = event;
    // Allow dot (.) for decimal values
    if (key === '.' && target?.value?.includes('.')) {
      event.preventDefault();
    }
    if (key === '-' && target?.selectionStart === 0 && target?.selectionEnd === 0 && !target?.value?.includes('-')) {
      // Allow minus sign (-) only if it's the first character and the input field is empty
      // This condition checks if the cursor is at the beginning of the input field
      // and if the input field does not already contain a minus sign
      return;
    }
    // Check for numeric keys, backspace, and allow CMD+V (for Mac) or CTRL+V (for Windows/Linux)
    if (key === ' ' || (isNaN(Number(key)) && key !== '.' && key !== 'Backspace' && !(event.metaKey || event.ctrlKey && key.toLowerCase() === 'v'))) {
      event.preventDefault();
    }
  };

  // function check(value: string): string {
  //   // Regular expression to match a decimal number
  //   const decimalPattern = /^[-+]?\d*\.?\d+$/;
  //   if (decimalPattern.test(value + '0')) {
  //     return value;//value;//(value.at(-1)==='.')?parseFloat(value).toString():value;
  //   } else {
  //     return '';
  //   }
  // }

  const handleFormDataChange = useCallback((val, formKey) => {
    const formData = cloneDeep(stationFormData);
    formData[formKey] = val;
    setStationFormData(formData);
  }, [stationFormData]);


  
  const handleFileUpload = async (signedURL: string, file: File): Promise<void> => {
    try {

      await axios.put(signedURL, file, {
        headers: {
          'Content-Type': file.type,
          'x-ms-blob-type': 'BlockBlob'
        }
      });
    } catch (error) {
      setSelectedImage(null);
      setSelectedFile(null);
      console.error('Error uploading file:', error);
    }
  };
  const handleImageChange = async (file): Promise<void> => {
    if (file && file?.length > 0) {
      const reader = new FileReader();
      setSelectedFile(file[0]);
      const response = await dispatch(fetchPreSignedURLForUpload({ fileName: file[0]?.name }));
      setPreSignedUrl(response?.data)
      handleFormDataChange(response?.data?.blobName, 'fileName')
      reader.onload = () => {
        if (typeof reader.result === 'string') {
          setSelectedImage(reader.result);
        }
      };
      reader.readAsDataURL(file?.[0]);
      setStationDetailData({ stationImage: file?.[0]?.name })
    }
    else {
      setSelectedImage(null);
      setSelectedFile(null);
      setStationDetailData({ stationImage: file?.[0]?.name })
    }
  }
  const triggerDialog = (): void => {
    setShowDialog(true)
  }
  const handleChargerNavigation = async () => { 
      const response = await setRedirectedStateForChargerFilters(true, null, null,singleStationDetail?.id )(dispatch)
    if (response === 'Action dispatched successfully') {
      navigate('/manage-chargers');
    }
  }
  
  const headerActionBtns = renderHeaderActionButtons({
    actionBtns:
      [
        {
          id: 'view_chargers',
          isAccessible: access?.chargingStation?.add(),
          headerActionBtnClick: (): void => {
            handleChargerNavigation();
          }
        },
        {
          id: 'add_new_charger',
          isAccessible: access?.chargingStation?.add(),
          headerActionBtnClick: (): void => {
            navigate('/add-charger', { state: { from: '/station', name: stationFormData?.customer?.label, id:stationFormData?.customer?.id , chargingStationMode: 'Edit' , stationData: location?.state?.rowData, stationName:stationFormData?.stationName, stationId:singleStationDetail?.id } })
          }
        }
      ]
  });


  const profileInfoFormArray: IFormContentProp[] = [
    {
      rowCountClassName: 'grid-3',
      formObject: [
        {
          formLabel: 'Customer',
          isRequired: true,
          objectType: 'dropdown',
          selectDropdownProps: {
            selectValue: stationFormData?.customer,
            selectOptions: removeAllFromReferenceData(customerReferenceData) as IGeneralReferenceData[] || [],
            handleSelectOptionChange: (val): void => {
              // handleCustomerSelectChange(val);
              handleFormDataChange(val, 'customer');
            },
            selectDropDownId: 'customer-dropdown',
            selectDropDownName: 'customer-dropdown',
            isDisabled: mode == 'View' 
          },
          inputProps: null,
          textAreaProps: null,
        },
        {
          formLabel: 'Station Name',
          isRequired: true,
          objectType: 'input',
          selectDropdownProps: null,
          inputProps: {
            inputPlaceHolder: 'Type Here',
            // handleInputChange: handleStationNameChange,
            handleInputChange: (event): void => {
              // handleStationNameChange,
              handleFormDataChange(event?.target?.value, 'stationName');
            },
            inputValue: stationFormData?.stationName ?? '',
            inputFieldId: 'add_user_name',
            isRequired: false,
            isDisabled: mode === 'View'
          },
          textAreaProps: null,
        },
        {
          formLabel: 'Station Image',
          isRequired: false,
          objectType: 'upload',
          selectDropdownProps: null,
          inputProps: null,
          uploadProps: {
            fileName: stationFormData?.stationImage,
            inputFieldId: '',
            isRequired: false,
            handleInputChange: handleImageChange,
            showDialog: triggerDialog,
            isDisabled: mode === 'View'
          },
          textAreaProps: null,
        },
      ],
    },
    {
      rowCountClassName: 'grid',
      formObject: [
        {
          formLabel: 'Address',
          isRequired: false,
          objectType: 'input',
          selectDropdownProps: null,
          inputProps: {
            inputPlaceHolder: 'Enter Address Line 1',
            // handleInputChange: handleAddressLine1Change,
            handleInputChange: (event): void => {
              handleFormDataChange(event?.target?.value, 'addressLine1');
            },
            inputValue: stationFormData?.addressLine1 ?? '',
            inputFieldId: 'charging__station__form-addressLine1',
            isRequired: false,
            isDisabled: mode === 'View'
          },
          textAreaProps: null,
        }
      ]
    },
    {
      rowCountClassName: 'grid',
      formObject: [
        {
          formLabel: '',
          isRequired: false,
          objectType: 'input',
          selectDropdownProps: null,
          inputProps: {
            inputPlaceHolder: 'Enter Address Line 2',
            // handleInputChange: handleAddressLine2Change,
            handleInputChange: (event): void => {
              handleFormDataChange(event?.target?.value, 'addressLine2');
            },
            inputValue: stationFormData?.addressLine2 ?? '',
            inputFieldId: 'charging__station__form-addressLine2',
            isRequired: false,
            isDisabled: mode === 'View'
          },
          textAreaProps: null,
        }
      ]
    },
    {
      rowCountClassName: 'grid-4',
      formObject: [
        {
          formLabel: 'City',
          isRequired: false,
          objectType: 'input',
          selectDropdownProps: null,
          inputProps: {
            inputPlaceHolder: 'Enter City here',
            // handleInputChange: handleCityChange,
            handleInputChange: (event): void => {
              handleFormDataChange(event?.target?.value, 'city');
            },
            inputValue: stationFormData?.city ?? '',
            inputFieldId: 'charging__station__form-city',
            isRequired: false,
            isDisabled: mode === 'View'
          },
          textAreaProps: null,
        },
        {
          formLabel: 'State',
          isRequired: false,
          objectType: 'input',
          selectDropdownProps: null,
          inputProps: {
            inputPlaceHolder: 'Enter State here',
            handleInputChange: (event): void => {
              handleFormDataChange(checkCharactersPaste(event?.target?.value), 'state');
              //  handleCustomerStateChange(checkCharactersPaste(event?.target?.value));
            },
            handleKeyPress: keyPressValidationForCharacters,
            inputValue: stationFormData?.state ?? '',
            inputFieldId: 'charging__station__form-state',
            isRequired: false,
            isDisabled: mode === 'View'
          },
          textAreaProps: null,
        },
        {
          formLabel: 'Pin Code',
          isRequired: false,
          objectType: 'input',
          selectDropdownProps: null,
          inputProps: {
            inputPlaceHolder: 'Type here',
            handleInputChange: (event): void => {
              handleFormDataChange(checkPINCodePaste(event), 'pinCode');
              // handlePinCodeChange(checkPINCodePaste(event));
            },
            inputValue: stationFormData?.pinCode ?? '',
            className: 'form-column-66',
            inputFieldId: 'pin_code',
            isRequired: false,
            handleKeyPress: keyPressValidationForPINCode,
            isDisabled: mode === 'View'
          },
          textAreaProps: null,
        },
      ]
    },
    {
      rowCountClassName: 'grid-6',
      formObject: [
        {
          formLabel: 'Latitude',
          isRequired: true,
          objectType: 'input',
          selectDropdownProps: null,
          inputProps: {
            inputPlaceHolder: 'Type here',
            handleInputChange: (event): void => {
              handleFormDataChange(event?.target?.value, 'latitude');
            },
            inputValue: stationFormData?.latitude ?? '',
            inputFieldId: 'charging__station__form-latitude',
            isRequired: true,
            handleKeyPress: keyPressValidation,
            isDisabled: mode === 'View'
          },
          textAreaProps: null,
        },
        {
          formLabel: 'Longitude',
          isRequired: true,
          objectType: 'input',
          selectDropdownProps: null,
          inputProps: {
            inputPlaceHolder: 'Type here',
            handleInputChange: (event): void => {
              handleFormDataChange(event?.target?.value, 'longitude');
            },
            inputValue: stationFormData?.longitude ?? '',
            inputFieldId: 'charging__station__form-longitude',
            isRequired: true,
            isDisabled: mode === 'View',
            handleKeyPress: keyPressValidation
          },
          textAreaProps: null,
        },
      ]
    },
    {
      rowCountClassName: 'grid-1',
      formObject: [
        {
          formLabel: 'Notes',
          isRequired: false,
          objectType: 'text-area',
          selectDropdownProps: null,
          inputProps: null,
          textAreaProps: {
            inputPlaceHolder: 'Type here',

            handleInputChange: (event): void => {
              // handleStationNameChange,
              handleFormDataChange(event?.target?.value, 'description');
            },
            isRequired: false,
            inputValue: stationFormData?.description ?? '',
            inputFieldId: 'adding_station',
            isDisabled: mode === 'View'
          },
        },
      ],
    },
  ]

  const formProps: ICustomFormProps = useMemo(() => {
    return {
      formContainerClassName: '',
      formArray: profileInfoFormArray
    };
  }, [stationFormData, btnList, mode])



  const breadCrumbs = createBreadCrumbProps({
    breadCrumbProps:
      [
        {
          objectType: 'link',
          id: 'chargingStation',
        },
        {
          objectType: 'text',
          id: 'text',
          text: mode !== 'Add' ? stationFormData?.stationName || location?.state?.rowData?.chargingStationName  : `${mode} Charging Station`
        }
      ]
  })
  const setHeaderText = useCallback(() => {
    return mode !== 'Add' ?  stationFormData?.stationName || location?.state?.rowData?.chargingStationName : `${mode} Charging Station`
  }, [mode,stationFormData])

  return (
    <>
      <DashboardLoader showLoader={showLoader} />
      <div className="add__station__form__wrap">
        <CustomBreadCrumbs
          containerId={'add-charging-station-breadcrumb'}
          containerClassName={'add-charging-station-breadcrumb'}
          breadCrumbs={breadCrumbs} />
        <div className="add__station__header">
          <div className='chargingStation__form__wrap-header-wrap-buttons'>
            <DashboardHeader header={mode != 'Add' ? setHeaderText() : 'Add Charging Station'} />
            <div className='station-edit-view-icons'>
            {mode !== 'Add' && access?.chargingStation?.edit() && <Icon iconClassName={mode === 'View' ? 'rms__pencil__icon' : 'rms__view__icon'} containerClassName='station__form_header-edit' onIconClick={() => {
              if (mode === 'Edit') loadInitialData()
              setMode(mode === 'View' ? 'Edit' : 'View')
            }
            } />}
            </div>
         

          </div>
          {mode !== 'Add' && <div className='chargingStation__form__wrap-header-wrap-buttons'>
            <TableSubHeader headerActionBtns={mode === 'View' ? headerActionBtns?.filter((button) => button?.buttonId === 'view_chargers') : headerActionBtns} />
           
           { mode === 'Edit' &&
            <IconWithTooltip
              key={''}
              toolTipText={'View Update History'}
              onIconClick={() => { navigate('/station-edit-history', { state: { id: singleStationDetail?.id, mode: mode,name:singleStationDetail?.customerName ,rowData:location?.state?.rowData} }) }}
              iconClassName={'rms_history_outlined_icon_customer'}
            />
           } 

          </div>}
        </div>
        <CustomForm {...formProps} />
        
          <div className='add__station--footer'>
            <CustomButtonGroup
              buttonsList={mode === 'View' ? btnList?.filter((button) => button?.buttonId === 'cancel') : btnList}
              buttonGroupClassName={'button__group__footer'}
            />
          </div>
        
      </div>
      {mode !== 'View' && <ImageDialog
        dialogConfig={{
          imageUrl: selectedImage ?? singleStationDetail?.imageUrl,
          dialogOkTitle: 'Close',
        }}
        show={showDialog}
        handleSubmit={() => setShowDialog(false)}
      />}
    </>
  )
}

export default ChargingStationForm
