import React, { FC } from 'react';
import './ScreenOperationsSelector.css';
import { CheckBox } from '../globalExports';
import { cloneDeep } from 'lodash';

export interface IScreenOperationsSelectorProps {
    header?: string;
    formContainerClass?: string;
    data: IRolePermissionReferenceData[];
    // eslint-disable-next-line @typescript-eslint/ban-types
    handleSelectedData?: Function;
    screenOperationsSelectorState: IRolePermissionReferenceData[];
    isDisabled: boolean;
}

const ScreenOperationsSelector: FC<IScreenOperationsSelectorProps> = (props) => {
    const { header, data, handleSelectedData, screenOperationsSelectorState, isDisabled } = props;

    const handleIndividualScreenOperationClick = (screen: string, operation: string) => {
        let updatedScreenOperationsSelectorState = cloneDeep(screenOperationsSelectorState);
        if (ifScreenOrSubscreenExists(updatedScreenOperationsSelectorState, screen)) {
            if (isOperationExists(updatedScreenOperationsSelectorState, screen, operation)) {
                updatedScreenOperationsSelectorState = removeOperation(updatedScreenOperationsSelectorState, screen, operation)
                const individualScreenSelectedOperations = findIndividualScreenOperations(updatedScreenOperationsSelectorState, screen)
                updatedScreenOperationsSelectorState = handleForAllCase(updatedScreenOperationsSelectorState, screen, operation, false)
                if (!individualScreenSelectedOperations?.length) updatedScreenOperationsSelectorState = handleIndividualCheckboxChange(screen)
            } else {
                updatedScreenOperationsSelectorState = insertOperation(updatedScreenOperationsSelectorState, screen, operation)
                updatedScreenOperationsSelectorState = handleForAllCase(updatedScreenOperationsSelectorState, screen, operation, true)
            }
        }
        handleSelectedData?.(updatedScreenOperationsSelectorState)
    };

    const handleIndividualCheckboxClick = (screen: string) => {
        const updatedScreenOperationsSelectorState = handleIndividualCheckboxChange(screen)
        handleSelectedData?.(updatedScreenOperationsSelectorState);
    }

    const handleIndividualCheckboxChange = (screen: string) => {
        let updatedScreenOperationsSelectorState = cloneDeep(screenOperationsSelectorState);
        if (ifScreenOrSubscreenExists(updatedScreenOperationsSelectorState, screen)) {
            updatedScreenOperationsSelectorState = removeScreenOrSubscreen(updatedScreenOperationsSelectorState, screen);
        } else {
            const screenWithOperation = findScreenOrSubscreen(data, screen)      // from data
            if (screenWithOperation) {
                const updatedScreenWithOperation = cloneDeep(screenWithOperation)
                if (screenWithOperation.screen != screen) {
                    const updatedSubScreen = updatedScreenWithOperation?.subScreen?.filter((obj) => obj.screen === screen)
                    updatedSubScreen[0].operation = ['View'];
                    updatedScreenOperationsSelectorState = updateSubscreenOfScreen(updatedScreenOperationsSelectorState, screenWithOperation.screen, updatedSubScreen)
                }
                else {
                    updatedScreenWithOperation.subScreen = [];
                    updatedScreenWithOperation.operation = ['View'];
                    updatedScreenOperationsSelectorState.push(updatedScreenWithOperation);
                }
            }
        }
        return updatedScreenOperationsSelectorState;
    };

    const handleForAllCase = (updatedScreenOperationsSelectorState, screen, operation, turnOn) => {
        const individualScreenSelectedOperations = findIndividualScreenOperations(updatedScreenOperationsSelectorState, screen)
        const individualScreenTotalOperations = findIndividualScreenOperations(data, screen)
        if (individualScreenTotalOperations && !individualScreenTotalOperations?.includes('All')) return updatedScreenOperationsSelectorState
        if (!turnOn) {
            if ((individualScreenSelectedOperations?.length ?? -1000000) + 1 === individualScreenTotalOperations?.length && operation != 'All') updatedScreenOperationsSelectorState = removeOperation(updatedScreenOperationsSelectorState, screen, 'All')
            if (operation === 'All') updatedScreenOperationsSelectorState = handleIndividualCheckboxChange(screen)
        } else {
            if (operation === 'All' || ((individualScreenSelectedOperations?.length || -1000000) + 1 === individualScreenTotalOperations?.length)) {
                individualScreenTotalOperations?.forEach(op => {
                    updatedScreenOperationsSelectorState = insertOperation(updatedScreenOperationsSelectorState, screen, op);
                });
            }
        }
        return updatedScreenOperationsSelectorState
    }

    const removeOperation = (updatedScreenOperationsSelectorState, screen, operation) => {
        return updatedScreenOperationsSelectorState.map(item => {
            if (item.screen === screen) {
                if (item.operation && item.operation.includes(operation)) {
                    item.operation = item.operation.filter(op => op !== operation);
                }
            } else if (item.subScreen && item.subScreen.length > 0) {
                const subItem = item.subScreen.find(sub => sub.screen === screen);
                if (subItem && subItem.operation && subItem.operation.includes(operation)) {
                    subItem.operation = subItem.operation.filter(op => op !== operation);
                }
            }
            return item;
        });
    };

    const insertOperation = (updatedScreenOperationsSelectorState, screen, operation) => {
        return updatedScreenOperationsSelectorState.map(item => {
            if (item.screen === screen) {
                if (!item.operation) {
                    item.operation = [];
                }
                if (item.operation && !item.operation.includes(operation)) item.operation.push(operation);
            } else if (item.subScreen && item.subScreen.length > 0) {
                item.subScreen.forEach(sub => {
                    if (sub.screen === screen) {
                        if (!sub.operation) {
                            sub.operation = [];
                        }
                        if (sub.operation && !sub.operation.includes(operation)) sub.operation.push(operation);
                    }
                });
            }
            return item;
        });
    };

    const removeScreenOrSubscreen = (updatedScreenOperationsSelectorState: IRolePermissionReferenceData[], screen: string) => {
        return updatedScreenOperationsSelectorState.filter(obj => {
            if (obj.screen === screen) {
                return false;
            }
            if (obj.subScreen) {
                obj.subScreen = obj.subScreen.filter(subObj => subObj.screen !== screen);
            }
            return true;
        });
    }

    const findScreenOrSubscreen = (updatedScreenOperationsSelectorState: IRolePermissionReferenceData[], screen: string) => {
        return updatedScreenOperationsSelectorState.find(obj => {
            if (obj.screen === screen) {
                return true;
            }
            if (obj.subScreen) {
                return obj.subScreen.some(subObj => subObj.screen === screen);
            }
            return false;
        });
    }

    const findIndividualScreenOperations = (updatedScreenOperationsSelectorState: IRolePermissionReferenceData[], screen: string) => {
        for (const obj of updatedScreenOperationsSelectorState) {
            if (obj.screen === screen) return obj.operation;
            if (obj.subScreen) {
                const subObj = obj.subScreen.find(subObj => subObj.screen === screen);
                if (subObj) return subObj.operation;
            }
        }
    }

    const updateSubscreenOfScreen = (updatedScreenOperationsSelectorState: IRolePermissionReferenceData[], screen: string, updatedScreenWithOperation: ISubScreenDataObj[]) => {
        const updatedState = updatedScreenOperationsSelectorState.map((item) => {
            if (item.screen === screen) {
                item.subScreen.push(updatedScreenWithOperation[0])
                return item;
            }
            return item;
        });
        return updatedState;
    }

    const isOperationExists = (updatedScreenOperationsSelectorState: IRolePermissionReferenceData[], screen: string, operation: string) => {
        const screenWithSubscreen = findScreenOrSubscreen(updatedScreenOperationsSelectorState, screen);
        if (!screenWithSubscreen) return false;
        
        if (screenWithSubscreen && screenWithSubscreen.screen === screen && screenWithSubscreen.operation) {
            return screenWithSubscreen.operation?.includes(operation);
        }
        return screenWithSubscreen.subScreen.filter(item => item.screen === screen)[0].operation.includes(operation);
    }

    const ifScreenOrSubscreenExists = (screenOperationsSelectorState: IRolePermissionReferenceData[], screen: string) => {
        return screenOperationsSelectorState.some(obj => {
            if (obj.screen === screen) return true;
            if (obj.subScreen) return obj.subScreen.some(subObj => subObj.screen === screen);
            return false;
        })
    }

    const renderOperations = (screen: string, operations: string[]) => {
        return operations.map((operation, index) => (
            <button
                key={`${screen}${index}`}
                className={`operation-button ${isOperationExists(screenOperationsSelectorState, screen, operation) ? 'selected' : ''}`}
                onClick={() => !isDisabled && handleIndividualScreenOperationClick(screen, operation)}
            >
                {operation}
            </button>
        ));
    };

    const renderSubScreen = (data: ISubScreenDataObj[]) => {
        return data.map((item, index) => (
            <div className='sub-screen-operations' key={`${item.screen}${index}`}>
                <div className='sub-screen'>
                    <div className='checkbox' onClick={() => !isDisabled && handleIndividualCheckboxClick(item?.screen)}><CheckBox checked={ifScreenOrSubscreenExists(screenOperationsSelectorState, item.screen)} isDisabled={isDisabled} /></div>
                    <div className='checkbox-text'>{item.screen}</div>
                </div>
                <div className={index ? 'sub-operation' : 'operation'}>{ifScreenOrSubscreenExists(screenOperationsSelectorState, item.screen) && item.operation && renderOperations(item.screen, item.operation)}</div>
            </div>
        ));
    };

    const renderScreenOperations = (data: IRolePermissionReferenceData[]) => {
        return data.map((item) => (
            <div className={`screen-operations ${item.subScreen && item.subScreen.length > 0 ? 'sub-screen-present' : ''}`} key={item.screen}>
                <div className={`screen ${item.subScreen && item.subScreen.length > 0 ? 'sub-screen-present' : ''}`}>
                    <div className='checkbox' onClick={() => !isDisabled && handleIndividualCheckboxClick(item?.screen)}><CheckBox checked={ifScreenOrSubscreenExists(screenOperationsSelectorState, item.screen)} isDisabled={isDisabled} /></div>
                    <div className='checkbox-text'>{item.screen}</div>
                </div>
                {item.subScreen && item.subScreen.length > 0 && ifScreenOrSubscreenExists(screenOperationsSelectorState, item.screen) ? (
                    renderSubScreen(item.subScreen)
                ) : (
                    <>
                        {!item.subScreen && <div className='operation'>{ifScreenOrSubscreenExists(screenOperationsSelectorState, item.screen) && item.operation && renderOperations(item.screen, item.operation)}</div>}
                    </>
                )}
            </div>
        ));
    };

    return (
        <div className='role_form_screen_operations_component'>
            {header && header.length && <div className='heading-note'>{header}</div>}
            {renderScreenOperations(data)}
        </div>
    );
};

export default ScreenOperationsSelector;
