
import Highcharts, { Options } from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import React,{ type FC } from 'react';
import highchartsMore from 'highcharts/highcharts-more';
highchartsMore(Highcharts);
import xrange from 'highcharts/modules/xrange';

xrange(Highcharts);

interface HighChartProps {
  highChartOptions: Options;
  container?: string
}

const CustomHighCharts: FC<HighChartProps> = ({ highChartOptions, container }) => {
  return (
    <HighchartsReact
      containerProps={{ style: { height: '100%', width: '100%', display: 'flex' } }}
      highcharts={Highcharts} container={container} options={highChartOptions || {}} />
  );
};

export default CustomHighCharts;
