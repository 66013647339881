/* eslint-disable @typescript-eslint/no-explicit-any */
import { isAxiosError } from 'axios';
import hwaxios from '../api-service/HWAxios';
import { reportTypes } from '../reduxExports';
import rmsStore from '../rmsStore';
import { isEqual } from 'lodash';

const url = process.env.REACT_APP_SERVER_BASE_URL

// TODO: CHARGING HISTORY LIST API
export const fetchChargingHistoryReportData: any = (reqBody: unknown) => async (dispatch: (arg0: Partial<ReportAction>) => void): Promise<void> => {
    try {
        const loaderStartAction: Partial<ReportAction> = {
            type: reportTypes.SET_REPORT_LOADER,
            reportLoader: true
        }
        dispatch(loaderStartAction);
        const response = await hwaxios.post(url + '/report/charging-history', reqBody)       
        const action: Partial<ReportAction> = {
            type: reportTypes.FETCH_CHARGERS_HISTORY_DATA,
            chargingHistoryColumnHeaders: response.data?.columns,
            chargingHistoryData: response.data?.data?.history,
            chargingHistoryCount: response.data?.data?.count,
        }
        dispatch(action)
        if (response?.status === 200) {
            const loaderEndAction: Partial<ReportAction> = {
                type: reportTypes.SET_REPORT_LOADER,
                reportLoader: false
            }
            dispatch(loaderEndAction);
        }
    } catch (error) {
        const loaderEndAction: Partial<UserAction> = {
            type: reportTypes.SET_REPORT_LOADER,
            loader: false
        }
        dispatch(loaderEndAction);
        console.error(error)
        throw error
    }
}

// TODO: EXPORT REPORTS
export const exportChargingHistoryReport: any = (reqParams: unknown) => async () => {
    try {
        const { data, status } = await hwaxios.post(url + '/report/charging-history/export', reqParams)       
        return { data, status };
    } catch (error) {
        if (isAxiosError(error)) {
            if (error.response) {
                const data = error.response.data;
                const status = error.response.status;
                return { data, status };
            }
        } else {
            return {
                data: 'Something went wrong', status: 400
            }
        }
    }
}

// TODO: CHARGER LOGS LIST API
export const fetchChargerLogsData: any = (reqBody: any) => async (dispatch: (arg0: Partial<ReportAction>) => void): Promise<void> => {
    try {
        const loaderStartAction: Partial<ReportAction> = {
            type: reportTypes.SET_REPORT_LOADER,
            reportLoader: true
        }
        dispatch(loaderStartAction);

        const filtersHash = reqBody?.filters
        const hashAction : Partial<ReportAction> = {
          type : reportTypes.SET_CHARGER_LOGS_LIST_REQUEST_HASH,
          chargerLogsFiltersRequestHash : filtersHash
        }
        dispatch(hashAction)
        const response = await hwaxios.post(url + '/report/charger/history', reqBody)
         
        if(isEqual(filtersHash ,rmsStore.getState().reportsReducer.chargerLogsFiltersRequestHash)){
            const action: Partial<ReportAction> = {
                type: reportTypes.FETCH_CHARGER_LOGS_DATA,
                chargerLogsColumnHeaders: response.data?.columns,
                chargerLogsData: response.data?.data?.history ?? response?.data?.data?.latest,
                chargerLogsCount: response.data?.data?.count,
            }
            dispatch(action)
        }

        if (response?.status === 200) {
            const loaderEndAction: Partial<ReportAction> = {
                type: reportTypes.SET_REPORT_LOADER,
                reportLoader: false
            }
            dispatch(loaderEndAction);
        }
    } catch (error) {
        const loaderEndAction: Partial<UserAction> = {
            type: reportTypes.SET_REPORT_LOADER,
            loader: false
        }
        dispatch(loaderEndAction);
        console.error(error)
        throw error
    }
}

// TODO: EXPORT REPORTS
export const exportChargerLogsReport: any = (reqParams: unknown) => async () => {
    try {
        const { data, status } = await hwaxios.post(url + '/report/charger/history/export', reqParams)       
        return { data, status };
    } catch (error) {
        if (isAxiosError(error)) {
            if (error.response) {
                const data = error.response.data;
                const status = error.response.status;
                return { data, status };
            }
        } else {
            return {
                data: 'Something went wrong', status: 400
            }
        }
    }
}

// TODO: EXCEPTION LOGS LIST API
export const fetchExceptionLogsData: any = (reqBody: unknown) => async (dispatch: (arg0: Partial<ReportAction>) => void): Promise<void> => {
    try {
        const loaderStartAction: Partial<ReportAction> = {
            type: reportTypes.SET_REPORT_LOADER,
            reportLoader: true
        }
        dispatch(loaderStartAction);
        const response = await hwaxios.post(url + '/log-dashboard', reqBody)
        const action: Partial<ReportAction> = {
            type: reportTypes.FETCH_EXCEPTION_LOGS_DATA,
            exceptionLogsColumnHeaders: response.data?.columns,
            exceptionLogsData: response.data?.data?.logs,
            exceptionLogsCount: response.data?.data?.logCount,
        }
        dispatch(action)
        if (response?.status === 200) {
            const loaderEndAction: Partial<ReportAction> = {
                type: reportTypes.SET_REPORT_LOADER,
                reportLoader: false
            }
            dispatch(loaderEndAction);
        }
    } catch (error) {
        const loaderEndAction: Partial<UserAction> = {
            type: reportTypes.SET_REPORT_LOADER,
            loader: false
        }
        dispatch(loaderEndAction);
        console.error(error)
        throw error
    }
}

// TODO: EXPORT EXCEPTION LOGS
export const exportExceptionLogs: any = (reqParams: unknown) => async () => {
    try {
        const { data, status } = await hwaxios.post(url + '/log-dashboard/export', reqParams)       
        return { data, status };
    } catch (error) {
        if (isAxiosError(error)) {
            if (error.response) {
                const data = error.response.data;
                const status = error.response.status;
                return { data, status };
            }
        } else {
            return {
                data: 'Something went wrong', status: 400
            }
        }
    }
}


// TODO: SET REPORT LIST TABLE PROPS
export const setReportListTableProps: any = (data,screen) => async (dispatch: (arg0: Partial<ReportAction>) => void): Promise<void> => {
    if (screen === 'chargingHistory') {
        const action: Partial<ReportAction> = {
            type: reportTypes.SET_CHARGING_HISTORY_LIST_TABLE_PROPS,
            chargingHistoryListTableProps: data,
        };
        dispatch(action);
      } else if (screen === 'chargerLogs') {
        const action = {
          type: reportTypes.SET_CHARGER_LOGS_LIST_TABLE_PROPS,
          chargerLogsListTableProps: data,
        }
        dispatch(action);
      }
      else if (screen === 'exceptionLogs') {
        const action = {
          type: reportTypes.SET_EXCEPTION_LOGS_LIST_TABLE_PROPS,
          exceptionLogsListTableProps: data,
        }
        dispatch(action);
      }
}