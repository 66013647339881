/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { Dispatch, FC, SetStateAction } from 'react'
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { RotatingLines } from 'react-loader-spinner';

interface DashboardLoaderProps {
  showLoader: boolean
  handleClose?: Dispatch<SetStateAction<boolean>>
  loaderText?:string
}


const DashboardLoader: FC<DashboardLoaderProps> = (props) => {

  const { showLoader, handleClose,loaderText } = props;

  const handleLoaderClose = (): void => {
    handleClose?.(false);
  };

  return (
    <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1,flexDirection:'column'}} open={showLoader}>
      {loaderText && <div className='rms__dashboard__loader-text'>{loaderText}</div>}
      <RotatingLines
        strokeColor="var(--secondary-blue-color)"
        strokeWidth="3"
        animationDuration="0.75"
        width="50"
        visible={true}
      />
    </Backdrop>
  )
}

export default DashboardLoader