import { Input as BaseInput } from '@mui/base/Input';

import PropTypes from 'prop-types';
import * as React from 'react';
import './CustomInput.css';
import { styled } from '@mui/material';

export const CustomInputFieldType = {
  inputValue: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.number.isRequired,
    PropTypes.oneOf([null]).isRequired,
  ]).isRequired,
  inputFieldId: PropTypes.string.isRequired,
  isRequired: PropTypes.bool.isRequired,
  inputType: PropTypes.string,
  inputName: PropTypes.string,
  className: PropTypes.string,
  inputPlaceHolder: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.oneOf([null]),
  ]),
  isDisabled: PropTypes.bool,
  autoFocus: PropTypes.bool,
  errorMessage: PropTypes.string,
  handleInputChange: PropTypes.func,
  handleKeyPress: PropTypes.func
};

export type CustomInputFieldPropTypes = PropTypes.InferProps<
  typeof CustomInputFieldType
>;

const Input = React.forwardRef(function CustomInput(
  props: React.InputHTMLAttributes<HTMLInputElement>,
  ref: React.ForwardedRef<HTMLDivElement>
) {
  return (
    <BaseInput
      className='custom__input'
      required={true}
      slots={{ input: InputElement }}
      {...props}
      ref={ref}
    />
  );
});

const InputElement = styled('input')(
  () => `
    width: 100%;
    font-family: Inter;
    font-size: 13px;
    letter-spacing: -0.01px;
    font-weight: 400;
    line-height: 1.5;
    padding: 8px 12px;
    border-radius: 4px;
    color: var(--primary-text-color);
    background: var(--white-color);
    border: 1px solid var(--grey-color);
    &:hover {
      border-color:var(--secondary-blue-color);
    } 
    &:focus {
     border-color: var(--secondary-blue-color); 
      // box-shadow: 0 0 0 1px var(--secondary-blue-color);
      outline:0
    }
  
    // firefox
    &:focus-visible {
      outline: 0;
    }
    &:disabled {
      background-color: hsl(0, 0%, 95%);
      color: hsl(0, 0%, 60%);
    }
  `
);

const CustomInput: React.FC<CustomInputFieldPropTypes> = (props) => {
  const {
    inputValue,
    inputFieldId,
    inputType,
    inputName,
    className,
    inputPlaceHolder,
    isDisabled,
    autoFocus,
    errorMessage,
    handleInputChange,
    handleKeyPress,
  } = props;


  return (
    <div className="rms__custom__input__field__container">
      <Input
        className={'rms__custom__input__field ' + className}
        type={inputType ?? 'text'}
        id={inputFieldId}
        name={inputName ?? ''}
        value={inputValue}
        required={true}
        placeholder={inputPlaceHolder ?? ''}
        disabled={isDisabled || false}
        autoFocus={autoFocus || false}
        onKeyDown={(event) => {
          handleKeyPress?.(event)
        }}
        // min={minValue ?? '0'}
        // max={maxValue ?? ''}
        onChange={(event) => {
          handleInputChange?.(event);
        }}
      />
      {errorMessage && errorMessage?.length > 0 && <div className="rms__custom__input__error__message">
        {errorMessage}
      </div>}
    </div>
  );
};

export default CustomInput;
