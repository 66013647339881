
import { cloneDeep, isEqual } from 'lodash';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import {
  CustomBreadCrumbs,
  CustomButtonGroup,
  DashboardHeader,
  DashboardLoader,
  useAppDispatch,
  useAppSelector,
  useEffectOnce,
} from '../../../globalUtils/globalExports';
import { Id, toast } from 'react-toastify';
import { useLocation, useNavigate } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import CustomForm, { ICustomFormProps, IFormContentProp } from '../../../globalUtils/CustomForm/CustomForm';
import ScreenOperationsSelector from '../../../globalUtils/ScreenOperationsSelector/ScreenOperationsSelector';
import { createBreadCrumbProps } from '../../../globalUtils/globalHooks';
import { fetchRolePermissionReferenceData, getReferenceDataReducer, userTypes, fetchIndividualRoleDetail, getUserReducer, updateRole, createRole } from '../../../rmsReduxStore/reduxExports';
import './RoleForm.css'

const RoleForm: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const initialFormData = {
    roleName: '',
    roleDescription: '',
  };

  const [roleFormData, setRoleFormData] = useState(initialFormData);
  const [initialRoleFormData, setInitialRoleFormData] = useState(initialFormData)
  const [showLoader, setShowLoader] = useState(false);
  const [screenOperationsSelectorState, setScreenOperationsSelectorState] = useState<IRolePermissionReferenceData[]>([]);
  const [mode, setMode] = useState(location?.state?.mode || 'View')
  const { rolePermissionReferenceData } = useAppSelector(getReferenceDataReducer);
  const { individualRoleDetail } = useAppSelector(getUserReducer)

  const handleSelectedValues = (screenOperationsSelectorState) => {
    setScreenOperationsSelectorState(screenOperationsSelectorState)
  };

  const handleFormDataChange = useCallback((val, formKey) => {
    const formData = cloneDeep(roleFormData);
    formData[formKey] = val;
    setRoleFormData(formData);
  }, [roleFormData]);

  const disableSubmitButton = useCallback(() => {
    if (mode === 'Edit' && isEqual(roleFormData, initialRoleFormData) && isEqual(screenOperationsSelectorState, individualRoleDetail?.permissions)) {
      return true
    }
    return false
  }, [roleFormData, mode, initialRoleFormData, screenOperationsSelectorState, individualRoleDetail]);

  const roleFormSuccessToast = (): Id => toast.success(mode === 'Edit' ? 'Role edited' : 'Role added');
  const roleFormErrorToast = (message): Id => toast.error(message);

  const footerButtonList = useMemo(() => {
    return [
      {
        buttonText: mode === 'Edit' ? 'Update' : 'Add',
        buttonId: 'submit',
        btnClassName: disableSubmitButton() ? 'primary__btn disabled' : 'primary__btn',
        handleClick: (): void => {
          handleSubmit?.(mode)
        },
        isDisabled: disableSubmitButton(),
        buttonVariant: 'filled',
      },
      {
        buttonText: 'Cancel',
        buttonId: 'cancel',
        btnClassName: 'secondary__btn',
        handleClick: (): void => {
          navigate('/roles')
        },
        isDisabled: false,
        buttonVariant: 'outlined',
      },
    ];
  }, [disableSubmitButton, roleFormData, mode, screenOperationsSelectorState]);

  useEffectOnce(() => {
    setMode(location?.state?.mode || 'View')
    if (location?.state?.rowData) {
      setRoleFormData({
        ...roleFormData,
        roleName: location?.state?.rowData?.name,
        roleDescription: location?.state?.rowData?.description,
      })
    }
    if (mode && location?.state?.roleId) {
      dispatch(fetchIndividualRoleDetail({ roleId: location?.state?.roleId, }))
    }
    dispatch(fetchRolePermissionReferenceData());
  });

  useEffect(() => {
    loadInitialData()
  }, [individualRoleDetail])

  const loadInitialData = () => {
    if (individualRoleDetail) {
      const roleDetail = {
        roleName: individualRoleDetail?.name,
        roleDescription: individualRoleDetail?.description,
      }
      setRoleFormData({
        ...roleFormData,
        ...roleDetail
      });
      setInitialRoleFormData({
        ...roleFormData,
        ...roleDetail
      })
      setScreenOperationsSelectorState(individualRoleDetail?.permissions)
    }
  }

  const roleNameFormArray: IFormContentProp[] = [
    {
      rowCountClassName: 'grid-3',
      formObject: [
        {
          formLabel: 'Name',
          isRequired: true,
          objectType: 'input',
          selectDropdownProps: null,
          inputProps: {
            inputPlaceHolder: mode !== 'View' ? 'Type here' : '',
            handleInputChange: (event): void => {
              handleFormDataChange(event?.target?.value, 'roleName');
            },
            inputValue: roleFormData?.roleName,
            inputType: 'string',
            inputFieldId: 'role_name',
            isRequired: false,
            isDisabled: mode === 'View',
            autoFocus: mode === 'Add',
          },
          textAreaProps: null,
        }
      ],
    },
    {
      rowCountClassName: 'grid-1',
      formObject: [
        {
          formLabel: 'Description',
          isRequired: false,
          objectType: 'text-area',
          selectDropdownProps: null,
          inputProps: null,
          textAreaProps: {
            inputPlaceHolder: mode !== 'View' ? 'Type here' : '',
            handleInputChange: (event): void => {
              handleFormDataChange(event?.target?.value, 'roleDescription');
            },
            isRequired: false,
            inputValue: roleFormData?.roleDescription,
            inputFieldId: 'role_description',
            isDisabled: mode === 'View',
          },
        }
      ],
    },
  ];

  const roleNameFormProps: ICustomFormProps = useMemo(() => {
    return {
      formContainerClassName: 'add__role__role__name',
      formArray: roleNameFormArray
    };
  }, [roleFormData, mode, rolePermissionReferenceData])


  // TODO: BreadCrumb navigation Props
  const breadCrumbs = createBreadCrumbProps({
    breadCrumbProps:
      [
        {
          objectType: 'link',
          id: 'roles',
        },
        {
          objectType: 'text',
          id: 'text',
          text: mode !== 'Add' ? roleFormData?.roleName : `${mode} Role`
        }
      ]
  })


  //TODO: SUBMIT FORM *******
  const handleSubmit = useCallback(async (mode) => {
    setShowLoader(true)
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const reqBody = {
      id: mode === 'Edit' ? location?.state?.roleId : null,
      roleName: roleFormData?.roleName,
      roleDescription: roleFormData?.roleDescription,
      rolePermission: screenOperationsSelectorState,
    }
    let response: AxiosResponse
    if (mode === 'Edit') {
      response = await dispatch(updateRole(reqBody))
    } else {
      response = await dispatch(createRole(reqBody))
    }
    if ((response?.status === 200 || response?.status === 202) && response?.data?.message === 'Success') {
      setShowLoader(false)
      roleFormSuccessToast();
      navigate('/roles')
    } else {
      setShowLoader(false)
      roleFormErrorToast(response?.data?.message);
    }
  }, [roleFormData, mode])

  // TODO: FORM HEADER TEXT 
  const setHeaderText = useCallback(() => {
    return mode !== 'Add' ? roleFormData?.roleName : `${mode} Role`
  }, [mode, roleFormData])

  // TODO: Redux state Clean up
  const roleFormCleanUpStates = useCallback(() => {
    const action = {
      type: userTypes.CLEAR_ROLE_DETAIL_DATA,
    }
    dispatch(action);
  }, [])

  useEffect(() => {
    window.addEventListener('beforeunload', roleFormCleanUpStates);
    return (): void => {
      roleFormCleanUpStates()
      window.removeEventListener('beforeunload', roleFormCleanUpStates);
    }
  }, [])

  return (
    <>
      <DashboardLoader showLoader={showLoader} />
      <div className='role__form__wrap'>
        <CustomBreadCrumbs breadCrumbs={breadCrumbs} />
        <div className='role__form__wrap-header'>
          <DashboardHeader
            header={setHeaderText()}
            headerClassName='role__form__wrap-header-text'
          />
          {/* {mode !== 'Add' && access?.roles?.edit() && <Icon iconClassName={mode === 'View' ? 'rms__pencil__icon' : 'rms__view__icon'} containerClassName='user__form_header-edit' onIconClick={() => {
            if (mode === 'Edit') loadInitialData()
            setMode(mode === 'View' ? 'Edit' : 'View')
          }
          } />} */}
        </div>
        <div className='role__form__wrap-content'>
          <CustomForm {...roleNameFormProps} />
          <ScreenOperationsSelector
            header={'Select the permissions for the new role'}
            data={rolePermissionReferenceData || []}
            handleSelectedData={handleSelectedValues}
            screenOperationsSelectorState={screenOperationsSelectorState}
            isDisabled={mode === 'View'}
          />
        </div>
        <div className='role__form__wrap-footer'>
          <CustomButtonGroup
            buttonsList={mode === 'View' ? footerButtonList?.filter((button) => button?.buttonId === 'cancel') : footerButtonList}
            buttonGroupClassName='button__group__footer'
          />
        </div>
      </div>
    </>
  );
}

export default RoleForm
