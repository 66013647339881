const headerCardConstants = [
  {
    text: 'Customers',
    icon: 'dashboard__customer__card__icon',
    bgcolor: '#F8F8F8',
    id: 'customers',
    redirectLink:'/customers'
  },
  {
    text: 'Chargers',
    icon: 'dashboard__total__charger__icon',
    bgcolor: '#F8F8F8',
    id: 'totalChargers',
    redirectLink: '/manage-chargers',
    filterId:'all',
  },
  {
    text: 'Online Chargers',
    icon: 'dashboard__online__charger__icon',
    bgcolor: '#27C38033',
    id: 'onlineChargers',
    filterId:'Online',
    redirectLink: '/manage-chargers',
    tooltipText:'Chargers with a live connection to RMS.'
  },
  {
    text: 'Offline Chargers',
    icon: 'dashboard__offline__charger__icon ',
    bgcolor: 'var(--grey-color)',
    id: 'offlineChargers',
    filterId:'Offline',
    redirectLink: '/manage-chargers'
  },
  {
    text: 'CMS Connectivity',
    icon: 'dashboard__cms__connectivity__icon',
    bgcolor: '#207D1133',
    cardClassName: 'cms__connectivity__card',
    id: 'cmsConnectivity',
    redirectLink: '/alarms',
    tooltipText:'Chargers with a live connection to CMS.'
  }
]

export default headerCardConstants
