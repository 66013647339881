import React, { useMemo, type FC } from 'react';
import {
  CustomHighCharts,
  useAppSelector,
} from '../../globalUtils/globalExports';
import { getDashboardReducer } from '../../rmsReduxStore/reduxExports';
import './dashboard.css';
import { Options } from 'highcharts';
import { formatNumberWithCommas } from '../../globalUtils/globalHooks';
import { Icon } from '../../globalUtils/globalIcons';
import { RectangularSkeleton } from '../../globalUtils/SkeletonDesign/SkeletonDesign';

const ConnectivityMediumStatus: FC = () => {
  const { connectivityMediumData } = useAppSelector(getDashboardReducer);

  const connectivityMediumChartOptions: Options = useMemo(() => {
    const filteredData = connectivityMediumData?.filter(entry => entry.percentage) ?? [];
    return {
      chart: {
        events: {
          load: function (): void {
            this.reflow();
          }
        }
      },
      title: {
        text: '',
        align: 'center',
      },
      legend: {
        enabled: false,
      },
      tooltip: {
        enabled: false,
        valueDecimals: 2,
        valueSuffix: ' %',
        formatter: function () {
          let tooltipText = '<b>' + this.point.name + '</b><br/>';
          tooltipText += this.series.name + ' (' + this.point.y + '%)' + '<br/>';
          return tooltipText;
        }
      },
      credits: {
        enabled: false,
      },
      plotOptions: {
        pie: {
          borderWidth: 0,
          colorByPoint: true,
          type: 'pie',
          size: '50%',
          innerSize: '70%',
          dataLabels: {
            enabled: true,
            formatter: function () {
              const points = this.point as unknown as { name: string, y: number, chargerCount: string };
              return '<span>' + this.point.name + '</span><br/>' +
                '<span>' + points.chargerCount + ' (' + this.point.y?.toFixed(1) + '%)</span>';
            },
            crop: false,
            distance: '20%', //-23%
            //distance: filteredData.some(entry => entry.percentage === 50) ? '2%' : filteredData.some(entry => entry.percentage === 0) ? '25%': '27%',
            style: {
              fontFamily: 'var(--font-family-regular)',
              letterSpacing: '-0.01px',
              fontWeight: '600',
              fontSize: '11px',
              color: 'var(--primary-text-color)',
              textOutline: 'none',
            },
            connectorWidth: 1.5,
            connectorColor: 'gray',
            connectorPadding: 0,
            connectorShape: 'crookedLine'
          },
        },
      },
      colors: ['#734B5E','#9F7F91', '#D3C0CD','#E8AEB7'],
      //colors: ['#3b85bf','#f3b5aa', '#deecf7'],
      series: [
        {
          type: 'pie',
          name: '6',
          // data: [{name: connectivityMediumData?.[0].network, y: connectivityMediumData?.[0].percentage, chargerCount: connectivityMediumData?.[0].count},
          // {name: connectivityMediumData?.[1].network, y: connectivityMediumData?.[1].percentage, chargerCount: connectivityMediumData?.[1].count}]
          data: filteredData.map(entry => ({
            name: entry.network,
            y: entry.percentage,
            chargerCount: formatNumberWithCommas(entry.count)
          }))
        },
      ],
    };
  }, [connectivityMediumData]);

  return (
    <div className='connectivity__medium__status__container'>
      <div className='connectivity__status__wrap'>
        <div className='charger__working__status-header-wrap'>
          <div className='charger__working__status-header'>
            Connectivity Medium
          </div>
        </div>

        <div className='connectivity__medium__status-graph-wrap'>
          {!connectivityMediumData ? <RectangularSkeleton skeletonContainerClassName='charger__working__status-graph-skeleton' /> :
            connectivityMediumData && connectivityMediumData.length > 0 ?
              <CustomHighCharts
                highChartOptions={connectivityMediumChartOptions}
              /> : (
                <div className='rms__table__dashboard__no__data'>
                  <Icon iconClassName='no__connectivity__medium__icon' />
                  <p className='connectivity__medium__status'>No data available</p>
                </div>)
          }
        </div>
      </div>
    </div>
  );
};

export default ConnectivityMediumStatus;

