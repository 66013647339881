/* eslint-disable react/display-name */
import React, { forwardRef, useEffect, useState } from 'react'
import { cloneDeep } from 'lodash';
import { useAppDispatch, useAppSelector } from '../../../../rmsReduxStore/rmsReduxHooks';
import { chargerTypes, fetchChargerStatusMasterInfo, fetchChargerStatusSystemInfo, getChargerReducer } from '../../../../rmsReduxStore/reduxExports';
import { updateMasterInfo, useEffectOnce } from '../../../../globalUtils/globalHooks';
import ParametersTable from '../../../../globalUtils/ParametersTable/ParametersTable';
import { ChargerParameterRef } from './ChargerPanelStatusSecondOption';
import { useLocation } from 'react-router-dom';

interface ChargerPanelStatus {
  contentHeight: number,
}

const ChargerStatusParameters = forwardRef<ChargerParameterRef,ChargerPanelStatus>(({contentHeight},ref ) => {

  const [systemInfoData, setSystemInfoData] = useState<ChargerMasterInfo[] | null>(null);
  const dispatch = useAppDispatch()
  const location = useLocation()
  const { chargerStatusMasterInfo, chargerStatusSystemInfo } = useAppSelector(getChargerReducer);

  useEffect(() => {
    if (chargerStatusMasterInfo !== null && chargerStatusSystemInfo !== null) {
      const chargerMasterData = cloneDeep(chargerStatusMasterInfo);
      const chargerSystemData = cloneDeep(chargerStatusSystemInfo);
      const data = updateMasterInfo(chargerMasterData, chargerSystemData);
      // : Partial<ChargerAction>
      const action = {
        type: chargerTypes.SET_ALTERED_MASTER_DATA_FOR_CHARGER_STATUS,
        chargerStatusMasterInfoAltered: data,
      }
      dispatch(action);    
      setSystemInfoData(data)
    }
  }, [chargerStatusMasterInfo, chargerStatusSystemInfo])


  useEffectOnce(() => {
    dispatch(fetchChargerStatusMasterInfo({ chargerId: location?.state?.chargerId }));
    dispatch(fetchChargerStatusSystemInfo({ chargerId: location?.state?.chargerId }));
  })
  
  return (
    <div ref={ref} className='charger__status__parameters__table' style={{ height: `${contentHeight}px` }}>
      <ParametersTable data={systemInfoData} containerClassName={'charger__status__parameters'} />
    </div>

  )
})

export default ChargerStatusParameters