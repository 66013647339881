/* eslint-disable react/prop-types */
import { TablePaginationOwnProps, ThemeProvider } from '@mui/material';
import MuiPagination from '@mui/material/Pagination';
import React, { FC, useCallback, } from 'react';
import { PaginationTheme } from '../globalExports';
import {
  GridPagination,
} from '@mui/x-data-grid-pro';
import { formatNumberWithCommas } from '../globalHooks';

const Pagination: FC<TablePaginationOwnProps> = ({ page, onPageChange, className, count }) => {
  const handleOnPageChange = useCallback((event, newPage: number) => {
    onPageChange(event, newPage);
  }, []);
  if (count < page) page = 1;


  return (

    <MuiPagination
      className={'table__footer__pagination ' + className}
      count={count}
      page={!count || count <= 0 ? 1 : page || 1}
      onChange={(event, newPage) => {
        handleOnPageChange && handleOnPageChange(event, newPage);
      }}
    />

  );
};

const TablePagination = (props): JSX.Element => {
  const defaultPageNumber = props?.pageNumber || 1;

  return (
    <ThemeProvider theme={PaginationTheme}>
      <div className="table__footer__wrap-paginated-grid">
        <div className="table__footer__wrap-paginated-grid-right">
          <TotalCountComponent totalCount={formatNumberWithCommas(props?.totalCount)} totalCountText={props?.totalCountText} />
        </div>
        <div className="table__footer__wrap-paginated-grid-right">
          <GridPagination
            ActionsComponent={Pagination}
            count={Math.ceil(props?.totalCount / props?.pageSize)}
            onPageChange={props?.onPageChange}
            page={defaultPageNumber}
            {...props}
          />
        </div>
      </div>
    </ThemeProvider>
  );
};

const TotalCountComponent = ({ totalCount, totalCountText }): JSX.Element => {
  return (
    <div className="pagination__total__of__rows__component">Total: {totalCount}&nbsp;{totalCountText}</div>
  );
};


export default TablePagination;
