import React, { FC } from 'react'
import { ToastContainer, ToastContainerProps } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
const Toasters: FC<ToastContainerProps> = () => {
  return (
    <ToastContainer
      position='bottom-left'
      // autoClose={false}
      autoClose={6000}
      hideProgressBar={true}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="light"
      bodyClassName="toaster__body"
      toastClassName='toaster__wrap'
    />
  )
}

export default Toasters