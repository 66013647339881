import { IUser } from '../../layouts/navbar/Navbar';
import HWLocalStorage from '../../rmsReduxStore/api-service/HWLocalStorage';
import authService from './AuthService';

class AppUser {
  user: null | IUser = null;

  constructor() {
    this.sync();
  }

  sync():void {
    this.user = HWLocalStorage.load('rms-user') as unknown as IUser;
  }

  // update(userObject: Object):void {
  //   HWLocalStorage.save('rms-user', userObject);
  //   this.sync();
  // }

  isLoggedIn = ():boolean => {
    if (!this.user) {
      this.sync();
    }
    return ((this.user != null) && authService.hasTokens()) as boolean;
  };
}

const appUser = new AppUser();
export default appUser;
